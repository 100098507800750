import React, {Fragment} from 'react';
import {ButtonGroup, Badge} from 'reactstrap';
import {getStyle} from './utils';

const data = [
  {label: 'First Submission', value: 'isSubmitted1'},
  {label: 'Remind Me', value: 'remindMe'},
  {label: 'Second Submission', value: 'isSubmitted2'},
];

const ActionsComplete = ({onClick, selected = []}) => {
  const addOnClick = value => () => onClick(value);
  return (
    <div className="d-flex align-items-center mb-3">
      <div className="mr-3 text-nowrap">Actions Complete</div>
      <ButtonGroup>
        <div className="d-flex align-items-center">
          {
            data.map(({label, value}) => (
              <Fragment key={value}>
                <Badge
                  onClick={addOnClick(value)}
                  active={selected.includes(value)}
                  style={getStyle(selected, value)}
                  className="text-capitalize text-dark d-flex justify-content-center align-items-center participation-badge-font mb-0"
                  color="light"
                >
                  {label}
                </Badge>
              </Fragment>
            ))
          }
        </div>
      </ButtonGroup>
    </div>
  );
};

export default ActionsComplete;
