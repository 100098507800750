import moment from 'moment/moment';

export const calculateBurnPercentage = (campaign) => {
  // totalRedeemed does not exsist yet, but this will be useful later..?
  const total = campaign?.totalRedeemed;
  const totalAvailable = campaign?.campaignBudget;
  return isNaN(Math.round((total / totalAvailable) * 100)) ? 0 : isNaN(Math.round((total / totalAvailable) * 100));
};

export const calcPillColorByBurnPercentage = (percentage) => {
  if (percentage < 50) {
    return 'danger';
  } else if (percentage >= 50 && percentage < 75) {
    return 'rgb(255, 165, 0)';
  } else {
    return 'success';
  }
};

export function campaignActiveRange(campaign) {
  const DATE_FORMAT = 'll';
  const startDate = moment(campaign?.campaignDateStart).format(DATE_FORMAT);
  const endDate = moment(campaign?.campaignDateEnd).format(DATE_FORMAT);

  return (
    <div className="d-flex font-weight-bold">
      <span className="">
        {startDate !== 'Invalid date' ? startDate : 'No Start Date'}
      </span>
      <span>-</span>
      <span className="">
        {endDate !== 'Invalid date' ? endDate : 'No End Date'}
      </span>
    </div>
  );
}

export function redemptionTimeLimit(campaign) {
  return (
    <div className="d-flex font-weight-bold">
      {!campaign?.timeLimitHour && !campaign?.timeLimitMinute ? (
        <p className="font-weight-bold">No Time Limit</p>
      ) : (
        <div className="d-flex">
          <p className="font-weight-bold mr-2">
            {campaign?.timeLimitHour ? campaign?.timeLimitHour + ' hrs ' : ''}
          </p>
          <span className="mr-2">
            {!campaign?.timeLimitHour || !campaign?.timeLimitMin ? ' ' : '&'}
          </span>
          <p className="font-weight-bold mr-2">
            {campaign?.timeLimitMin ? campaign?.timeLimitMin + ' minutes' : ''}
          </p>
        </div>
      )}
    </div>
  );
}

