import React from 'react';
import {Link} from 'react-router-dom';
import {useFormik} from 'formik';
import {Button, Card, CardBody, Spinner, Alert} from 'reactstrap';
import RolesSettings from './RolesSettings';
import OrganizationService from '../../shared/services/OrganizationService';
import {useQuery} from '@tanstack/react-query';
import {TextInput} from '../../shared/components/fields';
import {getRolesOptions, userSchema} from './config';
import {userService} from './api';
import MainFields from './MainFields';

const orgService = new OrganizationService();

const Form = ({onSubmit, initialValues, isEdit, submitError}) => {
  const {isLoading, data} = useQuery(['organizations'], () => orgService.getAllOrganizations());
  const {isLoading: isLoadingRoles, data: rolesReq} = useQuery(['roles'], () => userService.geRolesList());
  const formik = useFormik({
    initialValues: {roles: [], ...initialValues},
    onSubmit,
    validationSchema: userSchema(isEdit),
  });

  const organizationOptions = data?.data?.map(item => ({value: item.id, label: item.name}));
  const rolesList = getRolesOptions(rolesReq?.data || []);

  return (
    <form className="userForm" onSubmit={formik.handleSubmit} >
      <Card>
        <CardBody>
          <MainFields formik={formik} isEdit={isEdit}/>
          {isEdit && (
            <div className="rolesSection">
              <h6>Roles settings</h6>
              <TextInput
                classes="w50"
                label="Title"
                name="title"
                formik={formik}
              />
              {
                !isLoading && !isLoadingRoles ? (
                  <RolesSettings
                    rolesList={rolesList}
                    orgList={organizationOptions}
                    formik={formik}
                  />
                ) : (
                  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Spinner color="primary" className="page-loader"/>
                  </div>
                )
              }
            </div>
          )}
          {submitError && <Alert color='danger' className="formError">{submitError}</Alert>}
        </CardBody>
      </Card>
      <div className="userFormFooter">
        <Link to="/admin/users">
          <Button disabled={formik.isSubmitting} outline color="primary">Back</Button>
        </Link>
        <Button disabled={formik.isSubmitting || !formik.isValid} color="primary">Submit</Button>
      </div>
    </form>
  );
};

export default Form;
