import {Button, Col, NavLink, Row} from 'reactstrap';
import React, {useEffect, useRef, useState} from 'react';
import {joinClasses} from '../../utils';
import WizardNavButtons from './WizardNavButtons';
import {Sticky, StickyContainer} from 'react-sticky';
import StickyBox from './StickyBox';

const navItems = [
  {step: 'campaign', label: 'Type'},
  {step: 'overview', label: ' Overview'},
  {step: 'offer', label: 'Details'},
  {step: 'designer', label: 'Designer'},
  {step: 'trigger', label: 'Trigger'},
  {step: 'review', label: 'Review'},
];

const Navigation = (props) => {
  const {children, initTabKey = navItems[0].step, disabled, additionalNav} = props;
  const divRef = useRef(null);
  const [tabKey, _setTabKey] = useState(initTabKey);
  const [isFixed, setIsFixed] = useState(true);
  const currentNavItemN = navItems.findIndex(item => item.step === tabKey);

  const setTabKey = (key) => {
    localStorage.setItem('tabKey', key);
    _setTabKey(key);
    divRef.current.scrollIntoView(false);
    setIsFixed(false);
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    setTimeout(() => setIsFixed(true), 100);
  };

  return (
    <div className="campaignWrapper">
      <div ref={divRef} />
      <StickyContainer>
        <Sticky topOffset={Infinity}>
          {({distanceFromTop}) => {
            return (
              <div style={{position: 'relative'}}>
                <StickyBox distanceFromTop={distanceFromTop} isFixed={isFixed}>
                  <div className="navTopBtn" style={{paddingLeft: '40px'}}>
                    <Row>
                      <Col sm="10">
                        <Row className="flex-nowrap step-container content">
                          {navItems.map((item, index) => {
                            const classes = joinClasses('d-flex', 'align-items-center', 'justify-content-flex-start', 'p-0', 'pb-1',
                              tabKey === item.step ? 'active align-left c-nav-highlight cursor-pointer' : 'cursor-pointer text-align-center',
                            );
                            return (
                              <Col sm="2" key={item.step}>
                                <NavLink
                                  aria-expanded={tabKey === item.step}
                                  data-toggle="tab"
                                  role="tab"
                                  disabled={disabled}
                                  style={{cursor: 'pointer'}}
                                  className={classes}
                                  onClick={() => setTabKey(item.step)}
                                >
                                  <Button
                                    // disabled={index > 1 && formik?.values?.id === undefined}
                                    disabled={disabled}
                                    className={`badge badge-primary d-flex align-items-center ${disabled && 'disabled'}`}
                                  >
                                    {index + 1}
                                  </Button>
                                  <p className="d-flex align-items-center text-center my-auto ">
                                    {item.label}
                                  </p>
                                </NavLink>
                              </Col>
                            );
                          })}
                        </Row>
                      </Col>
                      <Col sm="2">
                        {additionalNav}
                      </Col>
                    </Row>
                  </div>
                </StickyBox>
              </div>
            );
          }}
        </Sticky>
      </StickyContainer>
      {typeof children === 'function' ? children({tabKey, setTabKey}) : null}
      <div style={{paddingRight: '200px', paddingLeft: '20px'}}>
        <WizardNavButtons
          hideNext={tabKey === navItems[0].step}
          onNextStep={ navItems.length > currentNavItemN +1 ? () => {
            setTabKey(navItems[currentNavItemN+1].step);
          } : undefined}
          onPrevStep={currentNavItemN > 0 ? () => {
            setTabKey(navItems[currentNavItemN-1].step);
          } : undefined}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default Navigation;
