import React from 'react';
import {Form} from './index';
import {EditorHtml} from './fields';


const Rules = ({formik, onSubmit}) => {
  return (
    <div>
      <Form formik={formik} onSubmit={onSubmit}>
        <EditorHtml label="Heading" formik={formik} name="footerRulesHeading" />
        <EditorHtml label="Description" formik={formik} name="footerRulesDescription" minHeight="80px" />
      </Form>
    </div>
  );
};

export default Rules;
