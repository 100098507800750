import React from 'react';
import {Link} from 'react-router-dom';
import {Col, Row} from 'reactstrap';

const WizardNavButtons = (props) => {
  const {onNextStep, onPrevStep, disabled, nextTitle, navLocation, hideNext} = props;
  return (
    <Row>
      <Col sm={12} className="justify-content-between" style={{display: 'flex'}}>
        {onPrevStep && (
          <div className="align-self-left" style={{margin: '0 auto 0 0'}}>
            <a
              className={
                !disabled ? 'btn btn-primary ' : 'btn btn-primary disabled'
              }
              onClick={() => {
                onPrevStep();
              }}
            >
              Back
            </a>
          </div>
        )}
        {!hideNext && onNextStep && (
          <div className="align-self-right" style={{margin: '0 0 0 auto'}}>
            {navLocation?.length ? (
              <Link
                to={`${navLocation}`}
                className={
                  !disabled ? 'btn btn-primary ' : 'btn btn-primary disabled'
                }
              >
                {nextTitle ?? 'Next'}
              </Link>
            ) : (
              <a
                className={
                  !disabled ? 'btn btn-primary ' : 'btn btn-primary disabled'
                }
                onClick={() => {
                  onNextStep();
                }}
              >
                {nextTitle ?? 'Next'}
              </a>
            )}
          </div>
        )}
      </Col>
    </Row>
  );
};

export default WizardNavButtons;
