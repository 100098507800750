import React, {useEffect, useRef} from 'react';
import {Button, Table} from 'reactstrap';
import {TextInput} from '../../../shared/components/fields';
import {getNewValues, getTimingList} from './utils';
import {PlusSquare, Trash} from 'react-bootstrap-icons';
import {winnerPeriodsCnf} from './config';
import DateTimePicker from '../../../shared/components/fields/DateTimePicker';


const WinnersTable = ({formik, name}) => {
  const start = formik.values?.campaignDateStart || null;
  const end = formik.values?.campaignDateEnd || null;
  const period = formik.values?.winnerPeriodType || null;
  const isCustomPeriod = period === winnerPeriodsCnf.c;
  const isMount = useRef(false);

  useEffect(() => {
    const periods = getTimingList({period, start, end});
    if (!isCustomPeriod && isMount.current) return formik.setFieldValue(name, getNewValues(periods));
    if (isCustomPeriod && isMount.current) {
      // Necessary for correct redrawing of the list
      setTimeout(()=>{
        formik.setFieldValue(name, getNewValues(periods));
      });
      return formik.setFieldValue(name, []);
    }
  }, [period, start, end]);

  useEffect(() => isMount.current = true, []);

  const addItem = () => {
    formik.setFieldValue(name, [...formik.values?.[name] || [], {
      date: null,
      amount: null,
    }]);
  };

  const onDelete = (i) => () => {
    const values = [...formik.values?.[name]];
    values.splice(i, 1);
    formik.setFieldValue(name, values);
  };

  return (
    <>
      {period && <div className="winnersTable">
        <Table size="sm" hover borderless>
          <tbody>
            {
              formik.values?.[name]?.map((item, i) => {
                return (
                  <tr key={item.roleId}>
                    <th>{i + 1}</th>
                    <th>Winner Selected</th>
                    <th>
                      <DateTimePicker
                        timeFormat={false}
                        disabled={!isCustomPeriod}
                        name={`${name}[${i}].date`}
                        formik={formik}
                        placeholder={'DD/MM/YYYY '}
                      />
                    </th>
                    <th>Amount</th>
                    <th className="text-right">
                      <div className="winnersAmount">
                        <TextInput
                          name={`${name}[${i}].amount`}
                          formik={formik}
                          classes="m0"
                        />
                      </div>
                    </th>
                    {
                      formik.values?.['winnerPeriodType'] === winnerPeriodsCnf.c && (
                        <th>
                          <Button
                            onClick={onDelete(i)}
                            size="sm"
                            color="danger"
                            outline
                          >
                            <Trash/>
                          </Button>
                        </th>
                      )
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </Table>
        {
          isCustomPeriod && (
            <div className="winnersTableFooter">
              <Button onClick={addItem} size="sm" outline color="info">
                <PlusSquare/>
                <span>Add date</span>
              </Button>
            </div>
          )
        }
      </div>}
    </>
  );
};

export default WinnersTable;
