import React from 'react';
import Switch from 'react-bootstrap-switch';
import {joinClasses} from '../../../../../../utils';

const SwitchField = ({formik, name, label, classes = []}) => {
  return (
    <div className={joinClasses('switchField', ...classes )}>
      {label && <label>{label}</label>}
      <Switch
        {...formik.getFieldProps(name)}
        defaultValue={formik.values[name] ?? false}
        name={name}
        onColor="success"
        offColor="primary"
        onText="Yes"
        offText="No"
        onChange={(e) => {
          formik.setFieldValue(name, e.state?.value);
        }}
      />
    </div>
  );
};

export default SwitchField;
