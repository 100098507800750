import React from 'react';
import {useParams} from 'react-router-dom';
import {useMutation, useQuery} from '@tanstack/react-query';
import {Alert, Spinner} from 'reactstrap';
import Form from './Form';
import {userService} from './api';

const UserUpdate = () => {
  const {id} = useParams();
  const [successMessage, setSuccessMessage] = React.useState(null);
  const {isLoading, error, data} = useQuery(['getUser'], () => userService.getUser(id), {cacheTime: 0});
  const user = data?.data || null;

  const {mutate: updateUser} = useMutation((values)=> {
    return userService.updateUser(values.id, values);
  },
  {
    onSuccess: () => {
      setSuccessMessage('Successfully updated the user.');
    },
    onError: (error) => {
      alert('Something went wrong');
    },
  });

  const onSubmit = async (values) => {
    return updateUser(values);
  };

  return (
    <div className="sectionBox _mini">
      <h5>Update Alpha 6 Admin User</h5>
      {error && <div>Something went wrong</div>}
      {!isLoading && !error ? (
       <>
         {successMessage && <Alert color='success'>{successMessage}</Alert>}
         <Form isEdit onSubmit={onSubmit} initialValues={user}/>
       </>
      ) : (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Spinner color="primary" className="page-loader"/>
        </div>
      )}
    </div>
  );
};


export default UserUpdate;
