import moment from 'moment/moment';
import {DateTime as luxDate} from 'luxon';
import Datetime from 'react-datetime';
import {joinClasses} from '../../../utils';

const DateTimePicker = ({formik, name, label, placeholder, onChange, ...restProps}) => {
  if (!formik || !name) return null;
  const error = formik.touched?.[name] && formik.errors?.[name];
  const valueStr = formik.getFieldProps(name).value;
  const value = valueStr ? moment(valueStr) : '';

  return (
    <div style={{width: '100%', position: 'relative', margin: 0}}>
      {label && <label style={{display: 'block', width: '100%'}} htmlFor={name}>{label}</label>}
      <Datetime
        inputProps={{disabled: restProps.disabled, className: 'dateTimePicker', placeholder}}
        className={
          joinClasses(
              'dateTimePickerBox',
            restProps.right ? 'right0' : 'left0',
            restProps.bottom && '_bottom',
          )
        }
        value={value}
        onChange={value => {
          if (value.isValid) {
            const date = luxDate.fromISO(value?.toISOString()).toUTC();
            formik.setFieldValue(name, date.toISO());
          } else formik.setFieldValue(name, '');
          return onChange?.(value);
        }}
        timeFormat={restProps.timeFormat ?? restProps.timeFormat}
        dateFormat={restProps.dateFormat ?? restProps.dateFormat}
      />
      {error && (
        <div style={{color: 'red', fontSize: '12px'}}>{error}</div>
      )}
    </div>
  );
};

export default DateTimePicker;
