import React, {useContext, useEffect, useState} from 'react';
import {ChosenLocation} from '../../contexts/RouterContext/ChosenLocation.js';
import {Badge, Button, Card, CardBody, Col, Row, Spinner} from 'reactstrap';
import ParticipantListTable from '../components/table/ParticipantListTable';
import moment from 'moment';
import './style.scss';
import CampaignService from '../../shared/services/CampaignService.js';
import {useQuery} from '@tanstack/react-query';
import InfoCard from '../components/shared/InfoCard.js';
import {calculateCampaignEndDateTime, safelyRoundTwoDecimals, handleOfferValueDisplay} from '../../shared/utils';
import {Link} from 'react-router-dom';


const ParticipantList = () => {
  // since we already have the data cached with react-query, we can use the queryClient to get it by utilizing the context IDs
  const [chosenLocation] = useContext(ChosenLocation);
  const [participants, setParticipants] = useState([]);
  const clientId = chosenLocation.client;
  const campaignId = chosenLocation.campaign;
  const campaignService = new CampaignService();

  const {
    data: campaignParticipants,
  } = useQuery(['participants', campaignId], () =>
    campaignService.getCampaignParticipants(campaignId),
  );
  useEffect(() => {
    if (campaignParticipants?.data?.length > 0) {
      setParticipants(campaignParticipants.data);
    }
  }, [campaignParticipants]);

  const campaignData = useQuery(['campaign', campaignId], () =>
    campaignService.getCampaignByIDFull({id: campaignId, clientId: clientId}),
  );

  const campaignObject = campaignData?.data?.data;

  const startDate = moment(campaignObject?.campaignDateStart).format(
      'MM/DD/YYYY',
  );
  const endDate = moment(campaignObject?.campaignDateEnd).format('MM/DD/YYYY');

  if (campaignData.isLoading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center mx-auto w-100"
        style={{height: '80vh'}}
      >
        <Spinner color="primary" className="page-loader" />
      </div>
    );
  }

  const formatToUsd = (value) => {
    if (!value || isNaN(value)) {
      return '$0.00';
    }
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(value);
  };
  return (
    <div>
      <Row className="ml-0">
        <Col style={{backgroundColor: '#f5f5f5'}} md="4">
          <h6 className="mt-0">{campaignObject?.campaignName}</h6>
          <div>
            <p className="mb-0">
              <span className="font-weight-bold">Campaign Type:</span>{' '}
              {campaignObject?.type === 'timedOffer' ?
                'Special Offer' :
                'Enter To Win'}{' '}
              {campaignObject?.offerType && '> ' + campaignObject?.offerType}
            </p>
            <Button className="rounded-pill standard-button-blue" size="sm">
              <Link to="/admin/clients/campaigns/details" style={{color: 'white'}}>
                View Dashboard
              </Link>
            </Button>
          </div>
        </Col>
        <Col md="8">
          <Row>
            <Col sm="5">
              <Card style={{minHeight: '100px'}}>
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <p>Campaign Active</p>
                    <h6>
                      <Badge pill className="standard-button-blue" style={{textTransform: 'none'}}>
                        {calculateCampaignEndDateTime(campaignObject?.campaignDateStart, campaignObject?.campaignDateEnd)}
                      </Badge>
                    </h6>
                  </div>
                  <div className="d-flex font-weight-bold">
                    <p className="pr-2">
                      {startDate !== 'Invalid date' ?
                        startDate :
                        'No Start Date'}
                    </p>
                    {'-'}
                    <p className="pl-2">
                      {endDate !== 'Invalid date' ? endDate : 'No End Date'}
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="3">
              <Card style={{minHeight: '100px'}}>
                <CardBody>
                  <p>Offer</p>
                  <p className="font-weight-bold">
                    {handleOfferValueDisplay(campaignObject?.cashBackAmt, campaignObject?.cashBackType, campaignObject?.percentage)}
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col className="mr-0" sm="4">
              <Card style={{minHeight: '100px'}}>
                <CardBody>
                  <p className="">Redemption Time Limit</p>
                  {!campaignObject?.timeLimitHour &&
                  !campaignObject?.timeLimitMinute ? (
                    <p className="font-weight-bold">No Time Limit</p>
                  ) : (
                    <div className="d-flex">
                      <p className="font-weight-bold mr-2">
                        {campaignObject?.timeLimitHour ?
                          campaignObject?.timeLimitHour + ' hrs ' :
                          ''}
                      </p>
                      <span className="mr-2">
                        {!campaignObject?.timeLimitHour ||
                        !campaignObject?.timeLimitMin ?
                          ' ' :
                          '&'}
                      </span>
                      <p className="font-weight-bold mr-2">
                        {campaignObject?.timeLimitMin ?
                          campaignObject?.timeLimitMin + ' minutes' :
                          ''}
                      </p>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col sm="4">
          <InfoCard
            // were mising redeemed amount for calculations
            multiColData={[
              {
                label: 'Total Reach', // (Landed on Age Gate)
                value: campaignObject?.landingCount ?? 0,
              },
              {
                label: 'Redemptions', // (Paid only?)
                value: campaignObject?.disbReceivedCount ?? 0,
              },
              {
                label: '% Conversion',
                value:
                  // (Redemption / Total Reach)
                  !campaignObject?.disbReceivedCount ||
                    !campaignObject?.landingCount ?

                  0 :
                  ((
                    campaignObject?.disbReceivedCount /
                      campaignObject?.landingCount
                  ) * 100).toFixed(2),
              },
            ]}
          />
        </Col>
        <Col sm="3" className="px-2">
          <InfoCard
            // were mising redeemed amount for calculations
            multiColData={[
              {
                label: 'Current Budget',
                value: formatToUsd(campaignObject?.campaignBudget - campaignObject?.totalRedeemed), // Total Budget - Redeemed Dollars = Remaining Budget should replace this eventually
              },
              {
                label: 'Redeemed Dollars',
                value: formatToUsd(campaignObject?.totalRedeemed), // Dollars Reimbursed
              },
            ]}
          />
        </Col>
        <Col sm="2">
          <InfoCard
            multiColData={[
              {
                label: 'Budget Burn',
                value: safelyRoundTwoDecimals(campaignObject?.budgetBurn, true), // (Redeemed Dollars (no data for this) / Total Budget)
              },
            ]}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <ParticipantListTable
            campaignData={campaignObject}
            participants={participants}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ParticipantList;
