import mainNavigation from '../../routes/mainNavigation';
import {Link, useLocation} from 'react-router-dom';
import {joinClasses} from '../../utils';
import AuthService2 from '../../shared/services/AuthService2';
import OrganizationService from 'shared/services/OrganizationService';
import {useMutation} from '@tanstack/react-query';
import {useEffect} from 'react';
import Submenu from './Submenu';

const clientService = new AuthService2();
const organizationService = new OrganizationService();

const MainNavigations = ({handleResetContext}) => {
  const location = useLocation();
  const {mutate: getOrgInfo, data, isLoading} = useMutation(({id}) => organizationService.getOrganization(id));
  const orgName = isLoading ? '...' : data?.data?.name;

  useEffect(() => {
    const orgId = clientService.getCurrentOrgList()[0];
    if (orgId) getOrgInfo({id: orgId});
  }, []);

  return (
    <ul className="nav flex-column">
      {
        mainNavigation({orgName}).map(item => {
          const isAllowed = clientService.canUserDo(item.validRoles);
          const isShow = item.path ? location.pathname.includes(item.path) : true;
          const isActive = location.pathname.includes(item.to);

          if (!isShow || !isAllowed) return null;

          return (
            <li className="nav-item a6-item" key={item.path}>
              <Link
                onClick={item.context ? () => handleResetContext(item.context) : undefined}
                className={joinClasses('nav-link', isActive && 'blue')}
                to={item.to}
              >
                <i className={item.iconClasses}></i>
                <span>{item.text}</span>
              </Link>
              {
                isActive && item.children?.length > 0 && (
                  <Submenu data={item.children} handleResetContext={handleResetContext}/>
                )
              }
            </li>
          );
        })
      }
    </ul>
  );
}
;

export default MainNavigations;
