
const CutLongText = ({children}) => {
  return (
    <div className="cutLongText">
      <div className="_content">
        {children}
      </div>
    </div>
  );
};

export default CutLongText;
