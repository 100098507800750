import {UserCreate, UserList, UserProfile, UserUpdate} from '../../views/Users';
import {ROLES} from '../config';

const routes = [
  {
    path: '/users',
    name: 'Users',
    component: UserList,
    layout: '/admin',
    exact: true,
    validRoles: [ROLES.org],
  },
  {
    path: '/users/create',
    name: 'User Create',
    component: UserCreate,
    layout: '/admin',
  },
  {
    path: '/users/edit/:id',
    name: 'User Edit',
    component: UserUpdate,
    layout: '/admin',
  },
  {
    path: '/users/profile/:id',
    name: 'User Profile',
    component: UserProfile,
    layout: '/admin',
    validRoles: [ROLES.org],
  },
];

export default routes;
