import React from 'react';
import 'react-datetime/css/react-datetime.css';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import 'bootstrap/dist/css/bootstrap.css';
import './Overview.css';
import './offerdetails.css';
import assImg from 'assets/img/age-acknowledge.png';
import enterDate from 'assets/img/age-enterdate.png';
import * as yup from 'yup';
import {TextInput, DatePicker, TimePicker} from '../../../shared/components/fields';

export const campaignOverviewValidation = {
  // ageVerif: yup.string().required("Required"),
  campaignName: yup.string().required('Required'),
  // campaignDateEnd: yup.string().required("Required"),
  // campaignTimeEnd: yup.string().required("Required"),
  // campaignDateStart: yup.string().required("Required"),
  // campaignTimeStart: yup.string().required("Required"),
  description: yup.string(),
};

const Overview = ({formik}) => {
  return (
    <div>
      <div className="form-row animated ">
        <TextInput label="Campaign Name" name="campaignName" formik={formik}/>
      </div>
      {/* <div className="form-row animated">
        <label>Program</label>
        <div className="input-group input-group-btn">
          <select
            className="form-control"
            id="programName"
            defaultValue={formik.values.programName}
            onChange={formik.handleChange}
          >
            <option value="" selected>
                  Choose one...
            </option>
            <option>Bud Light Seltzer</option>
            <option>Other Program</option>
          </select>
          <div className="input-group-append">
            <button
              style={{margin: '0px'}}
              className="btn btn-outline-primary disabled overview"
              type="button"
              id="btnProgram"
            >
              <i className="zmdi zmdi-plus"></i>
            </button>
          </div>
        </div>
      </div> */}
      {/*
      <div className="form-row animated">
        <label>Primary Contact</label>
        <div className="input-group input-group-btn ">
          <select
            className="form-control"
            id="primaryContactID"
            defaultValue={formik.values.primaryContactID}
            onChange={formik.handleChange}
          >
            <option value="" selected>
                  Choose one...
            </option>
            <option>Joe Schmoe</option>
            <option>Jane Plain</option>
          </select>
          <div className="input-group-append">
            <button
              style={{margin: '0px'}}
              className="btn btn-outline-primary disabled"
              type="button"
              id="btnContact"
            >
              <i className="zmdi zmdi-plus"></i>
            </button>
          </div>
        </div>
      </div> */}
      <div className="form-row animated">
        <label>
              Campaign Summary{' '}
          <i
            className="zmdi zmdi-help ml-auto"
            data-toggle="popover"
            title="Campaign Summary Help"
            data-content="Be sure to include an overview of the offer, including product name along with purchase/redeem instructions."
          ></i>
        </label>
        <textarea
          className="form-control"
          id="description"
          defaultValue={formik.values.description}
          onChange={formik.handleChange}
        ></textarea>
      </div>
      <div className="form-row animated">
        <label>Age Gate:</label>
        <ul className="campaign-single-select">
          <li >
            <div className="select-item" onClick={(e)=>{
              document.getElementById('timeOfferCheck').click();
            }} >
              <label className="custom-radio">
                <input
                  type="radio"
                  name="ageVerif"
                  id="timedOffer"
                  value="1"
                  checked={formik?.values?.ageVerif=='1'}
                  defaultValue={formik?.values?.ageVerif=='1'}
                  onChange={formik.handleChange}
                />
                <span className="checkmark" id="timeOfferCheck"></span>
              </label>
              <span>Require Customer to acknowledge age</span>
              <img src={assImg} className={'img-contaiend'} />
            </div>
          </li>
          <li>
            <div className="select-item" onClick={(e)=>{
              document.getElementById('timeWindowCheck').click();
            }}>
              <label className="custom-radio" >
                <input
                  type="radio"
                  name="ageVerif"
                  id="timeWindow"
                  value="2"
                  checked={formik?.values?.ageVerif=='2'}
                  defaultValue={formik?.values?.ageVerif=='2'}
                  onChange={formik.handleChange}
                />
                <span className="checkmark" id="timeWindowCheck"></span>
              </label>
              <span>Require customer to enter birth date</span>
              <img src={enterDate} />
            </div>
          </li>
          <li>
            <div className="select-item" style={{'min-height': '109px'}} onClick={(e)=>{
              document.getElementById('standardOfferCheck').click();
            }}>
              <label className="custom-radio">
                <input
                  type="radio"
                  name="ageVerif"
                  id="standardOffer"
                  value="0"
                  checked={formik?.values?.ageVerif=='0'}
                  defaultValue={formik?.values?.ageVerif==='0'}
                  onChange={formik.handleChange}
                />
                <span className="checkmark " id="standardOfferCheck"></span>
              </label>
              <span>None</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Overview;
