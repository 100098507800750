import React, {useEffect} from 'react';
import {Route, Switch, useHistory, useLocation} from 'react-router-dom';
import AdminNavbar from '../components/Navbars/AdminNavbar.js';
import {Col} from 'reactstrap';
import {ChosenLocation} from '../contexts/RouterContext/ChosenLocation.js';
import {getRoutes} from '../routes';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import ClientService from 'shared/services/ClientService';
import './admin.css';
import '../assets/css/material-design-iconic-font.css';
import {usePerfectScrollbar, useScrollTop} from '../utils';
import MainNavigations from './MainNavigations';
import {authService2, userService} from '../views/Users/api';
import AuthService from '../shared/services/AuthService2';
import NotFound from './NotFound';

const clientService = new ClientService();
const authService = new AuthService();

function Admin(props) {
  const isLoggedIn = authService.isLoggedIn();
  const location = useLocation();
  const history = useHistory();
  const {mutate: updateUserInfo} = useMutation(({id}) => userService.getUser(id), {
    onSuccess: ({data: user}) => user && authService2.saveUser(user),
  });
  const [chosenLocation, setChosenLocation] = React.useState(
      JSON.parse(window.localStorage.getItem('chosenLocation') ?? '{}'),
  );
  React.useEffect(() => {
    window.localStorage.setItem('chosenLocation', JSON.stringify(chosenLocation));
  }, [chosenLocation]);
  usePerfectScrollbar('Win');
  useScrollTop([location]);

  const isParticipantList = location?.pathname === '/admin/participantlist';

  const handleResetContext = (value) => {
    if (value === 'Organizations') setChosenLocation({});
    else if (value === 'Client') setChosenLocation({organization: chosenLocation.organization});
    else {
      setChosenLocation({
        organization: chosenLocation.organization,
        client: chosenLocation.client,
      });
    }
  };

  const handleMiniClick = () => {
    document.body.classList.toggle('sidebar-mini');
  };

  useEffect(() => {
    const userId = authService2.getCurrentUser()?.id;
    if (userId) updateUserInfo({id: userId});
  }, []);

  if (!isLoggedIn) history.push('/auth/login');

  return (
    <ChosenLocation.Provider value={[chosenLocation, setChosenLocation]}>
      <div className="m-0 h-100">
        <Col sm={12} className="h-100">
          <AdminNavbar {...props} handleMiniClick={handleMiniClick}/>
        </Col>
        <div>
          <div className="rail-container c-custom-railcontainer">
            <MainNavigations handleResetContext={handleResetContext}/>
          </div>
          <div className="A6-platform-wrapper h-100">
            <div
              className={`mt-20 pl-2 container-lg content-wrapper ${isParticipantList ? 'participant-list-large-width' : ''}`}
            >
              <Switch>
                {getRoutes('/admin')}
                <Route path='*' render={(props) => <NotFound {...props} />}/>
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </ChosenLocation.Provider>
  );
}

export default Admin;
