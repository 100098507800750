import React from 'react';
import {joinClasses} from '../../../utils';


const ButtonInput = ({formik, name, text, icon, value, action}) => {
  const isSelected = formik.values[name] === value;
  const onClick = () => {
    if (!isSelected) {
      formik.setFieldValue(name, value);
      setTimeout(() => action && action(), 200);
    } else {
      formik.setFieldValue(name, '');
    }
  };
  return (
    <div className="buttonInput" style={{width: '100%', position: 'relative'}}>
      <div
        className={joinClasses('buttonInputBox', isSelected && 'isSelected')}
        onClick={onClick}
      >
        <div className="buttonInputText">{text || 'Button'}</div>
        <div className="buttonInputIcon">{icon || ''}</div>
      </div>
    </div>
  );
};

export default ButtonInput;
