import React from 'react';
import {Link} from 'react-router-dom';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
  Alert,
} from 'reactstrap';
import {useFormik} from 'formik';
import AuthService from 'shared/services/AuthService';
import {authService2} from 'views/Users/api';
import {useMutation} from '@tanstack/react-query';
const ForgotPassword = () => {
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [resetPasswordError, setResetPasswordError] = React.useState('');
  const {mutate: handleResetPassword} = useMutation((values) => {
    return authService2.resetPassword(values);
  }, {
    onSuccess: (data) => {
      setIsSuccess(true);
    },
    onError: (error) => {
      if (error.response.status) {
        setResetPasswordError(error.response.data);
      }
    },
  });
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: (values) => {
      const {email} = values;
      handleResetPassword(email);
    },
  });

  return (
    <div className="login-page">
      <Container>
        <Row>
          <Col className="m-auto" lg="5" md="6">
            <Form onSubmit={formik.handleSubmit} action="" className="form" name="form" method="">
              <Card>
                {
                  isSuccess ? (
                    <div className="login-card-body">
                      <CardHeader>
                        <h3 className="text-center">Reset Password</h3>
                      </CardHeader>
                      <CardBody>
                        <p className="text-center">Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.</p>
                      </CardBody>
                      <CardFooter>
                        <div className="text-center">
                          <Link to="/login">
                            <Button className="btn-round" color="primary" size="lg">
                              Back to Login
                            </Button>
                          </Link>
                        </div>
                      </CardFooter>
                    </div>
                  ) : (
                    <div className="login-card-body">
                      <CardHeader>
                        <h5 className="title text-center">Forgot Password?</h5>
                      </CardHeader>
                      <Input
                        className={`has-label, login-height`}
                        id={'email'}
                        name={'email'}
                        placeholder="Enter Email"
                        onChange={(event) => {
                          formik.handleChange(event);
                          setResetPasswordError('');
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        type="email"
                      />
                      {/* {formik.touched.email && formik.errors.email ? (<div style={{paddingLeft: '20px'}}>{formik.errors.email}</div>) : null} */}
                      {resetPasswordError && <Alert color='danger' className="formError">{resetPasswordError}</Alert>}
                      <br />
                      <CardFooter className='d-flex pr-0 justify-content-end'>
                        <Button
                          className="btn-round login-submit-button"
                          type="submit"
                        >
                    Reset Password
                        </Button>
                        <br />
                      </CardFooter>
                    </div>
                  )
                }

              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
      <div
        className="full-page-background"
      />
    </div>
  );
};

export default ForgotPassword;
