import {useD3} from './useD3';
import React from 'react';
import * as d3 from 'd3';

const BarChart = ({data, width, height}) => {
  height = height || 200;
  width = width || 420;
  const rx = 6;
  const ry = 6;

  const ref = useD3((svg) => {
    svg.selectAll('path').remove();
    const margin = {top: 20, right: 0, bottom: 30, left: 30};
    const max = d3.max(data, (dataItem) => dataItem.y);

    const x = d3
        .scaleBand()
        .domain(data.map((dataItem) => dataItem.x))
        .rangeRound([margin.left, width - margin.right])
        .padding(0.2);

    const y1 = d3
        .scaleLinear()
        .domain([0, max])
        .rangeRound([height - margin.bottom, margin.top]);

    const xAxis = (g) =>
      g.attr('transform', `translate(0,${height - margin.bottom})`).call(
          d3
              .axisBottom(x)
              .tickValues(
                  d3
                      .ticks(...d3.extent(x.domain()), Math.floor(width / 0.1))
                      .filter((v) => x(v) !== undefined),
              )
              .tickSizeOuter(0),
      );

    const y1Axis = (g) =>
      g
          .attr('transform', `translate(${margin.left},0)`)
          .style('color', 'steelblue')
          .call(d3.axisLeft(y1).ticks(null, 's'))
          .call((g) => g.select('.domain').remove())
          .call((g) =>
            g
                .append('text')
                .attr('x', -margin.left)
                .attr('y', 10)
                .attr('fill', 'currentColor')
                .attr('text-anchor', 'start')
                .text(data.y1),
          );

    svg.select('.x-axis').call(xAxis);
    svg.select('.y-axis').call(y1Axis);

    svg
        .select('.plot-area')
        .selectAll('bar')
        .data(data)
        .enter().append('path')
        .style('fill', '#006fc5')
        .attr('d', (item) => {
          if (item.y === 0) {
            // eslint-disable-next-line no-unused-vars
            const _item = {...item, y: 0.2};
            return ('');
          }
          return (`
		    M${x(item.x)},${y1(item.y) + ry}
		    a${rx},${ry} 0 0 1 ${rx},${-ry}
		    h${x.bandwidth() - 2 * rx}
		    a${rx},${ry} 0 0 1 ${rx},${ry}
		    v${y1(0) - y1(item.y) - ry}
		    h${-(x.bandwidth())}Z
      `);
        });
  },
  [data.length, width],
  );

  return (
    <div style={{position: 'relative'}}>
      {data.length < 1 && <span>No data yet</span>}
      <span
        style={{
          fontSize: '10px',
          fontWeight: 400,
        }}>Average time to Redeem</span>
      <svg
        ref={ref}
        style={{
          height,
          width: '100%',
          marginRight: '0px',
          marginLeft: '0px',
        }}
      >
        <g className="plot-area" />
        <g className="x-axis" />
        <g className="y-axis" />
      </svg>
      <span
        style={{
          fontSize: '8px',
          fontWeight: 600,
          position: 'absolute',
          left: '50%',
          bottom: '-5px',
          transform: 'translateX(-50%)',
        }}>Time</span>
      <span
        style={{
          fontSize: '8px',
          fontWeight: 600,
          writingMode: 'tb',
          position: 'absolute',
          left: '-10px',
          top: '50%',
          transform: 'translateY(-50%)',
        }}># Redemptions</span>
    </div>
  );
};

export default BarChart;
