import {Col, Row} from 'reactstrap';
import DateTimePicker from 'shared/components/fields/DateTimePicker';

export const DateAndTime = ({formik, bottom}) => {
  return (
    <Row className="dateAndTime">
      <Col sm={6}>
        <DateTimePicker mb={6} label="Campaign Begins" name="campaignDateStart" formik={formik} bottom/>
      </Col>
      <Col sm={6}>
        <DateTimePicker mb={6} label="Campaign Ends" name="campaignDateEnd" formik={formik} bottom/>
      </Col>
    </Row>
  );
};
