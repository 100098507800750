import React, {useContext} from 'react';
import {useQuery} from '@tanstack/react-query';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from 'reactstrap';
import {ChosenLocation} from 'contexts/RouterContext/ChosenLocation';
import SettingsForm from './SettingsForm';
import OrganizationService from 'shared/services/OrganizationService';
import './style.scss';

const Settings = () => {
  const [chosenLocation, setChosenLocation] = useContext(ChosenLocation);
  const organizationService = new OrganizationService();
  const {isLoading: loading, error: errors, data: credentials} = useQuery(['paypalcredentials', chosenLocation.organization], () => {
    return organizationService.getPaypalCredentials(chosenLocation.organization);
  }, {
    onSuccess: (data) => {
      console.log('data in index', data);
    },
    retry: false,
  });

  const initialValues = credentials?.data ? credentials?.data : {
    paypalClientId: '',
    paypalClientSecret: '',
    Id: chosenLocation.organization,
  };
  console.log('credentials', credentials);
  return (
    <>
      <div className="paypal-account-content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <h5>PayPal Account</h5>
              </CardHeader>
              <CardBody>
                <div className="settings-wrapper">
                  <SettingsForm orgId={chosenLocation.organization} initialValues={initialValues} />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Settings;
