import React from 'react';
import {Row, Col} from 'reactstrap';
import {potentialRedemptions, potentialRedemptionsPercentages} from '../../../Campaign/OfferDetails/utils';
import {CAMPAIGN_TYPES} from '../../../Campaign/config';
import ETWdetails from './ETWdetails';

const Details = (props) => {
  const {formik} = props;
  const isEnterToWin = props.formik?.values?.type === CAMPAIGN_TYPES[1].value;

  return (
    <>
      <Row style={{marginBottom: '0px'}}>
        <Col>
          <h3
            className="text-primary"
            style={{
              textAlign: 'left',
              marginBottom: '0px',
            }}
          >
            Offer Details
          </h3>
        </Col>
        <Col className="text-right">
          <div
            className="btn btn-link btn-edit btn-sm"
            style={{textAlign: 'right', padding: '0px', margin: '0px'}}
            onClick={() => props.setHorizontalTabs('offer')}
          >
            <i className="zmdi zmdi-edit"></i> Edit
          </div>
        </Col>
      </Row>
      {
        isEnterToWin ? (
          <ETWdetails formik={formik}/>
        ) : (
          <div className="card mt-3" style={{padding: '20px'}}>
            <form>
              <div className="row m-0 form-row animated">
                <div className="col-md-6 pl-0">
                  <div className="text-left">
                    {
                      formik.values.cashBackType === 'timedOffer' ? (
                        <>
                          <label className="w-100">Cash Back Amount</label>
                          <p>${(formik.values?.cashBackAmt ?? 0).toLocaleString()}</p>
                        </>
                      ) : (
                        <>
                          <label className="w-100">Percentage Back</label>
                          <p>{(formik.values?.percentage ?? 0).toLocaleString()}%</p>
                        </>
                      )
                    }
                  </div>
                </div>
                <div className="col-md-6 pr-0">
                  <div className="text-left">
                    <label className="w-100">Available Funds</label>
                    <p>
                      ${(formik.values?.campaignBudget ?? 0).toLocaleString()}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row m-0 form-row animated">
                <div className="col-md-6 pl-0">
                  <div className="text-left">
                    <label className="w-100">Potential Redemptions</label>
                    <p>
                      {' '}
                      {formik.values.cashBackType === 'timedOffer' ? (
                        potentialRedemptions(formik.values.campaignBudget, formik.values.cashBackAmt)
                      ) : (
                        '~' + potentialRedemptionsPercentages(
                            formik.values?.campaignBudget,
                            formik.values?.averageAmount,
                            formik.values?.percentage,
                        )
                      )}
                    </p>
                  </div>
                </div>
                <div className="col-md-6 pr-0">
                  <div className="text-left">
                    <label className="w-100">
                      Time Limit to Purchase Once Activated
                    </label>
                    <p>
                      {formik.values?.timeLimitHour ?? 0} hrs /{' '}
                      {formik.values?.timeLimitMin ?? 0} mins
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )
      }
    </>
  );
};

export default Details;
