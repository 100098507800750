import React from 'react';
import {Form} from './index';
import {EditorHtml, ImgInput} from './fields';
import SwitchField from './fields/SwitchField';
import TextInput from './fields/TextInput';

const USE_LINK_KEY = 'confirmationLinkToBuy';
const LINK_KEY_URL = 'confirmationLinkToBuyURL';

const Confirmation = ({formik, onSubmit}) => {
  return (
    <div>
      <Form formik={formik} onSubmit={onSubmit}>
        <EditorHtml label="Heading" formik={formik} name="confirmationHeading" />
        <EditorHtml label="Description" formik={formik} name="confirmationDescription" minHeight="80px" />
        <hr/>
        <ImgInput
          hint="At least 600px / 300px and no larger than 1000px / 600px"
          formik={formik}
          name="confirmationImage"
          label="Confirmation Image (optional?)"
        />
        <hr/>
        <div className="linkField">
          <label htmlFor={USE_LINK_KEY}>Link to Buy</label>
          <div className="linkFieldBox animatedBox">
            <SwitchField classes={['margin0', 'marginR10']} formik={formik} name={USE_LINK_KEY}/>
            {formik.values?.[USE_LINK_KEY] && (
              <TextInput
                placeholder="Enter link URL"
                name={LINK_KEY_URL}
                formik={formik}
              />
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default Confirmation;
