import React from 'react';
import {ArrowLeftCircle} from 'react-bootstrap-icons';

const OptionsGroupForm = ({formik, formKey, formList}) => {
  const {formTitle, component: Form} = formList?.[formKey] || {};

  if (!Form) {
    return (
      <div className="optionsGroupFormDialog">
        <ArrowLeftCircle size={30}/>
        <h5>Select an option</h5>
      </div>
    );
  }
  return (
    <div className="optionsGroupForm">
      {formTitle && <h6>{formTitle}</h6>}
      <div><Form formik={formik}/></div>
    </div>
  );
};

export default OptionsGroupForm;
