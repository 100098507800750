import React from 'react';
import moneyGif from '../../../assets/img/animation_500_l60y3imt.gif';
import percentGif from '../../../assets/img/93902-offer.gif';
import {potentialRedemptions, potentialRedemptionsPercentages} from './utils';

export const CashBack = ({formik}) => {
  return (
    <div className="col-sm-12 p-0">
      <label className="form-row animated font-weight-bold">Cash Back</label>
      <ul className="campaign-single-select" id="cashBack">
        <li className="form-row animated">
          <div
            className="select-item"
            onClick={(e) => {
              document.getElementById('timedOffer').click();
            }}
          >
            <label className="custom-radio">
              <input
                type="radio"
                name="cashBackType"
                id="timedOffer"
                value="timedOffer"
                defaultChecked={formik.values.cashBackType === 'timedOffer'}
                onChange={formik.handleChange}
              />
              <span className="checkmark"></span>
            </label>
            <span>Fixed Offer Amount</span>
            <img src={moneyGif} className="pt-3 pb-3 mr-3"/>
          </div>
          <div
            className={
              formik.values['cashBackType'] === 'timedOffer' ?
                'select-item-options' :
                'collapse select-item-options'
            }
          >
            <div
              className="form-row"
              onClick={(e) => {
                document.getElementById('timedOffer').click();
              }}
            >
              <label>Cash Back Amount</label>
              <div className="input-group">
                <span className="input-group-addon">$</span>
                <input
                  type="number"
                  // disabled
                  className="form-control dollar"
                  id="cashBackAmt"
                  name="cashBackAmt"
                  value={formik.values.cashBackAmt}
                  onChange={(event) => {
                    formik.handleChange({
                      target: {
                        name: event.target.name,
                        value: event.target.value.toLocaleString(
                            'en-US',
                            {style: 'currency', currency: 'USD'},
                        ),
                      },
                    });
                  }}
                />
              </div>
            </div>
            <div className="form-row">
              <label>Budget</label>
              <div className="input-group">
                <span className="input-group-addon">$</span>
                <input
                  type="number"
                  // disabled
                  className="form-control dollar"
                  id="campaignBudget-fixed"
                  value={formik.values.campaignBudget}
                  onChange={formik.handleChange}
                  name="campaignBudget"
                />
              </div>
            </div>
            <div className="alert alert-muted">
              <p>
                <strong>
                  {potentialRedemptions(formik.values.campaignBudget, formik.values.cashBackAmt)}
                </strong>{' '}
                potential redemptions
              </p>
            </div>
          </div>
        </li>
        <li className="form-row animated">
          <div
            className="select-item"
            onClick={(e) => {
              document.getElementById('timeWindow').click();
            }}
          >
            <label className="custom-radio">
              <input
                onChange={formik.handleChange}
                defaultChecked={formik.values.cashBackType === 'timeWindow'}
                type="radio"
                name="cashBackType"
                id="timeWindow"
                value="timeWindow"
              />
              <span className="checkmark"></span>
            </label>
            <span>Percentage Back</span>
            <img src={percentGif} className="pt-2 pb-2 mr-2"/>
          </div>
          <div
            className={
              formik.values['cashBackType'] === 'timeWindow' ?
                'select-item-options' :
                'collapse select-item-options'
            }
          >
            <div className="form-row">
              <label>Average Cost Of Item</label>
              <div className="input-group">
                <span className="input-group-addon">$</span>
                <input
                  onChange={formik.handleChange}
                  type="number"
                  // disabled
                  id="averageAmount"
                  name="averageAmount"
                  className="form-control dollar"
                  value={formik.values.averageAmount}
                />
              </div>
            </div>
            <div className="form-row">
              <label>Percentage</label>
              <div className="input-group">
                <span className="input-group-addon">%</span>
                <input
                  onChange={formik.handleChange}
                  type="number"
                  // disabled
                  min="1"
                  max="100"
                  defaultValue={formik?.values?.percentage}
                  className="form-control percent"
                  id="percentage"
                />
              </div>
            </div>
            <div className="form-row">
              <label>Budget</label>
              <div className="input-group">
                <span className="input-group-addon">$</span>
                <input
                  onChange={formik.handleChange}
                  type="number"
                  // disabled
                  id="campaignBudget"
                  name="campaignBudget"
                  className="form-control dollar"
                  value={formik.values.campaignBudget}
                />
              </div>
            </div>
            <div className="alert alert-muted">
              <p>
                <strong>
                  {'~' + potentialRedemptionsPercentages(
                      formik.values?.campaignBudget,
                      formik.values?.averageAmount,
                      formik.values?.percentage,
                  )}
                </strong>{' '}
                <span>potential redemptions</span>
              </p>§
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};
