import React, {useRef} from 'react';
import {UncontrolledTooltip} from 'reactstrap';

const TooltipBox = ({children, placement, title}) => {
  const ref = useRef(null);
  return (
    <>
      <span ref={ref}>
        {children}
      </span>
      <UncontrolledTooltip
        placement={placement}
        target={ref}
      >
        {title}
      </UncontrolledTooltip>
    </>
  );
};

export default TooltipBox;
