import React, {useState, useRef, useEffect} from 'react';
import {useQuery} from '@tanstack/react-query';
import {Card} from 'reactstrap';
import {AgGridReact} from 'ag-grid-react';
import CampaignService from 'shared/services/CampaignService';
import useResize from '../../../../shared/hooks/useResize';
import {CAMPAIGN_TYPES} from '../../../Campaign/config';
import {getPercent} from '../utils';
import {columns, columnsETW} from './config';
import {CustomLoadingOverlay} from './renderers';
import TableFilter from './TableFilter';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../style.scss';

const campaignService = new CampaignService();

const ParticipantListTable = ({campaignData, participants}) => {
  const gridRef = useRef();
  const [rowData, setRowData] = useState([]);
  const [filterParams, setFilterParams] = useState({});
  const isEnterToWin = campaignData?.type === CAMPAIGN_TYPES[1].value;
  const [columnDefs] = useState(isEnterToWin ? columnsETW : columns);

  useResize(() => gridRef.current?.api?.sizeColumnsToFit());
  useQuery([filterParams], () => campaignService.getCampaignUsers(filterParams),
      {
        onSuccess: data => setRowData(data.data),
        onError: error => console.log('error in query', error),
        onSettled: () => gridRef?.current?.api?.hideOverlay(),
        retry: false,
      },
  );
  useEffect(() => {
    setTimeout(() => gridRef.current?.api?.sizeColumnsToFit(), 0);
  }, []);

  const setFilters = props => {
    gridRef?.current?.api?.showLoadingOverlay();
    return setFilterParams({campaignId: campaignData?.id, ...props});
  };

  return (
    <div>
      <Card className="participant-list-table-wrapper">
        <TableFilter setFilterParams={setFilters} isEnterToWin={isEnterToWin}/>
        <div className="ag-theme-alpine" style={{height: 500}}>
          <h4 className="ml-3 font-weight-light">
            <span className="font-weight-bold">{rowData?.length} of </span>
            <span>{participants.length} </span>
            <span>{getPercent(rowData, participants)}% </span>
            <span>total participants fit these criteria</span>
          </h4>
          <AgGridReact
            ref={gridRef} // Ref for accessing Grid's API
            checkboxSelection="true"
            rowData={rowData} // Row Data for Rows
            columnDefs={columnDefs} // Column Defs for Columns
            noRowsOverlayComponent={CustomLoadingOverlay}
            loadingOverlayComponent={CustomLoadingOverlay}
            rowSelection="multiple"
            onGridReady={() => sizeToFit()}
            onFirstDataRendered={() => sizeToFit()}
            defaultColDef={{sortable: true}}
          />
        </div>
      </Card>
    </div>
  );
};

export default ParticipantListTable;
