import Settings from 'views/Settings';
import ParticipantList from '../views/ParticipantList/ParticipantList';
import users from './users';
import account from './account';
import organizations from './organizations';
import clients from './clients';
import campaigns from './campaigns';

export const routes = [
  ...organizations,
  ...clients,
  ...campaigns,
  ...users,
  ...account,
  {
    path: '/clients/campaigns/participantlist',
    name: 'Participant List',
    component: ParticipantList,
    layout: '/admin',
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    layout: '/admin',
  },
];
