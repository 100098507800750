import React, {useState} from 'react';
import {useMutation} from '@tanstack/react-query';
import FileManagementService from '../../../../../../shared/services/FileService';
import {CardImage, XSquareFill} from 'react-bootstrap-icons';

import {Input, Spinner} from 'reactstrap';
import TooltipBox from '../../../../../../shared/components/TooltipBox';

const FMS = new FileManagementService();
const PREFIX = 'input__image';

const ImgInput = ({formik, name, hint, label}) => {
  if (!formik || !name) return null;
  const imageUrl = formik.values?.[name] || null;
  const [loading, _setLoading] = useState(false);
  const ID = `{${PREFIX}__${name}`;

  const setLoading = (value) => {
    _setLoading(value);
    formik.setSubmitting(value);
  };

  const {mutate: uploadLogo} = useMutation(FMS.createFile, {
    onSuccess: (url) => {
      setLoading(false);
      formik.setFieldValue(name, url);
    },
    onError: () => setLoading(false),
  });

  const onChange = (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    formData.append('fileName', e.target.files[0].name);
    setLoading(true);
    uploadLogo(formData);
  };

  const onRemove = () => formik.setFieldValue(name, '');

  return (
    <div className="logoField animatedBox">
      <label htmlFor={ID}>
        {label && <span>{label}</span>}
        <div className='preview'>{
          imageUrl ? <img src={imageUrl} alt="Preview"/> : (
            <div>
              {loading ? <Spinner color="primary" className="page-loader" /> : <CardImage size={100}/>}
            </div>
          )}
        </div>
        <input
          style={{display: 'none'}}
          onChange={onChange}
          type="file"
          id={ID}
        />
      </label>
      <div className="inputBox">
        <Input
          name={name}
          placeholder="Image path"
          {...formik.getFieldProps(name)}
        />
        <div onClick={onRemove}>
          <TooltipBox title="Remove" placement="top-end">
            <XSquareFill size={25} color="red"/>
          </TooltipBox>
        </div>
      </div>
      {hint && <div className="hint">{hint}</div>}
    </div>
  );
};

export default ImgInput;
