import React, {useState} from 'react';
import {Button, Row, Col} from 'reactstrap';
import TextInput from '../../../shared/components/fields/TextInput';
import DateTimePicker from 'shared/components/fields/DateTimePicker';

const fields = [
  {label: 'Receipt Name', name: 'receiptName'},
  {label: 'Merchant Name', name: 'merchantName'},
  {label: 'Merchant Address', name: 'merchantAddress'},
  {label: 'Product name', name: 'productName'},
  {label: 'Price', name: 'productPrice'},
];

const datesFields = [
  {label: 'Accepted from', name: 'acceptedDateStart'},
  {label: 'Accepted to', name: 'acceptedDateEnd'},
];

export const ReceiptDetails = ({formik}) => {
  const [hiddenDates, setHiddenDates] = useState(false);
  const onClear = () => {
    [...fields, ...datesFields].forEach(item => formik.setFieldValue(item.name, ''));
    setHiddenDates(true);
    // Without it, the values in the fields will not change if an empty value is passed
    setTimeout(() => setHiddenDates(false), 0);
  };
  console.log(formik.values?.['campaignDateStart']);
  return (
    <div className="proofDetailsWrap">
      <div className="proofDetails">
        <div className="proofDetailsTitle">Receipt details</div>
        <div className="proofDetailsBox">
          <div className="fieldsList">
            {fields.map(item => (
              <div className="fieldBox">
                <TextInput
                  key={item.label}
                  label={item.label}
                  name={item.name}
                  formik={formik}
                />
              </div>
            ))}
          </div>
          {
            !hiddenDates ?
              (
                <Row >
                  <Col >
                    <DateTimePicker xs={6} label={datesFields[0].label} name={datesFields[0].name} formik={formik}/>
                  </Col>
                  <Col >
                    <DateTimePicker xs={6} label={datesFields[1].label} name={datesFields[1].name} formik={formik}/>
                  </Col>
                </Row>
              ) : <div style={{height: '114px'}}/>
          }
          <div className="proofDetailsFooter">
            <div></div>
            <Button size="sm" outline onClick={onClear} color="danger">Clear</Button>
          </div>
        </div>
      </div>
    </div>
  );
};
