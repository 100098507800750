import React, {useState} from 'react';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import {Input, Button, Label} from 'reactstrap';
import {useMutation} from '@tanstack/react-query';
import CampaignService from 'shared/services/CampaignService';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import debounce from 'lodash.debounce';

import {useEffect} from 'react';
import CsvParser from 'views/components/form-elements/csvReader';

import moment from 'moment';
// import * as Emoji from "quill-emoji

const Email = ({email, index, removeEmail, formik, campaignId}) => {
  const [parsedCsvData, setParsedCsvData] = useState([]);
  const [emailBody, setEmailBody] = React.useState(formik.values?.emailBody);
  const [receipientBody, setReceipientBody] = React.useState('');
  const [disabledAfterUploadCsv, setDisabledAfterUploadCsv] = useState(false);
  // flatten the array of arrays from parsedCsvData and remove duplicates

  const campaignService = new CampaignService();

  const removeEmailDuplicates = parsedCsvData.flat().filter((v, i, a) => a.indexOf(v) === i);


  // TODO: should we be creating a new campaign service every render? does it matter?

  // const debouncedChangeHandler = useCallback((fn) => debounce(fn, 500), []);

  const {mutate: updateEmail} = useMutation(
      campaignService.updateEmailTrigger,
      {
        onSuccess: (data) => {
        // console.log(data);
        },
        onError: (error) => {
          console.error('error', error);
        },
      },
  );

  const debouncedChangeHandler = React.useRef(
      debounce((data) => updateEmail(data), 500),
  ).current;

  const onDateChange = (event) => {
    const newDate = event;
    const thedatetest =
      newDate.format('YYYY-MM-DD') + 'T' + newDate.format('HH:mm:ss');

    formik.handleChange({
      target: {
        name: `emails[${index}].SendOutDateTime`,
        value: thedatetest,
      },
    });
  };

  React.useEffect(() => {
    const email = formik.values[`emails`][index];

    debouncedChangeHandler({...email, emailBody: emailBody, campaignId: campaignId});

    return () => {
      debouncedChangeHandler.cancel();
      formik.handleChange({
        target: {
          name: `emails[${index}].emailBody`,
          value: emailBody,
        },
      });
    };
  }, [formik.values[`emails`][index], emailBody]);

  useEffect(() => {
    // handle the side effect of updating formik and input if a file is dragged in.
    setReceipientBody(removeEmailDuplicates.toString().replace(/,/g, ', \n'));
    if (parsedCsvData.length > 0) {
      setDisabledAfterUploadCsv(true);
    }

    formik.handleChange({
      target: {
        name: `emails[${index}].emailTo`,
        value: receipientBody,
      },
    });
  }, [parsedCsvData]);

  return (
    <>
      <div className="email">
        <h3>Create a promotional email</h3>
        <p>
          <strong>Name this email</strong>
        </p>
        <p>The email name is shown in your reports and email archives</p>
        <Input
          type="text"
          name={`emails[${index}].emailName`}
          defaultValue={formik.values?.emails[index]?.emailName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        ></Input>
        <br />
        <br />
        <p>
          <strong>Write a subject line</strong>
        </p>
        <p>The email name is shown in your reports and email archives</p>
        <Input
          type="text"
          name={`emails[${index}].emailSubject`}
          defaultValue={formik.values?.emails[index]?.emailSubject}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        ></Input>
        <br />
        <br />
        <p>
          <strong>Who is it from?</strong>
        </p>
        <p>This is displayed in your recipients' From fields</p>
        <Input
          type="text"
          defaultValue={formik.values?.emails[index]?.emailFrom}
          name={`emails[${index}].emailFrom`}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        ></Input>
        <br />
        <br />
        <p>
          <strong>Who are you sending it to?</strong>
        </p>
        <p>Upload a recipient list or enter manually.</p>
        <p>
          Each recipient should be on their own line, with each piece of data
          separated by a comma.
        </p>
        {
      parsedCsvData.length > 0 ? (
        <Label>Items detected: {removeEmailDuplicates.length} (Note: Duplicate recipients in upload are automatically removed from recipient list.)</Label>

      ) : null
        }

        <Input
          id={`emailRecipients[${index}]`}
          type="textarea"
          rows="30"
          value={receipientBody}
          disabled={disabledAfterUploadCsv}
          name={`emails[${index}].emailTo`}
          onChange={(e) => {
            setReceipientBody(e.target.value);
          }}
          onBlur={formik.handleBlur}
        />
        <br />
        <br />

        <CsvParser setParsedCsvData={setParsedCsvData}/>

        <br />
        <br />
        <br />
        <p>
          <strong>Content</strong>
        </p>
        <p>Define the content for your email</p>
        <ReactQuill
          style={{height: '150px'}}
          theme="snow"
          value={emailBody}
          defaultValue={formik.values?.emails[index]?.emailBody}
          onChange={(value) => {
            setEmailBody(value);
          }}
        />
        <br />

        <br />

        <div className="my-4">
          <strong>When will this being sent out?</strong>
          <Datetime
            id="sendOutDateTimePicker"
            name="sendOutDateTimePicker"
            className="mt-2"
            value={moment(formik.values.emails[index].sendOutDateTimePicker).format('MM/DD/YYYY')}
            onChange={onDateChange}
            inputProps={{
              className: 'form-control',
              placeholder: '1/1/2022 12:00 AM',
            }}
            onBlur={formik.handleBlur}
          />
        </div>
        <Button
          id="buildEmail"
          onClick={() => {
            // should we have as update instead?
            saveEmail(formik.values['emails'][index]);
          }}
        >
          Build Email
        </Button>

      </div>
    </>
  );
};

export default Email;
