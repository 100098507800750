import React, {useContext, useEffect, useRef, useState} from 'react';
import {CardBody, Spinner} from 'reactstrap';
import {useQuery} from '@tanstack/react-query';
import CampaignService from '../../../shared/services/CampaignService';
import BarChart from './BarChart';
import {BARS_COUNT, SPENDING_TIME_KEY} from './const';
import {createChartData, formatData, getCompleted, sortByMaxPeriod} from './utils';
import RangePeriod from './RangePeriod';
import {ChosenLocation} from '../../../contexts/RouterContext/ChosenLocation';

// eslint-disable-next-line no-unused-vars


export const campaignService = new CampaignService();

const AverageTimeChart = () => {
  const [chosenLocation] = useContext(ChosenLocation);
  const campaignId = chosenLocation.campaign;
  const {data, error, isLoading} = useQuery(['campaign_time_report'], () =>
    campaignService.getUserListingForCampaign(campaignId),
  {cacheTime: 0},
  );
  const userList = data?.data || [];
  const completedUserListFull = sortByMaxPeriod(formatData(getCompleted(userList)));
  const minRange = completedUserListFull?.[0]?.[SPENDING_TIME_KEY];
  const maxRange = completedUserListFull?.[completedUserListFull?.length - 1]?.[SPENDING_TIME_KEY];
  const [range, setRange] = useState({start: 0, end: 24});
  const [showChart, setShowChart] = useState(true);
  const ref = useRef(null);

  useEffect(() => {
    setShowChart(false);
    setTimeout(() => {
      setShowChart(true);
    }, 100);
  }, [range.start, range.end]);

  if (error) return <span>Something went wrong</span>;

  const completedUserList = completedUserListFull.filter((item) => {
    const itemTime = item[SPENDING_TIME_KEY];
    return ((itemTime >= range.start) && (itemTime <= range.end));
  });

  const minPeriodMinutes = completedUserList[0]?.[SPENDING_TIME_KEY] || 0;
  const _maxPeriodMinutes = completedUserList[completedUserList.length - 1]?.[SPENDING_TIME_KEY] || 0;
  let maxPeriodMinutes = _maxPeriodMinutes - minPeriodMinutes;
  const multipleOf24Rest = maxPeriodMinutes % BARS_COUNT;
  maxPeriodMinutes = maxPeriodMinutes + (BARS_COUNT - multipleOf24Rest);
  const frameSizeMinutes = maxPeriodMinutes / BARS_COUNT;
  const chartData = createChartData(completedUserList, frameSizeMinutes, minPeriodMinutes);
  const onRangeChange = (min = 0, max = BARS_COUNT) => {
    setRange({start: min, end: max});
  };

  const height = 250;
  return (
    <CardBody>
      <div ref={ref} style={{height}}>
        {
          !isLoading && (
            <RangePeriod
              min={minRange}
              max={maxRange}
              onChange={onRangeChange}
            />
          )
        }
        {
					isLoading ? <Spinner color="primary" className="page-loader"/> : (
						<>
						  {showChart && <BarChart height={height - 60} width={ref?.current?.clientWidth} data={chartData || []}/>}
						</>
					)
        }
      </div>
    </CardBody>
  );
};

export default AverageTimeChart;
