import React, {useState} from 'react';
import {DateTime as luxDate} from 'luxon';
import ProfileNav from './ProfileNav';
import {joinClasses} from '../../utils';
import {ROLES} from '../../routes/config';
import RenderWithRoles from '../../shared/components/RenderWithRoles';

const ProfileHeader = ({data}) => {
  const {image, firstName, lastName, createdAt, status, title} = data;
  const [statusValue, setStatusValue] = useState(status);

  return (
    <header className="profileHeader">
      <div className="__left">
        <div className="profileLogo">
          {
            image ? <img className="profileImg" src={image} alt="Logo"/> : <div className="profileImg">{firstName?.[0]}</div>
          }
        </div>
        <div className="profileDesc">
          <h6>{firstName || 'None'} {lastName || 'None'}</h6>
          <span>{title}</span>
        </div>
      </div>
      <div className="__right">
        <div className="profileCreated">
          <span>Created: </span>
          <span>{createdAt ? luxDate.fromISO(createdAt).toFormat('MM/dd/yyyy') : 'Missing'}</span>
        </div>
        <div
          className={joinClasses('profileStatus', statusValue === 'Active' && '_active')}
        >
          {statusValue}
        </div>
        <RenderWithRoles
          validRoles={[ROLES.platform]}
          allowed={(
            <div className="profileMenu">
              <ProfileNav setStatusValue={setStatusValue} statusValue={statusValue} ser data={data}/>
            </div>
          )}
        />
      </div>
    </header>
  );
};

export default ProfileHeader;
