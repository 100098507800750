import React, {useState, useEffect} from 'react';
import {Input, Label} from 'reactstrap';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {useMutation} from '@tanstack/react-query';
import CampaignService from 'shared/services/CampaignService';
import debounce from 'lodash.debounce';
import CsvParser from 'views/components/form-elements/csvReader';

const Texts = (props) => {
  const {formik, index, campaignId} = props;
  const [textMessage, setTextMessage] = React.useState(formik.values?.textMessage);
  const [parsedCsvData, setParsedCsvData] = useState([]);
  const [DisabledAfterCsvUpload, setDisabledAfterCsvUpload] = useState(false);
  const [receipientBody, setReceipientBody] = React.useState('');
  const removedTextDuplicates = parsedCsvData.flat().filter((v, i, a) => a.indexOf(v) === i);
  const campaignService = new CampaignService();

  const {mutate: updateText} = useMutation(
      campaignService.updateTextTrigger,
      {
        onSuccess: (data) => {
        // console.log("success", data);
        },
        onError: (error) => {
          console.log('error', error);
        },
      },
  );

  const [sendOutDateTime, setSendOutDateTime] = React.useState(
      formik.values['campaignDateStart'] ?? '',
  );

  const debouncedChangeHandler = React.useRef(
      debounce((data) => updateText(data), 500),
  ).current;

  React.useEffect(() => {
    const text = formik.values[`texts`][index];

    debouncedChangeHandler({
      ...text,
      textMessage: textMessage,
      sendOutDateTime: sendOutDateTime,
      campaignId: campaignId,
    });

    return () => {
      debouncedChangeHandler.cancel();
      formik.handleChange({
        target: {
          name: `texts[${index}].textMessage`,
          value: textMessage,
        },
      });
    };
  }, [formik.values[`texts`][index], textMessage, sendOutDateTime]);

  useEffect(() => {
    // handle the side effect of updating formik and input if a file is dragged in.
    setReceipientBody(removedTextDuplicates.toString().replace(/,/g, ', \n'));
    if (parsedCsvData.length > 0) {
      setDisabledAfterCsvUpload(true);
    }
    formik.handleChange({
      target: {
        name: `texts[${index}].PhoneList`,
        value: receipientBody,
      },
    });
  }, [parsedCsvData]);
  return (
    <div className="texts">
      <h3>Create a promotional text</h3>
      <p>
        <strong>Name this text</strong>
      </p>
      <p>The text name is shown in your reports and text archives</p>
      <Input
        type="text"
        name={`texts[${index}].TextName`}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      ></Input>
      <br />
      <br />
      <p>
        <strong>Who are you sending it to?</strong>
      </p>
      <p>Upload a recipient list or enter manually.</p>
      <p>
        Each recipient should be on their own line, with each piece of data
        separated by a comma.
      </p>
      {
      parsedCsvData.length > 0 ? (
        <Label>Items detected: {removedTextDuplicates.length} (Note: Duplicate recipients in upload are automatically removed from recipient list.)</Label>

      ) : null
      }
      <Input
        id={`textRecipients[${index}]`}
        type="textarea"
        name={`texts[${index}].PhoneList`}
        onChange={(e) => {
          setReceipientBody(e.target.value);
        }}
        value={receipientBody}
        disabled={DisabledAfterCsvUpload}
        onBlur={formik.handleBlur}
      ></Input>
      <CsvParser setParsedCsvData={setParsedCsvData}/>
      <br />
      <strong>What is the body of the message?</strong>
      <p>Write copy for the text message and add link</p>
      <ReactQuill
        style={{height: '150px'}}
        theme="snow"
        value={textMessage}
        onChange={(value) => {
          setTextMessage(value);
        }}
      />
      <br />
      <br />
      <br />
      <strong>When will this being sent out?</strong>
      <div className="my-2">

        <Datetime
          id="sendOutDateTimePicker"
          name="sendOutDateTimePicker"
          onChange={(value) => {
            setSendOutDateTime(value);
          }}
          inputProps={{
            className: 'form-control',
            placeholder: '1/1/2022 12:00 AM',
          }}
          onBlur={formik.handleBlur}
        />
      </div>
    </div>
  );
};

export default Texts;
