import React, {useState} from 'react';
import {Row, Col} from 'reactstrap';
import OptionsGroup from './OptionsGroup';
import OptionsGroupForm from './OptionsGroupForm';

const DesignerRow = ({formik, formList, groupLabel, defaultItem}) => {
  const [activeItem, setActiveItem] = useState(-1);
  return (
    <Row>
      <Col xs="5">
        <OptionsGroup
          groupLabel={groupLabel}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          formList={formList}
          defaultItem={defaultItem}
        />
      </Col>
      <Col xs="7">
        <OptionsGroupForm formik={formik} formKey={activeItem} formList={formList}/>
      </Col>
    </Row>
  );
};

export default DesignerRow;
