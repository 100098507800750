import React, {useContext} from 'react';
import {Input, FormGroup, Label, Row, Col} from 'reactstrap';
import {Link} from 'react-router-dom';
import {ChosenLocation} from '../../../contexts/RouterContext/ChosenLocation.js';

const FilterBar = (props) => {
  const {currentPage, navigateUrl, dropdownOption, setQuickFilter} = props;

  const [chosenLocation, setChosenLocation] = useContext(ChosenLocation);
  const handleResetContext = () => {
    if (currentPage == 'Campaigns') {
      // since the user can take paths to set then decide later to create a context we need to clear all values since createCampaign.js will think were editing a campaign
      setChosenLocation({
        organization: chosenLocation.organization,
        client: chosenLocation.client,
      });
    }
  };


  return (
    <>
      <Row className='mb-4 d-flex filter-bar-wrapper'>
        <Row className="d-flex align-items-center">
          <Col className='d-flex'>
            <FormGroup>
              <Label className='d-flex justify-content-end mb-0'>
                <Input onChange={setQuickFilter} className='text-center c-pill-input rounded-pill c-3vh-fullhd p-0' type='select' name='select' id='select'>
                  {
                    dropdownOption.map((option, index) => {
                      return (
                        <option key={index}>{option}</option>
                      );
                    })
                  }
                </Input>
              </Label>
            </FormGroup>
          </Col>

          <Col className='c-width-14 d-flex justify-content-end'>
            <Link onClick={handleResetContext} to={navigateUrl} className='c-pill-input-button d-flex align-items-center justify-content-center text-center text-decoration-none c-3vh-fullhd c-small-font'>
              {/* <PlusCircleFill color='royalblue' size={26} /> */}
              <p className='text-center text-white m-auto'>Create New {currentPage == 'Campaigns' ? 'Campaign' : currentPage}</p>
            </Link>
          </Col>
        </Row>

      </Row>
    </>
  );
};


export default FilterBar;
