import React from 'react';
import Form from './Form';
import {userService, authService2} from './api';
import {useHistory} from 'react-router-dom';
import {useMutation} from '@tanstack/react-query';

const UserCreate = () => {
  const history = useHistory();
  const [createError, setCreateError] = React.useState(null);

  const {mutate: createUser} = useMutation((value)=>{
    return authService2.createUser(value);
  },
  {
    onSuccess: (result)=>{
      history.push(`/admin/users/edit/${result.data?.id}`);
    },
    onError: (error) => {
      setCreateError(error.response.data);
    },
  });

  const onSubmit = async (values) => {
    createUser(values);
  };

  return (
    <div className="sectionBox _mini">
      <h5>Create Alpha 6 Admin User</h5>
      <Form onSubmit={onSubmit} initialValues={{}} submitError={createError}/>
    </div>
  );
};


export default UserCreate;
