import React, {useState} from 'react';
import {Card, CardBody, Col, Row} from 'reactstrap';
import {DateTime as luxDate} from 'luxon';
import {userService} from './api';


const UserProfileBody = ({data}) => {
  const {email, roles, createdAt, phone} = data;
  const [message, setMessage] = useState(null);

  const resetPass = async (e) => {
    e.preventDefault();
    try {
      await userService.resetPassword(email);
      setMessage('Success');
    } catch (e) {
      console.log('e', e);
      setMessage(e.message);
    }
  };

  return (
    <div className="profileBody">
      <Row>
        <Col sm={8}>
          <Card className="__left">
            <CardBody>
              <ul className="profileDescription">
                <li>
                  <h6>Password:</h6>
                  <a onClick={resetPass} href="#">Send password reset</a>
                  {message && <div>{message}</div>}
                </li>
                <li>
                  <h6>Email address:</h6>
                  <p>{email}</p>
                </li>
                <li>
                  <h6>Phone number:</h6>
                  <p className="cardDescPhone">{phone || '+1 ( ___ ) ___ ___'}</p>
                </li>
              </ul>
            </CardBody>
          </Card>
        </Col>
        <Col sm={4}>
          <Card className="__right">
            <CardBody>
              <ul className="profileDescription">
                <li>
                  <h6>Account Created:</h6>
                  <p>{createdAt ? luxDate.fromISO(createdAt).toFormat('MM/dd/yyyy') : '00/00/0000'}</p>
                </li>
                <li>
                  <h6>Roles:</h6>
                  <p>{roles?.length > 0 ? roles.map(item => item.role).join(', ') : 'None'}</p>
                </li>
                <li>
                  <h6>Client access:</h6>
                  <p>All</p>
                </li>
              </ul>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default UserProfileBody;
