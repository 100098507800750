import React from 'react';
import Select from 'react-select';
import stateList from '../../../shared/USStates';
import {Col, Row} from 'reactstrap';

const states = [...stateList];
states.shift();

const StateGroup = ({onClick, selected}) => {
  const onChange = value => {
    const result = value.map(item => item.value);
    onClick(result.join('|'));
  };

  const values = selected ? selected.split('|')?.map(itemSTR => {
    return states.find(item => item.value === itemSTR) || {};
  }) : [];

  return (
    <Row offset="0" p="0">
      <Col xs="2" style={{paddingRight: 0}}>
        <div>State</div>
      </Col>
      <Col xs="10">
        <div className="styled-select">
          <Select
            placeholder="Select state"
            closeMenuOnSelect={false}
            options={states}
            onChange={onChange}
            isMulti
            value={values}
            menuPortalTarget={document.body}
            styles={{menuPortal: base => ({...base, zIndex: 1, fontSize: '12px'})}}
          />
        </div>
      </Col>
    </Row>
  );
};

export default StateGroup;
