import Login from '../../views/account/Login';
import Logout from '../../views/account/Logout';
import ForgotPassword from '../../views/account/ForgotPassword';
import ResetPassword from '../../views/account/ResetPassword';

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    layout: '/auth',
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    layout: '/auth',
  },
  {
    path: '/forgotpassword',
    name: 'ForgotPassword',
    component: ForgotPassword,
    layout: '/auth',
  },
  {
    path: '/resetpassword',
    name: 'ResetPassword',
    component: ResetPassword,
    layout: '/auth',
  },
];

export default routes;
