import React from 'react';
import 'react-color-palette/lib/css/styles.css';
import 'bootstrap/dist/css/bootstrap.css';
import {Container} from 'reactstrap';
import * as yup from 'yup';
import {DesignerRow} from './designerRow';
import TitleRow from './TitleRow';
import {
  footerForms,
  initialSubmissionForms,
  offerExpForms,
  offerLookForms,
  URL_REG_EXP,
} from './formConfig';
import {CAMPAIGN_TYPES} from '../../../Campaign/config';

import './style.scss';


export const campaignDesignerValidation = {
  confirmationLinkToBuyURL: yup.string().matches(URL_REG_EXP, 'Enter a valid URL! Example: https://google.com'),
};

const Designer = ({formik}) => {
  const isEnterToWin = formik?.values?.type === CAMPAIGN_TYPES[1].value;
  const disabled = !formik.values.winnerPhotoReq2;

  return (
    <Container className="designerWrapper animatedBox">
      <TitleRow/>
      <DesignerRow formik={formik} formList={offerLookForms()} groupLabel="Offer Look & Feel" defaultItem={0}/>
      <br/>
      <DesignerRow
        formik={formik}
        formList={isEnterToWin ? initialSubmissionForms({disabled}) : offerExpForms()}
        groupLabel="Offer Experience"
        defaultItem={0}
      />
      <br/>
      <DesignerRow formik={formik} formList={footerForms()} groupLabel="Footer" defaultItem={0}/>
      <br />
    </Container>
  );
};

export default Designer;
