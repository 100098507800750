import Organization from '../../views/Organization';
import CreateOrganization from '../../views/components/organization/CreateOrganization';

const routes = [
  {
    path: '/organizations',
    name: 'Organization',
    component: Organization,
    layout: '/admin',
    exact: true,
  },
  {
    path: '/organizations/create',
    name: 'Create Organization',
    component: CreateOrganization,
    layout: '/admin',
  },
];

export default routes;
