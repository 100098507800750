import React from 'react';
import {Col, Row} from 'reactstrap';
import {DateTime as luxDate} from 'luxon';
import {SwitchInput, TextInput} from '../../shared/components/fields';
import {formatPhoneNumber} from '../../utils';
const MainFields = ({formik, isEdit}) => {
  return (
    <>
      {
        isEdit && (
          <Row>
            <Col sm={6}/>
            <Col sm={6}>
              <div className="text-right">
                <label>Created:</label>
                <span>
                  {' '}
                  <i className="zmdi zmdi-calendar text-orange mr-1"></i>{' '}
                  {formik.values?.createdAt ?
                    luxDate.fromISO(formik.values?.createdAt).toFormat('MM/dd/yyyy hh:mm a') :
                    'Missing'}
                </span>
              </div>
              <div className="text-right">
                <SwitchInput
                  label="Is active"
                  name="status"
                  specialValues={{'true': 'Active', 'false': 'InActive'}}
                  formik={formik}
                />
              </div>
            </Col>
          </Row>
        )
      }
      <Row>
        <Col sm={6}>
          <TextInput
            label="First Name"
            name="firstName"
            formik={formik}
          />
        </Col>
        <Col sm={6}>
          <TextInput
            label="Last Name"
            name="lastName"
            formik={formik}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <TextInput
            label="Email Address"
            name="email"
            formik={formik}
            disabled={isEdit}
          />
        </Col>
        <Col sm={6}>
          <TextInput
            label="Mobile Number"
            name="phone"
            onChange={(e) => {
              formik.setFieldValue('phone', formatPhoneNumber(e.target.value) || '');
            }}
            maxLength={14}
            value={formatPhoneNumber(formik.values?.phone)}
            formik={formik}
          />
        </Col>
      </Row>
    </>
  );
};

export default MainFields;
