import React from 'react';
import {CampaignDetails, Form} from './index';
import {EditorHtml} from './fields';
import CameraIcon from './CameraIcon';

const InitialSubmission = ({formik, onSubmit}) => {
  return (
    <div>
      <Form formik={formik} onSubmit={onSubmit}>
        <CameraIcon disable={!formik.values?.winnerPhotoReq1}/>
        <EditorHtml label="Heading" formik={formik} name="initialHeading" />
        <EditorHtml label="Description" formik={formik} name="initialDescription" minHeight="80px" />
        <hr/>
        <CampaignDetails formik={formik}/>
      </Form>
    </div>
  );
};

export default InitialSubmission;
