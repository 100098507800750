import {React, useState} from 'react';
import {useHistory} from 'react-router-dom';

import {
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  FormText,
  Button,
  Col,
} from 'reactstrap';
import OrganizationService from 'shared/services/OrganizationService';
import {useQueryClient, useMutation} from '@tanstack/react-query';
import FileService from 'shared/services/FileService';

const CreateOrganization = () => {
  const history = useHistory();
  const [orgName, setOrgName] = useState('');
  const [logo, setLogo] = useState(null);

  const queryClient = useQueryClient();
  const organizationService = new OrganizationService();
  const fileService = new FileService();

  const {mutate: createOrganization, isLoading: loadingCamp} = useMutation(
      organizationService.createOrganization,
      {
        onSuccess: (data) => {
          console.log(`success!`);
        },
        onError: () => {
          alert('there was an error');
        },
        onSettled: () => {
          queryClient.invalidateQueries('organizations');
          history.push('/admin/Organizations');
        },
      },
  );

  const {mutate: createFile, isLoading: loadingFile} = useMutation(
      async (formData)=> {
        return await fileService.createFile(formData);
      },
      {
        onSuccess: (returnLogo) => {
          const orgData = {
            Logo: returnLogo.replace(/["]+/g, ''),
            Name: orgName,
            IsActive: 1,
            dateCreated: new Date(),
          };

          createOrganization(orgData);
          console.log(`success! ${returnLogo}`);
        },
        onError: () => {
          alert('Please upload a logo and input an organization name. Or wait a minute and try again...');
        },
      },
  );

  const handleCreateOrg = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('file', logo);
    formData.append('fileName', logo.Name);
    formData.append('orgname', orgName);

    createFile(formData);
  };

  return (
    <div className="h-100 w-100 container-fluid d-flex flex-column">
      <Row className="h-100 w-100">
        <h4 className="font-weight-normal">Organization Details</h4>
      </Row>
      <Row className="d-flex  w-100">
        <Col sm="12">
          <Form
            onSubmit={(e) => handleCreateOrg(e)}
            className="bg-white rounded-lg shadow-sm h-100 d-flex flex-column p-3"
          >
            <FormGroup className="mt-5 ">
              <Label for="orgname" className="font-weight-bold">
              Organization Name
              </Label>
              <Input
                type="text"
                name="orgname"
                value={orgName}
                onChange={(e) => setOrgName(e.target.value)}
                placeholder="Enter organization name"
                className="w-100"
              ></Input>
            </FormGroup>
            <FormGroup className="mt-2">
              <Label for="logoUpload" className="font-weight-bold">
              Upload Organization Logo
              </Label>
              <Input
                type="file"
                name="logoUpload"
                id="logoUpload"
                className="bg-white w-100"
                onChange={(e) => setLogo(e.target.files[0])}
              />
              <FormText color="muted">
              Upload your organizations logo. Let the user know any image
              restrictions here; size, format or otherwise.
              </FormText>
            </FormGroup>
            <Button
              type="submit"
              className="mt-5 rounded-pill"
              style={{backgroundColor: '#1898DA'}}
              disabled={loadingCamp || loadingFile}
            >
            Create Organization
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default CreateOrganization;
