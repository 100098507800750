import React from 'react';
import {CashBack} from './CashBack';
import {ProofOfPurchase} from './ProofOfPurchase';
import {CashDisbursement} from './CashDisbursement';
import {OfferTiming} from './OfferTiming';

import './style.scss';

export const campaignDetailsValidation = {
  // permittedRedemption: yup.string().required("Required"),
  // timeLimitHour: yup.string().required("Required"),
  // timeLimitMin: yup.string().required("Required"),
  // cashBackType: yup.string().required("Required"),
  // purchaseProof: yup.string().required("Required"),
};

const OfferDetails = (props) => {
  const formik = props.formik;

  return (
    <div>
      <div>
        <CashBack formik={formik}/>
      </div>
      <div>
        <OfferTiming formik={formik}/>
      </div>
      <div className="form-row animated pb-0 border-bottom-0" style={{position: 'relative', zIndex: 1}}>
        <ProofOfPurchase formik={formik}/>
      </div>
      <div className="form-row animated pb-0 border-bottom-0">
        <CashDisbursement formik={formik}/>
      </div>
      <br/>
    </div>
  );
};

export default OfferDetails;
