import {Button, Table} from 'reactstrap';
import {Trash, PlusSquare} from 'react-bootstrap-icons';
import SelectInput from '../../shared/components/fields/SelectInput';

const RolesSettings = ({formik, orgList, rolesList}) => {
  const onDeleteRole = (i) => () => {
    const values = [...formik.values?.roles];
    values.splice(i, 1);
    formik.setFieldValue('roles', values);
  };

  const addItem = () => {
    formik.setFieldValue('roles', [...formik.values?.roles, {role: null, organizationId: null}]);
  };

  return (
    <div className="rolesSettings">
      <Table size="sm" hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Role Name</th>
            <th>Organization</th>
            <th>Del</th>
          </tr>
        </thead>
        <tbody>
          {
            formik.values?.roles?.map((item, i) => {
              return (
                <tr key={item.roleId}>
                  <th>{i + 1}</th>
                  <th>
                    <SelectInput
                      name={`roles[${i}].roleId`}
                      formik={formik}
                      options={rolesList}
                    />
                  </th>
                  <th>
                    <SelectInput
                      name={`roles[${i}].organizationId`}
                      formik={formik}
                      options={orgList}
                    />
                  </th>
                  <th>
                    <Button onClick={onDeleteRole(i)} size="sm" outline color="danger"><Trash/></Button>
                  </th>
                </tr>
              );
            })
          }
        </tbody>
      </Table>
      <div className="rolesSettingsFooter">
        <span/>
        <Button onClick={addItem} size="sm" outline color="info">
          <PlusSquare/>
          <span>Add role</span>
        </Button>
      </div>
    </div>
  );
};

export default RolesSettings;
