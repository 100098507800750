import {axiosInstance} from '../../utils';

const url = '/File/file-upload';

class FileManagementService {
  createFile = (formData) => {
    return axiosInstance.post(url, formData).then((response) => {
      return JSON.stringify(response.data).replace(/["]+/g, '');
    });
  };
}

export default FileManagementService;
