import React, {useEffect} from 'react';

import {Container, Spinner} from 'reactstrap';
import {useHistory} from 'react-router-dom';
import {authService2} from 'views/Users/api';

function Logout() {
  const history = useHistory();
  useEffect(() => {
    authService2.logout();
    setTimeout(() => {
      history.push('/');
    }, 1000);
  }, []);

  return (
    <Container style={{color: '#ededed', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <div style={{textAlign: 'center'}}>
        <h1>Logout process</h1>
        <Spinner color="primary" style={{fontSize: 24, width: 50, height: 50}}/>
      </div>
    </Container>
  );
}

export default Logout;
