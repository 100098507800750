import React from 'react';
import {Link} from 'react-router-dom';
import {Spinner} from 'reactstrap';
import './styles.scss';
const TableDataCard = ({
  icon,
  mainText,
  valueAmount,
  yesAmount,
  noAmount,
  loading,
}) => {
  return (
    <>
      <div style={{color: '#5F38FE'}}>{icon}</div>
      {loading ? (
          <Spinner />
      ) : (
        <div className={valueAmount ? '' : 'position-items mb-2'}>
          <div className="text-right">{mainText}</div>
          {valueAmount ? (
            <div className="text-right font-weight-bold mt-4 center">
              {' '}
              {valueAmount}
            </div>
          ) : (
            <div style={{marginTop: '8px'}}>
              <div>
                <div className="d-flex justify-content-between text-center">
                  <p className="font-weight-bold ">{yesAmount}</p>
                  <p> Yes </p>
                </div>
                <hr className="my-0 py-0" />
                <div
                  style={{marginTop: '10px'}}
                  className="d-flex justify-content-between"
                >
                  <p className="font-weight-bold">{noAmount}</p>
                  <p> No </p>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default TableDataCard;
