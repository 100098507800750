import React from 'react';
import {Row, Col, CardBody} from 'reactstrap';
import paypal from '../../../../assets/img/paypal_logo.jpeg';
import venmo from '../../../../assets/img/Venmo-Logo.png';

const Methods = (props) => {
  const {formik} = props;
  return (
    <>
      <Row style={{marginBottom: '0px'}}>
        <Col>
          <h3
            className="text-primary"
            style={{
              textAlign: 'left',
              marginBottom: '0px',
            }}
          >
            Cash Disbursement Methods
          </h3>
        </Col>
        <Col className="text-right">
          <div
            className="btn btn-link btn-edit btn-sm"
            style={{textAlign: 'right', padding: '0px', margin: '0px'}}
            onClick={() => props.setHorizontalTabs('offer')}
          >
            <i className="zmdi zmdi-edit"></i> Edit
          </div>
        </Col>
      </Row>
      <div className="card mt-4" style={{padding: '20px'}}>
        <CardBody>
          <Row className="pl-4 pr-4">
            {formik.values?.cashDisbursementMethod != '' && formik.values?.cashDisbursementMethod
                ?.split('|')
                .filter((x) => x !== 'Check')
                .map((method, index) => {
                  return (
                    <Col key={method}>
                      {method === 'PayPal' && <img style={{maxWidth: '200px'}} height="110px" src={paypal}/>}
                      {method === 'Venmo' && <img style={{maxWidth: '200px'}} height="110px" src={venmo}/>}
                      <span className="d-none">{method}</span>
                      {/* <p className="small">*/}
                      {/*  <label>Account:</label> Not Implemented*/}
                      {/* </p>*/}
                    </Col>
                  );
                })}
            {formik.values?.cashDisbursementMethod == '' &&
              <Col>
                <div>No methods chosen...</div>
              </Col>}
          </Row>
        </CardBody>
      </div>
    </>
  );
};

export default Methods;
