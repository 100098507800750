import React from 'react';
import {Form} from './index';
import {EditorHtml} from './fields';


const Copyright = ({formik, onSubmit}) => {
  return (
    <div>
      <Form formik={formik} onSubmit={onSubmit}>
        <EditorHtml label="Heading" formik={formik} name="footerCopyrightHeading" />
        <EditorHtml label="Description" formik={formik} name="footerCopyrightDescription" minHeight="80px" />
      </Form>
    </div>
  );
};

export default Copyright;
