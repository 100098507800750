import React from 'react';
import {Row, Col, CardBody, Card, CardText} from 'reactstrap';
import InfoCard from '../components/shared/InfoCard';
import {campaignActiveRange} from './utils';
import {calculateCampaignEndDateTime} from '../../shared/utils';
import './style.scss';

const HeaderEWT = ({campaignData}) => {
  const {
    etwTotalEntries: total,
    etwInitialEntries: init,
    etwSecondaryEntries: second,
    landingCount,
    campaignDateStart,
    campaignDateEnd,
  } = campaignData || {};

  return (
    <Row className="mx-0">
      <Col sm="12" md="3" className="px-0 my-2">
        <InfoCard
          navLocation={'./participantlist'}
          cardMainText={'Total Reach'}
          pillColor={'success'}
          valueAmount={landingCount ?? 0}
          linkText={'View Participants'}
        />
      </Col>
      <Col sm="12" md="6" className="px-0 my-2">
        <Card className='mx-2 individual-info-card'>
          <CardBody>
            <Row>
              <Col xs="6">
                <p className='text-muted'>Total entries:</p>
              </Col>
              <Col xs="6">
                <p><CardText className="font-weight-bold mb-0" style={{fontSize: '17px'}}>{total}</CardText></p>
              </Col>
            </Row>
            <Row>
              <Col xs="6">
                <CardText className="font-weight-bold mb-0">{init}</CardText>
                <p>Initial entries</p>
              </Col>
              <Col xs="6">
                <CardText className="font-weight-bold mb-0">{second}</CardText>
                <p>Secondary entries</p>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <Col sm="12" md="3" className="px-0 my-2">
        <InfoCard
          cardMainText={'Campaign Active'}
          valueAmount={campaignActiveRange(campaignData)}
          pillText={calculateCampaignEndDateTime(campaignDateStart, campaignDateEnd)}
          pillColor={'info'}
        />
      </Col>
    </Row>
  );
};

export default HeaderEWT;
