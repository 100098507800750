import React, {useEffect, useRef} from 'react';
const topOffset = 62;

const StickyBox = ({children, distanceFromTop= topOffset, hidden, isFixed= true}) => {
  distanceFromTop -=topOffset;
  const topPosition = distanceFromTop >= 0 ? 0 : distanceFromTop * -1;
  const prevPos = useRef(topPosition);

  useEffect(() => {
    prevPos.current = topPosition;
  });

  const pos = prevPos.current <= topPosition ? `translateY(${topPosition}px)`: 'none';

  return (
    <div className="stickyBox" style={{
      zIndex: 2,
      transitionDuration: isFixed ? '300ms' : 0,
      transitionProperty: isFixed ? 'transform' : 'none',
      position: 'relative',
      transform: isFixed ? pos : `translateY(0px)`,
      display: hidden ? 'none' : 'block',
    }}>
      {children}
    </div>
  );
};
export default StickyBox;
