import React, {useEffect, useRef, useState} from 'react';
import {Row, Col, TabContent, TabPane, Spinner, Alert} from 'reactstrap';
import {Step1, Step2, Step3, Step4, Step5, Step6} from './Steps';
import {useFormik} from 'formik';
import './style.scss';
import Warning from './Warning';
import {compareFormValues, findPushCampaignErrors} from './formValidation';
import Navigation from './Navigation';
import SubmitBox from './SubmitBox';
import {joinClasses} from '../../utils';

const Form = props => {
  const {initialValues, onSubmit, validationSchema, isEdit, initTabKey, successMessage, errorMessage} = props;
  const campaignId = initialValues.id;
  const [subItem, setSubItem] = useState('');
  const [disabledPublish, setDisabledPublish] = useState(true);
  const isPublishSubmit = useRef(false);
  const [disabledNav, setDisabledNav] = React.useState(false);
  const exsistingKey = localStorage.getItem('tabKey');

  const formik = useFormik({
    initialValues: {...initialValues},
    onSubmit: (values, formikHelpers) => onSubmit(values, formikHelpers, isPublishSubmit.current),
    validationSchema: validationSchema,
    validate: (value) => {
      const errors = findPushCampaignErrors(value);
      const disabled = errors.length > 0;
      if (disabled !== disabledPublish) setDisabledPublish(disabled);
    },
    enableReinitialize: true,
  });

  // TODO Move this offerType update to the details section after its redesign.
  const cashBackType = formik.values?.cashBackType;
  useEffect(() => {
    if (cashBackType === 'timedOffer' || cashBackType === 'timeWindow') {
      formik.setFieldValue('offerType', 'Cash Disbursement');
    } else if (cashBackType === 'coupon') formik.setFieldValue('offerType', 'Generate Code');
  }, [cashBackType]);

  const defaultStartingKey = !isEdit ? 'campaign' : (exsistingKey || initTabKey);

  const publish = e => {
    isPublishSubmit.current = true;
    return formik.handleSubmit(e);
  };

  const submit = e => {
    isPublishSubmit.current = false;
    return formik.handleSubmit(e);
  };

  const isDifferent = compareFormValues(initialValues, formik.values);
  const isValid = Object.keys(formik.errors).length === 0;
  const disabledSubmit = !isValid || disabledNav;

  useEffect(() => {
    if (isEdit) return;
    if (formik.values?.type) setDisabledNav(false);
    else setDisabledNav(true);
  }, [formik.values?.type]);

  useEffect(() => {
    if (!formik.dirty) return;
    if (formik.values?.showStates && !formik.values?.stateSelection?.length) setDisabledNav(true);
    else if (isValid) setDisabledNav(false);
  }, [formik.values?.showStates, formik.values?.stateSelection]);
  const buttonText = `${campaignId ? 'Update' : 'Save'} ${formik.values.status || 'Draft'}`;

  return (
    <Navigation
      disabled={disabledNav || formik.isSubmitting}
      initTabKey={defaultStartingKey}
      additionalNav={
        <SubmitBox
          text={buttonText}
          onSubmit={submit}
          disabled={disabledSubmit || !isDifferent || formik.isSubmitting}
          hidden={false}
        />
      }
    >
      {({tabKey, setTabKey}) => (
        <form>
          <div style={{paddingRight: '200px', paddingLeft: '20px'}}>
            <div className={joinClasses('loader', formik.isSubmitting && '_active')}>
              <Spinner color="primary" style={{width: '50px', height: '50px'}}/>
            </div>
            <Warning isDifferent={isDifferent}/>
            <Row className="" style={{minWidth: '600px'}}>
              {
                (errorMessage || successMessage) && (
                  <Col sm={12}>
                    {errorMessage && <Alert className="alert" color='danger'>{errorMessage}</Alert>}
                    {successMessage && <Alert className="alert" color='success'>{successMessage}</Alert>}
                  </Col>
                )
              }
              <Col sm={12}>
                <TabContent
                  className="c-tabcontent"
                  id="my-tab-content"
                  activeTab={tabKey}
                >
                  <TabPane tabId="campaign">
                    <Step1 open={tabKey === 'campaign'} formik={formik} nextTab={() => setTabKey('overview')}/>
                  </TabPane>
                  <TabPane tabId="overview">
                    <Step2 open={tabKey === 'overview'} formik={formik}/>
                  </TabPane>
                  <TabPane tabId="offer">
                    <Step3 open={tabKey === 'offer'} formik={formik}/>
                  </TabPane>
                  <TabPane tabId="designer">
                    <Step4 open={tabKey === 'designer'} formik={formik}/>
                  </TabPane>
                  <TabPane tabId="trigger">
                    <Step5
                      campaignId={campaignId}
                      formik={formik}
                      subItem={subItem}
                      open={tabKey === 'trigger'}
                    />
                  </TabPane>
                  <TabPane tabId="review">
                    <Step6
                      setHorizontalTabs={setTabKey}
                      open={tabKey === 'review'}
                      formik={formik}
                      setSubItem={setSubItem} // for navigation to specific trigger tab
                      onSubmit={publish}
                      disabled={disabledSubmit || disabledPublish}
                    />
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </div>
        </form>
      )}
    </Navigation>
  );
};

export default Form;
