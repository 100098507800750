import React, {useContext} from 'react';
import Form from './Form';
import {initFormData} from './config';
import {ChosenLocation} from '../../contexts/RouterContext/ChosenLocation';
import CampaignService from '../../shared/services/CampaignService';
import {useMutation} from '@tanstack/react-query';
import {useHistory} from 'react-router-dom';
import {schemaCreateDraft} from './formValidation';
const {createCampaign, createAndPublishCampaign} = new CampaignService();

const CreateCampaign = () => {
  const [location, setChosenLocation] = useContext(ChosenLocation);
  const history = useHistory();
  const {client} = location;

  const {mutate: onSubmit} = useMutation(createCampaign, {
    onSuccess: (data) => {
      setChosenLocation({...location, client: data.data?.clientID, campaign: data.data?.id});
      setTimeout(() => {
        history.push('/admin/clients/campaigns/edit');
      }, 0);
    },
  });

  const {mutate: onPublishSubmit} = useMutation(createAndPublishCampaign, {
    onSuccess: (data) => {
      setChosenLocation({...location, client: data.data?.clientID, campaign: data.data?.id});
      setTimeout(() => {
        history.push('/admin/clients/campaigns/edit');
      }, 0);
    },
  });

  return (
    <Form
      initialValues={{...initFormData, clientID: client}}
      onSubmit={(v, fh, isPublishSubmit) => {
        if (isPublishSubmit) onPublishSubmit(v, fh);
        else onSubmit(v, fh);
      }}
      validationSchema={schemaCreateDraft}
    />
  );
};

export default CreateCampaign;
