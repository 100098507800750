import React, {useContext} from 'react';
import {Col, Card, CardBody, Row, CardFooter} from 'reactstrap';
import {Link} from 'react-router-dom';
import {format, parseISO} from 'date-fns';
import {ChosenLocation} from '../contexts/RouterContext/ChosenLocation.js';

export const OrganizationDisplay = (props) => {
  const [chosenLocation, setChosenLocation] = useContext(ChosenLocation);
  const {organization} = props;
  // insert call to fetch Clients by Organization ID --
  const clickHandler = () => {
    setChosenLocation({
      ...chosenLocation,
      organization: organization.id,
    });
  };

  return (
    <Col lg="4" md="6" sm="6" key={organization.id.toString()} className='d-flex justify-content-center align-items-center'>
      <Card className="card-stats c-display-card-height">
        <CardBody className='p-0 c-font-fullhd'>
          <div className={`${organization.isActive ? 'active-card-header':'inactive-card-header'} container-fluid mb-2`}></div>
          <Row className='mx-2 c-info-row-height'>
            <Col md="6" xs="5" className='bg-white h-100 w-100 d-flex justify-content-center align-items-start'>
              {/* <div className="icon-big text-center icon-warning h-100 w-100 d-flex justify-content-center align-items-center bg-white">
              <div className="image w-100 h-100 d-flex align-items-center justify-content-center bg-white"> */}
              {organization.logo !== '' ? <img src={organization.logo} alt={organization.name}
                style={{maxHeight: '100%'}} /> : <p>NO LOGO PROVIDED</p>}
              {/* </div>
            </div> */}
            </Col>
            <Col md="6" xs="7" className='d-flex flex-column justify-content-start align-items-start'>
              <p className='text-center inline font-weight-bold'>{organization.name}</p>
              <div className='c-light-gray rounded-pill d-flex justify-content-center container'>
                <p className='text-center font-italic m-auto c-variable-font'>
              Created: {format(new Date(parseISO(organization.dateCreated)), 'MM-dd-yyyy')}
                </p>

              </div>
            </Col>
          </Row>
        </CardBody>
        <CardFooter className='p-0 c-footer-height mt-5'>
          <hr className='m-0' />
          <div className='w-100 h-100 d-flex'>
            <div className='w-50 h-100 d-flex c-button-hover'>
              <Link onClick={clickHandler} to="./clients/" className='w-100 h-100 border-right d-flex justify-content-center align-items-center c-button-link'>
                  Clients
                <div className='my-auto d-flex ml-2 c-button-link' style={{width: '34px'}}>
                  <div className='rounded-pill c-badge'>
                    {organization.clientcount ?? 0}
                  </div>
                </div>
              </Link>
            </div>
            <div className='w-50 h-100 d-flex flex-column justify-content-center align-items-center'>
              {/* TODO: client was this to link to settings, i imagine its org specific settings by ID? */}
              <Link onClick={clickHandler} to={'./settings'} className='w-100 h-100 d-flex justify-content-center align-items-center c-button-link'>
                  Settings
                <div className='my-auto d-flex ml-2 c-button-link' style={{width: '34px'}}>
                </div>
              </Link>
            </div>
          </div>
          {/* <hr className='mb-2' />
        <Row className='h-100'>
          <Col lg="6" md="4" xs="5" className='d-flex justify-content-center align-items-center c-footer-hd-height c-font-fullhd mb-3'>
            <div className="badge-colors text-center">
              <Link to={"./clients/" + organization.id}>
                Clients
              </Link>
              <div className="pull-right">
                <Badge color="danger" pill>
                  {organization.clientcount}
                </Badge>
              </div>
            </div>
          </Col>
          <Col lg="6" md="4" xs="5" className='d-flex justify-content-center align-items-center c-footer-hd-height c-font-fullhd w-100 mb-3'>
            <div className="badge-colors text-center">
              <Link className="simple-text logo-mini logo-initials mr-0" to={"./organizationprofile/" + organization.id}>
                Settings
              </Link>
            </div>
          </Col>
        </Row> */}
        </CardFooter>
      </Card>
    </Col>
  );
};
