import React, {useState, useEffect, useContext} from 'react';
import {Link} from 'react-router-dom';
import FilterBar from './components/shared/FilterBar';
import {ChosenLocation} from '../contexts/RouterContext/ChosenLocation.js';
import {Row, Spinner, Col} from 'reactstrap';
import {ClientDisplay} from './ClientDisplay';
import OrganizationService from '../shared/services/OrganizationService';
import {useQuery} from '@tanstack/react-query';
import AuthService from '../shared/services/AuthService2';

const authService = new AuthService();

const Clients = () => {
  const [client, setClient] = useState([]);
  const [filteredClient, setFilteredClient] = useState([]);
  const [chosenLocation] = useContext(ChosenLocation);
  const organizationService = new OrganizationService();
  const org = chosenLocation?.organization || authService.getCurrentOrgList()[0] || undefined;

  const {
    error,
    data: clientData,
    isFetching,
  } = useQuery(
      ['clientsByOrg', org],
      () =>
        organizationService.getOrganizationClientsById(org),
      {
        enabled: !!org,
      },
  );

  useEffect(() => {
    // Update the org
    if (clientData?.data?.length > 0) {
      setClient(clientData.data);
      setFilteredClient(clientData.data);
    }
  }, [clientData]);

  const setQuickFilter = (e) => {
    const value = e.target.value;
    let searchValue;
    if (value === 'Active') {
      searchValue = 1;
    } else if (value === 'Inactive') {
      searchValue = 0;
    } else {
      return setFilteredClient(clientData.data);
    }
    const filteredData = clientData?.data?.filter((item) => {
      return item.isActive === searchValue;
    });
    setFilteredClient(filteredData);
  };

  if (isFetching) return <Spinner color="primary" className="page-loader" />;
  if (error) return <h1>{error?.message || error.code}</h1>;

  if (!org) {
    return (
      <Row>
        <Col
          className="bg-light border"
          md={{
            offset: 3,
            size: 6,
          }}
          sm="12"
        >
          <div role="alert">
            <p>Oops, There is no organization selected</p>
            <pre>Please click the button below to select an organization</pre>
            <Link className="btn btn-primary" to="../organizations">
              Let's Go!
            </Link>
          </div>
        </Col>
      </Row>
    );
  }

  return (
    <>
      <div className="c-centered-row p-0 pr-4">
        <FilterBar
          navigateUrl={'/admin/clients/create'}
          setQuickFilter={setQuickFilter}
          currentPage={'Client'}
          dropdownOption={['All', 'Active', 'Inactive']}
        />
      </div>

      <div className="m-auto p-0">
        <Row className="mt-0 pt-0">
          {filteredClient?.map((client, i) => {
            return <ClientDisplay key={i} client={client} />;
          })}
        </Row>
      </div>
    </>
  );
};

export default Clients;
