import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';

import AuthLayout from 'layouts/Auth';
import AdminLayout from 'layouts/Admin';

import 'bootstrap/dist/css/bootstrap.css';
import 'assets/scss/paper-dashboard.scss?v=1.3.0';
import 'assets/demo/demo.css';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import {QueryClientProvider, QueryClient} from '@tanstack/react-query';
import RenderWithRoles from './shared/components/RenderWithRoles';
import {ROLES} from './routes/config';


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
    <>
      {/* <ErrorBoundary FallbackComponent={ErrorFallback} onError={(error, errorInfo) => ErrorService.log({ error, errorInfo })} onReset={() => {
      // reset the state of your app so the error doesn't happen again

    }}> */}
      <QueryClientProvider client={queryClient}>
        <Router>
          <Switch>
            <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
            <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
            <RenderWithRoles
              validRoles={[ROLES.platform]}
              allowed={<Redirect to="/admin/organizations"/>}
              disabled={<Redirect to="/admin/clients" />}
            />
          </Switch>
        </Router>
      </QueryClientProvider>
      {/* </ErrorBoundary> */}

    </>,
    document.getElementById('root'),
);
