import React from 'react';
import {Link} from 'react-router-dom';
import {authService2} from 'views/Users/api';
import {useFormik} from 'formik';
import {useMutation} from '@tanstack/react-query';
import jwtDecode from 'jwt-decode';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  Form,
  Input,
  Container,
  Col,
  Row,
  Alert,
} from 'reactstrap';
import UserService from '../../shared/services/UserService';

const USER_ID_KEY = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier';
const userService = new UserService();

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = 'Required';
  } else if (values.email.length > 100) {
    errors.email = 'Must be 100 characters or less.';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address.';
  }

  if (!values.password) {
    errors.password = 'Required';
  } else if (values.password.length > 100) {
    errors.password = 'Must be 100 characters or less.';
  }
  return errors;
};

const Login = (props) => {
  const [loginError, setLoginError] = React.useState(false);
  const {mutate: getUser} = useMutation(({id}) => userService.getUser(id), {
    onSuccess: ({data: user}) => {
      if (user) authService2.saveUser(user);
      props.history.push('/');
    },
    onError: (error) => {
      alert('Failed to get user information');
      console.log('error', error);
      return error;
    },
  });
  const {mutate: handleLoginMutation} = useMutation(({email, password}) => {
    return authService2.login(email, password);
  },
  {
    onSuccess: async ({data}) => {
      if (data.token) {
        authService2.saveTokens(data?.token, data?.refreshToken);
        getUser({id: jwtDecode(data.token)?.[USER_ID_KEY] || null});
      }
      return data.data;
    },
    onError: (error) => {
      if (error.response.data) setLoginError(true);
      console.log('error in mutation', error);
    },
  },
  );

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validate,
    onSubmit: (values) => {
      if (values.email && values.password) {
        handleLoginMutation({email: formik.values.email, password: formik.values.password});
      }
    },
  });

  return (
    // TODO: maybe this can be refactored to reusable components when forgot password flow is in development
    <div className="login-page">
      <Container>
        <Row>
          <Col className="m-auto" lg="5" md="6">
            <Form onSubmit={formik.handleSubmit} action="" className="form" name="form" method="">
              <Card>
                <div className="login-card-body">
                  <CardHeader>
                    <h5 className="title text-center">Login</h5>
                  </CardHeader>
                  <Input
                    className={`has-label, login-height`}
                    id={'email'}
                    name={'email'}
                    placeholder="Enter Email"
                    onChange={(event) => {
                      formik.handleChange(event);
                      setLoginError(false);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    type="email"
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div style={{paddingLeft: '20px'}}>{formik.errors.email}</div>) : null}
                  <br/>

                  <Input
                    className={`has-label, login-height`}
                    id={'password'}
                    name={'password'}
                    placeholder="Password"
                    type="password"
                    autoComplete="off"
                    onChange={(event) => {
                      formik.handleChange(event);
                      setLoginError(false);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                  />
                  {
                    loginError ? (
                      <Alert color="warning">Invalid email or password.</Alert>
                    ) : null
                  }
                  <div className="text-left pl-2">
                    <h6 className="link">
                      <Link to="./forgotpassword">
                        Forgot Password?
                      </Link>
                    </h6>
                  </div>
                  <CardFooter className='d-flex pr-0 justify-content-end'>
                    <Button
                      className="btn-round login-submit-button"
                      type="submit"
                    >
                      Let's do this!
                    </Button>
                    <br/>
                  </CardFooter>
                </div>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
      <div
        className="full-page-background"
      />
    </div>
  );
};

export default Login;
