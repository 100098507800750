import {axiosInstance} from '../../utils';

const API_URL = '/Campaign';
class CampaignService {
  getCampaignReport = (id) => {
    // Campaign/CampaignReportTemp?campaignId=4241ac75-be1e-4819-b574-11ce470303ec
    return axiosInstance.get(`${API_URL}/CampaignReport?campaignId=${id}`);
  };
  getCampaigns = () => {
    return axiosInstance.get(`${API_URL}/campaigns-full/`);
  };

  getRedemptionZone = (id) => {
    return axiosInstance.get(`${API_URL}/${id}/redemption-zone`);
  };
  getUserListingForCampaign = (campaignId) => {
    return axiosInstance.get(`${API_URL}/${campaignId}/user-listing-for-campaign/`);
  };

  getCampaignsByClientId = (id) => {
    return axiosInstance.get(`${API_URL}/${id}/campaigns`);
  };

  createCampaign = (data) => {
    return axiosInstance
        .post(`${API_URL}/create-campaign/`, data)
        .then((response) => {
          return response;
        });
  };

  createAndPublishCampaign = (data) => {
    return axiosInstance
        .post(`${API_URL}/create-campaign-and-publish/`, data)
        .then((response) => {
          return response;
        });
  };

  publishCampaign = (data) => {
    const id = data.id;
    return axiosInstance.patch(`${API_URL}/publish-campaign/` + `${id}`, data);
  };

  updateCampaign = (data) => {
    const id = data.id;
    return axiosInstance.patch(`${API_URL}/update-campaign/` + `${id}`, data);
  };

  getCampaignByID = async (data) => {
    const id = data?.id ?? localStorage.getItem('newCampaignID');
    return axiosInstance.get(
        `${API_URL}/${data?.clientId}/campaigns/${id}`,
    );
  };

  getCampaignByIDFull = async (data) => {
    const id = data?.id ?? localStorage.getItem('newCampaignID');
    return axiosInstance.get(
        `${API_URL}/${data?.clientId}/campaignsfull/${id}`,
    );
  };
  getCampaignUsers = async (data) => {
    // https://localhost:7104/api/Campaign/a71e2bee-5ded-45b9-85b1-0091f313a949/user-listing-for-campaign-with-extra?statesearch=HI&receivedcash=Y&leadgensource=Email%7CText
    // `${API_URL}/${data?.campaignId}/user-listing-for-campaign?statesearch=${data?.state ?? ""}&receivedcash=${data?.receiveCashOption ?? ""}&leadgensource=${data?.leadGenSource ?? ""}&actionscompleted=${data?.actionCompleted ?? ""}`,
    return axiosInstance.get(
        `${API_URL}/${data?.campaignId}/user-listing-for-campaign`,
        {
          params: {
            receivedcash: data?.receiveCashOption ?? '',
            statesearch: data?.state ?? '',
            leadgensource: data?.leadGenSource ?? '',
            actionscompleted: data?.actionCompleted ?? '',

          },
        },
    );
  };
  getCampaignParticipants = async (campaignId) => {
    return axiosInstance.get(
        `${API_URL}/${campaignId}/user-listing-for-campaign`,
    );
  };

  /**
   *
   * @param {*} data
   * @returns
   */

  createLinkTrigger = (data, identifier) => {
    return axiosInstance.post(
        `${API_URL}/${data.campaignID}/create-link-trigger/`,
        data,
    );
  };

  getLinkTrigger = (data) => {
    return axiosInstance.get(
        `${API_URL}/${data.campaignId}/all-link-trigger/`,
    );
  };

  deleteLinkTrigger = (data) => {
    return axiosInstance.post(
        `${API_URL}/${data.campaignID}/delete-link-trigger/`,
        data,
    );
  };

  updateLinkTrigger = (data) => {
    return axiosInstance.post(
        `${API_URL}/${data.campaignID}/update-link-trigger/`,
        data,
    );
  };

  /**
   * triggers section
   */
  createEmailTrigger = (data) => {
    console.log(`data`, data);
    return axiosInstance.post(
        `${API_URL}/${data.campaignID}/create-email-trigger/`,
        data,
    );
  };

  getEmailTriggers = (data) => {
    return axiosInstance.get(
        `${API_URL}/${data.campaignId}/email-trigger/`,
    );
  };

  deleteEmailTrigger = (data) => {
    return axiosInstance.post(
        `${API_URL}/${data.campaignID}/delete-email-trigger/`,
        data,
    );
  };

  updateEmailTrigger = (data) => {
    return axiosInstance.post(
        `${API_URL}/${data.campaignId}/update-email-trigger/`,
        data,
    );
  };

  /**
   * triggers section
   */
  createTextTrigger = (data) => {
    console.log(`data`, data);
    return axiosInstance.post(
        `${API_URL}/${data.campaignID}/create-text-trigger/`,
        data,
    );
  };

  getTextTrigger = (data) => {
    return axiosInstance.get(`${API_URL}/${data.campaignId}/text-trigger/`);
  };

  deleteTextTrigger = (data) => {
    return axiosInstance.post(
        `${API_URL}/${data.campaignID}/delete-text-trigger/`,
        data,
    );
  };

  updateTextTrigger = (data, identifier) => {
    return axiosInstance.post(
        `${API_URL}/${data.campaignID}/update-text-trigger/`,
        data,
    );
  };

  getQrTrigger = (data) => {
    return axiosInstance.get(`${API_URL}/${data.campaignId}/qr-trigger/`);
  };

  createQrTrigger = (data, identifier) => {
    return axiosInstance
        .post(`${API_URL}/create-qr-trigger/`, data)
        .then((response) => {
          identifier = response.data.id;
          return response;
        });
  };
  updateQrTrigger = (data, identifier) => {
    return axiosInstance
        .post(`${API_URL}/update-qr-trigger/`, data)
        .then((response) => {
          identifier = response.data.id;
          return response;
        });
  };

  deleteQrTrigger = (data, identifier) => {
    return axiosInstance
        .post(`${API_URL}/delete-qr-trigger/`, data)
        .then((response) => {
          identifier = response.data.id;
          return response;
        });
  };

  updateCampaignSite = (data, identifier) => {
    return axiosInstance.patch(`${API_URL}/${data.id}/site/`, data);
  };
}
export default CampaignService;
