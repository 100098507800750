import {BARS_COUNT, INCOMPLETE_FLAG, SPENDING_TIME_KEY} from './const';

export const sortByMaxPeriod = (arr = []) => arr.sort((a, b) => {
  if (a[SPENDING_TIME_KEY] < b[SPENDING_TIME_KEY]) return -1;
  if (a[SPENDING_TIME_KEY] > b[SPENDING_TIME_KEY]) return 1;
  return 0;
});

export const getCompleted = (arr = []) => arr.filter((item) => !(item.timeToComplete === INCOMPLETE_FLAG));

export const formatData = (arr = []) => arr.map((item) => {
  const landingTimeSec = Math.floor(new Date(item.landingTime).getTime() / 1000);
  const completedTimeSec = Math.floor(new Date(item.completedTime).getTime() / 1000);
  return {
    ...item,
    landingTimeSec,
    completedTimeSec,
    spendingTimeSec: completedTimeSec - landingTimeSec,
    [SPENDING_TIME_KEY]: Math.floor((completedTimeSec - landingTimeSec) / 60),
  };
});

export const createChartData = (userArrSorted= [], frameSizeMinutes = 1, startPoint = 0) => {
  if (frameSizeMinutes < 1) frameSizeMinutes = 1;
  // Returns a tuple of elements length [BARS_COUNT]
  const chartListTmp = Array(BARS_COUNT +1).fill({usersCount: 0, period: 0});
  const a = chartListTmp.map((item, i1) => {
    const prevPeriod = (i1 - 1) * frameSizeMinutes + startPoint;
    const period = i1 * frameSizeMinutes + startPoint;
    let usersCount = 0;

    for (let i = 0; i < userArrSorted.length; i++) {
      const item = userArrSorted[i];
      if (item[SPENDING_TIME_KEY] <= (period) && item[SPENDING_TIME_KEY] > prevPeriod) usersCount += 1;
    }
    return ({x: Math.floor(period), y: usersCount});
  });
  return a;
  return [
    {x: 19, y: 1},
    {x: 20, y: 2},
    {x: 30, y: 3},
    {x: 40, y: 4},
    {x: 50, y: 5},
    {x: 60, y: 6},
    {x: 70, y: 7},
    {x: 80, y: 8},
    {x: 90, y: 9},
    {x: 100, y: 10},
    {x: 110, y: 11},
    {x: 120, y: 10},
    {x: 130, y: 9},
    {x: 140, y: 8},
    {x: 150, y: 7},
    {x: 160, y: 6},
    {x: 170, y: 5},
    {x: 180, y: 4},
    {x: 190, y: 3},
    {x: 200, y: 2},
    {x: 210, y: 2},
    {x: 220, y: 1},
    {x: 230, y: 1},
    {x: 240, y: 1},
    {x: 250, y: 1},
  ];
};

