import React from 'react';
import {Form} from './index';
import {ColorInput} from './fields';

const ButtonForm = ({formik, onSubmit}) => {
  return (
    <div>
      <Form formik={formik} onSubmit={onSubmit}>
        <ColorInput label="Primary Button Background" formik={formik} name="primaryButtonBackground" />
        <ColorInput label="Primary Button text" formik={formik} name="primaryButtonText" />
      </Form>
    </div>
  );
};

export default ButtonForm;
