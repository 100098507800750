import React from 'react';
import {useMutation} from '@tanstack/react-query';
import {Formik, Form} from 'formik';
import {Button, Row, Col} from 'reactstrap';
import OrganizationService from 'shared/services/OrganizationService';


const SettingsForm = ({orgId, initialValues}) => {
  const organizationService = new OrganizationService();
  const mutation = useMutation(organizationService.savePaypalCredentials, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(['ActiveClient']);
    },
  });

  const updateMutation = useMutation(organizationService.updatePaypalCredentials, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(['ActiveClient']);
    },
  });

  const handleSubmit = (values) => {
    values.organizationId = orgId;
    if (initialValues.paypalClientId || initialValues.paypalClientSecret) {
      updateMutation.mutate(values);
      return;
    } else {
      mutation.mutate(values);
    }
  };

  return (
    <div>
      {
        initialValues && (
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={(values) => {
              console.log('on submit values', values);
              handleSubmit(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row className="flex-column">
                  <Col>
                    <label htmlFor="paypalClientId">Client Key</label>
                  </Col>
                  <Col>
                    <input id="paypalClientId" value={values.paypalClientId} type='text' onChange={handleChange} />
                  </Col>
                </Row>
                <Row className="flex-column">
                  <Col>
                    <label htmlFor="paypalClientSecret">Secret</label>
                  </Col>
                  <Col>
                    <input
                      id="paypalClientSecret"
                      onChange={handleChange}
                      value={values.paypalClientSecret}
                      type="string"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-end">
                    <Button color="primary" type="submit">
              Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        )
      }
    </div>
  );
};

export default SettingsForm;
