import React from 'react';
import print from '../../../assets/img/selector-print-receipt.png';
import mobile from '../../../assets/img/selector-mobile-receipt.png';
import bothReceipt from '../../../assets/img/selector-receipt-both.png';
import CustomRadioInput from '../../../shared/components/fields/CustomRadioInput';
import {ReceiptDetails} from './ReceiptDetails';
import {joinClasses} from '../../../utils';

const fieldName = 'proofOfPurchase';

export const ProofOfPurchase = ({formik}) => {
  const value = formik.values[fieldName];
  const isSelected = !!value || value === 0;

  return (
    <div className="proofOfPurchase">
      <span className="proofOfPurchaseTitle">Required Formats for Proof of Purchase</span>
      <div className={joinClasses('proofOfPurchaseBox', isSelected && 'isSelected')}>
        <div className="proofOfPurchaseLeft">
          <CustomRadioInput
            formik={formik}
            name={fieldName}
            text="Photo of paper receipt"
            icon={<img src={print} alt="print"/>}
            value="0"
          />
          <CustomRadioInput
            formik={formik}
            name={fieldName}
            text="Digital Receipt (email from retailer)"
            icon={<img src={mobile} alt="mobile"/>}
            value="1"
          />
          <CustomRadioInput
            formik={formik}
            name={fieldName}
            text="Both"
            icon={<img src={bothReceipt} alt="bothReceipt"/>}
            value="2"
          />
        </div>
        <div className="proofOfPurchaseRight">
          {isSelected && <ReceiptDetails formik={formik}/>}
        </div>
      </div>
    </div>
  );
};
