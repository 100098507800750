import React from 'react';
import {joinClasses} from '../../../utils';
import {Collapse} from 'reactstrap';

const isCheckedFn = (fieldValue, selectedValue) => {
  if (selectedValue === null || selectedValue === undefined || selectedValue === '') return false;
  return String(selectedValue) === String(fieldValue);
};

const CustomRadioInput = ({formik, name, text, icon, value, children}) => {
  const isChecked = isCheckedFn(value, formik.values[name]);
  const onChange = () => formik.setFieldValue(name, value);
  const ID = `${name}__${value}`;

  return (
    <div className="customRadioInput" style={{width: '100%', position: 'relative'}}>
      <div className={joinClasses('customRadioInputBox', isChecked && 'isChecked')}>
        <label className="customRadioInputLabel" htmlFor={ID}>
          <input
            className="customRadioInputInput"
            onChange={onChange}
            type="radio"
            name={name}
            value={value}
            id={ID}
            checked={isChecked}
          />
          <span className="customRadioInputCheckmark"></span>
          <span className="buttonInputText">{text || 'undefined'}</span>
          <div className="buttonInputIcon">{icon || ''}</div>
        </label>
        {children && (
          <Collapse isOpen={isChecked}>
            <div
              className={joinClasses('customRadioInputChildrenBox')}
            >{children}
            </div>
          </Collapse>
        )}
      </div>
    </div>
  );
};

export default CustomRadioInput;
