import React from 'react';
import SelectInput from '../../../shared/components/fields/SelectInput';
import WinnersTable from './WinnersTable';
import {winnerPeriods} from './config';
import {TextInput} from '../../../shared/components/fields';
import CheckboxInput from '../../../shared/components/fields/CheckboxInput';

const Winners = ({formik}) => {
  return (
    <div className="offerWinners">
      <header>
        <h6>When should winners be selected?</h6>
        <div className="sinnerPeriod">
          <SelectInput
            name="winnerPeriodType"
            formik={formik}
            options={winnerPeriods}
          />
        </div>
      </header>
      <br/>
      <WinnersTable name="winnerTimes" formik={formik}/>
      <div className="offerWinnersSwitch">
        <CheckboxInput labelEnd label="Specific prize to be determinate later" name="winnerSpecialPrize" formik={formik}/>
      </div>
      {
        formik.values.winnerSpecialPrize && (
          <div>
            <div>Prize notes:</div>
            <TextInput
              type="textarea"
              placeholder=""
              name="winnerPrizeNote"
              formik={formik}
              classes="m0 p10"
            />
          </div>
        )
      }
    </div>
  );
};

export default Winners;
