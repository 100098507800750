import {STORAGE_KEYS} from './config';
import {axiosInstance} from '../../utils';

const API_URL = '/ClientProfiles/';
class AuthService {
  login(email, password) {
    return axiosInstance.post(API_URL + 'login', {
      email: email,
      password: password,
    });
  }
  logout() {
    const email = this.getCurrentUser()?.email;
    localStorage.clear();
    return axiosInstance.delete(API_URL+`logout?email=${email}`);
  }
  saveUser(user) {
    localStorage.setItem(STORAGE_KEYS.user, JSON.stringify(user));
  }
  saveTokens(token, refreshToken) {
    localStorage.setItem(STORAGE_KEYS.token, token);
    localStorage.setItem(STORAGE_KEYS.refreshToken, refreshToken);
  }
  getCurrentUser() {
    return JSON.parse(localStorage.getItem(STORAGE_KEYS.user));
  }
  isLoggedIn() {
    return localStorage.getItem(STORAGE_KEYS.token) && JSON.parse(localStorage.getItem(STORAGE_KEYS.user));
  }
  userHasRole(role) {
    const roles = JSON.parse(localStorage.getItem(STORAGE_KEYS.user))?.roles || [];
    return roles?.includes(role);
  }
  resetPassword(email) {
    localStorage.clear();
    return axiosInstance.patch(API_URL + 'Reset-Password-Email' + '?email=' + email, {
      email,
    });
  }
  updatePassword(data) {
    return axiosInstance.patch(`${API_URL}Update-Password`, {
      email: data.email,
      password: data.password,
      passwordConfirm: data.passwordconfirm,
      linkId: data.linkid,
    });
  }
  authenticateUpdate(data) {
    return axiosInstance.post(`${API_URL}Authenticate-Update`, {
      email: data.email,
      linkId: data.linkid,
    });
  }
}
export default new AuthService();
