import React from 'react';
import {Input} from 'reactstrap';
import {joinClasses} from '../../../utils';

const TextInput = ({formik, name, label, placeholder, classes, ...restProps}) => {
  if (!formik || !name) return null;
  const error = formik.touched?.[name] && formik.errors?.[name];

  return (
    <div className={joinClasses('textInput', classes)}>
      {label && <label className='font-weight-bold' htmlFor={name}>{label}</label> }
      <Input
        {...formik.getFieldProps(name)}
        {...restProps}
        invalid={!!error}
        tooltip={!!error}
        placeholder={placeholder || undefined}
      />
      { error && (
        <div style={{color: 'red', fontSize: '12px'}}>{error}</div>
      )}
    </div>
  );
};

export default TextInput;
