import React, {useState} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import a6new from '../../assets/img/a6new.png';
import {authService2} from 'views/Users/api';

function AdminNavbar(props) {
  const location = useLocation();
  const [user] = useState(authService2.getCurrentUser());
  React.useEffect(() => {
    if (
      window.outerWidth < 993 &&
      document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open');
    }
  }, [location]);
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)


  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-grey fixed-top">
        <div className="container container-lg">
          <a className="navbar-brand" href="#">
            <img src={a6new} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="user-icon"></i>{ (`${user?.firstName} ${user?.lastName}` )}
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a className="dropdown-item" href="#">
                    My Profile
                  </a>
                  <NavLink
                    className="dropdown-item"
                    to={'/auth/logout'}
                  >
                    Log Out
                  </NavLink>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default AdminNavbar;
