import React from 'react';
import {CardImage} from 'react-bootstrap-icons';
import {joinClasses} from '../../../../../utils';

const GroupItem = ({title, isActive, isFilledOut, onClick, disabled}) => {
  const classes = joinClasses('groupItem', isActive && 'active', isFilledOut && 'isFilledOut', disabled && '_disabled' );
  return (
    <div onClick={disabled ? undefined : onClick} className={classes}>
      <CardImage size={24} color={isActive ? '#ec6612' : '#99cded'}/>
      <span className="groupItemText">{title}</span>
    </div>
  );
};

export default GroupItem;
