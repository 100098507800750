import React, {useEffect, useState} from 'react';
import paypal from '../../../assets/img/paypal_logo.jpeg';
import venmo from '../../../assets/img/Venmo-Logo.png';

export const CashDisbursement = ({formik}) => {
  const [isPayPalActive, setIsPayPalActive] = useState(false);
  const [isVenmoActive, setIsVenmoActive] = useState(false);
  const [isCheckActive] = useState(false);

  useEffect(() => {
    setIsPayPalActive(formik?.values?.cashDisbursementMethod?.includes('PayPal') ?? false);
    setIsVenmoActive(formik?.values?.cashDisbursementMethod?.includes('Venmo') ?? false);
  }, []);

  useEffect(() => {
    const CDM = [];
    if (isPayPalActive) CDM.push('PayPal');
    if (isVenmoActive) CDM.push('Venmo');
    // if (isCheckActive) CDM.push("Check");
    formik.setFieldValue('cashDisbursementMethod', CDM.join('|'));
  }, [isPayPalActive, isVenmoActive, isCheckActive]);

  return (
    <>
      <label>Cash Disbursement Methods</label>
      <div className="form-group form-inline">
        <div className="row w-100">
          <div className="col-sm-4 p-0">
            <a
              className={`btn-method ${isPayPalActive ? 'active' : ''}`}
              id="PayPal"
              name="cashDisbursementMethod"
              onClick={() => setIsPayPalActive(!isPayPalActive)}
              onChange={formik.handleChange}
            >
              <img src={paypal}/>
              <span className="d-none">Paypal</span>
            </a>
          </div>
          <div className="col-sm-4 p-0">
            <a
              className={`btn-method ${isVenmoActive ? 'active' : ''}`}
              id="Venmo"
              name="cashDisbursementMethod"
              onClick={() => setIsVenmoActive(!isVenmoActive)}
              onChange={formik.handleChange}
            >
              <img src={venmo}/>
              <span className="d-none">Venmo</span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
