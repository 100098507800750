import {getTotalTime, comparatorString} from '../utils';
import {CashIconRenderer} from './renderers';

export const columns = [
  {
    field: 'firstName',
    headerName: 'First Name',
    width: 160,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    comparator: comparatorString,
  },
  {
    headerName: 'Last Name',
    field: 'lastName',
    width: 160,
    comparator: comparatorString,
  },
  {
    headerName: 'Mobile',
    field: 'phoneNumber',
    width: 160,
  },
  {
    headerName: 'Email',
    field: 'email',
    width: 200,
  },
  {
    headerName: 'Source',
    field: 'source',
    width: 120,
  },
  {
    headerName: 'Segments',
    field: 'segment',
    width: 140,
    comparator: comparatorString,
  },
  {headerName: 'State', field: 'state', width: 80},
  {
    headerName: 'Stages Completed',
    field: 'sectionCount',
    width: 170,
  },
  {
    headerName: 'Time To Complete',
    field: 'timeToComplete',
    valueGetter: getTotalTime,
    comparator: (valueA, valueB) => {
      if (valueA === valueB) return 0;
      return valueA > valueB ? 1 : -1;
    },
    maxWidth: 160,
  },
  {
    headerName: 'Cash?',
    field: 'cash',
    maxWidth: 100,
    cellRenderer: CashIconRenderer,
  },
];

export const columnsETW = [
  {
    field: 'firstName',
    headerName: 'First Name',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    comparator: comparatorString,
  },
  {
    headerName: 'Last Name',
    field: 'lastName',
    comparator: comparatorString,
  },
  {
    headerName: 'Mobile',
    field: 'phoneNumber',
  },
  {
    headerName: 'Email',
    field: 'email',
  },
  {
    headerName: 'Source',
    field: 'source',
  },
  {
    headerName: 'First Submission',
    field: 'isSubmitted1',
  },
  {
    headerName: 'Second Submission',
    field: 'isSubmitted2',
  },
];
