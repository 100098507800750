import CampaignDetails from '../../views/CampaignDetails';
import Campaigns from '../../views/Campaigns';
import {CreateCampaign, UpdateCampaign} from '../../views/Campaign';
import {ROLES} from '../config';

const routes = [
  {
    path: '/clients/campaigns',
    name: 'Campaigns',
    component: Campaigns,
    layout: '/admin',
    exact: true,
    validRoles: [ROLES.org],
  },
  {
    path: '/clients/campaigns/details',
    name: 'Campaign Details',
    component: CampaignDetails,
    layout: '/admin',
    exact: true,
    validRoles: [ROLES.org],
  },
  {
    path: '/clients/campaigns/create',
    name: 'Create Campaigns',
    exact: true,
    component: CreateCampaign,
    layout: '/admin',
    validRoles: [ROLES.org],
  },
  {
    path: '/clients/campaigns/edit',
    name: 'Edit Campaigns',
    exact: true,
    component: UpdateCampaign,
    layout: '/admin',
    validRoles: [ROLES.org],
  },
];

export default routes;
