import React, {useEffect, useRef, useState} from 'react';
import './Trigger.css';
import QRImage from '../../../assets/img/qr-code.png';
import GMAPImage from '../../../assets/img/gmap-placeholder.png';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCopy} from '@fortawesome/free-regular-svg-icons';
import {faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.css';
import {
  Button,
  Label,
  CardBody,
  FormGroup,
  Input,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  TabContent,
  TabPane,
  NavLink,
} from 'reactstrap';
import Email from './triggers/emails';
import Texts from './triggers/texts';
import {Accordion} from 'react-bootstrap';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader';
import {useMutation} from '@tanstack/react-query';
import CampaignService from 'shared/services/CampaignService';
import Link from './triggers/links';

const Trigger = (props) => {
  const {formik, subItem} = props;
  const [saveError] = useState(null);
  const [displayQRInfo, setDisplayQRInfo] = useState('oneQR');
  const [activeTab, setActiveTab] = useState('links');
  const [enterPage, setEnterPage] = useState(true);
  const hiddenFileInput = useRef();

  useEffect(() => {
    if (enterPage) {
      setEnterPage(false);
    }
    // if subItem is not null, then we want to setActiveTab to the subItem
    if (subItem) {
      setActiveTab(subItem);
    }
  }, [subItem]);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    props.handleFile(fileUploaded);
  };

  const currentUrlReader = () => {
    const currentUrl = window.location.href;
    let finalUrl = '';
    if (currentUrl.includes('uat')) {
      finalUrl = 'https://alpha6-pe-uat.devops-e2gens.com/register';
    } else if (currentUrl.includes('staging')) {
      finalUrl = 'https://alpha6-pe-staging.devops-e2gens.com/register';
    } else {
      finalUrl = 'http://localhost:3000/register';
    }
    return finalUrl;
  };

  const campaignService = new CampaignService();

  const {mutate: generateLink, isLoading: isLoadingLink} = useMutation(
      campaignService.createLinkTrigger,
      {
        onSuccess: (links) => {
          const currLinks = formik.values['links'] ?? [];
          currLinks.push({...links.data, id: Math.random()});
          formik.setFieldValue('links', currLinks);
        },
      },
  );

  const {mutate: createEmailTrigger, isLoading: isLoadingEmailLink} =
    useMutation(campaignService.createEmailTrigger, {
      onSuccess: (links) => {
        const currLinks = formik.values['emails'] ?? [];
        currLinks.push({...links.data});
        formik.setFieldValue('emails', currLinks);
      },
    });

  const {mutate: deleteEmail} = useMutation(
      campaignService.deleteEmailTrigger,
      {
        onSuccess: (data) => {
          const emails = formik.values['emails'];
          const index = emails.findIndex(
              (email) => email.emailID === data.data.emailID,
          );
          emails.splice(index, 1);
          formik.handleChange({
            target: {
              name: 'emails',
              value: emails,
            },
          });
        },
        onError: (error) => {
          console.log('error', error);
        },
      },
  );

  const {mutate: createTextTrigger, isLoading: isLoadingTextLink} =
    useMutation(campaignService.createTextTrigger, {
      onSuccess: (links) => {
        const currLinks = formik.values['texts'] ?? [];
        currLinks.push({...links.data});
        formik.setFieldValue('texts', currLinks);
      },
    });

  const {mutate: deleteText, isLoading: isLoadingRemoveText} = useMutation(
      campaignService.deleteTextTrigger,
      {
        onSuccess: (data) => {
          const texts = formik.values['texts'];
          const index = texts.findIndex(
              (email) => email?.textID === data.data?.textID,
          );
          texts.splice(index, 1);
          formik.handleChange({
            target: {
              name: 'texts',
              value: texts,
            },
          });
        },
        onError: (error) => {
          console.log('error', error);
        },
      },
  );


  if (saveError) throw saveError;

  return (
    <div>
      <FormGroup className="form-row animated">
        <Label for="domainName">Primary Link</Label>
        <Input
          type="text"
          id="domainName"
          name="domainName"
          placeholder="your entry link..."
          defaultValue={formik.values.domainName}
          onChange={formik.handleChange}
        />
      </FormGroup>
      <Row>
        {['links', 'qr'].map((trigger) => {
          return (
            <Col key={trigger} style={{cursor: 'pointer'}}>
              <NavLink
                className={`d-flex align-items-center justify-content-flex-start p-0 pb-1
                  ${
                  activeTab === trigger ?
                    'active align-left c-nav-highlight' :
                    'text-align-center'
            }`}
                onClick={() => setActiveTab(trigger)}
              >
                {trigger[0].toUpperCase() + trigger.slice(1)}
              </NavLink>
            </Col>
          );
        })}
      </Row>
      <Row>
        <Col>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="links" role="tabpanel">
              <Row >
                <Col className={'text-center'}>
                  <Button
                    disabled={!formik.values['domainName']}
                    className={`gen-links`}
                    onClick={() => {
                      // TODO: generate link with create link mutation
                      generateLink(
                          {
                            id: 'testId',
                            campaignID: props.campaignId,
                            linkAddress: formik.values['domainName'],
                          },
                          'test',
                      );
                    }}
                  >
                Generate Link
                    {isLoadingLink && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </Button>
                </Col>
              </Row>
              <hr/>
              <Row>
                <Col>
                  <div className="links">
                    {(formik.values['links'] ?? []).map((item, index) => {
                      return (
                        <Link
                          campaignId={props.campaignId}
                          key={index}
                          index={index}
                          item={item}
                          formik={formik}
                        />
                      );
                    })}
                  </div>
                </Col>
              </Row>


            </TabPane>
            <TabPane tabId="qr" role="tabpanel">
              <CardBody>
                <div className="container">
                  <Row>
                    <Col className='text-center'>
                      <Input
                        id=""
                        type="radio"
                        checked={displayQRInfo === 'oneQR'}
                        value="oneQR"
                        onChange={(e) => {
                          setDisplayQRInfo(e.target.value);
                        }}
                      />
                      <Label>Generate one QR code</Label>
                      <hr/>
                      {displayQRInfo === 'oneQR' && (
                        <>
                          <div className="container">
                            <Row>
                              <Col lg="12" m="6" s="6">
                                <img
                                  src={QRImage}
                                  alt="qr code image"
                                  className="image-qr"
                                />
                              </Col>

                              <Col m="8">
                                <Button
                                  type="file"
                                  name="file"
                                  id="file"
                                  className="btn-download-qr"
                                  onChange={handleChange}
                                >
                                  Download QR Code
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </>
                      )}
                    </Col>

                    {/* <Col>
                      <Input
                        type="radio"
                        checked={displayQRInfo === 'multiQR'}
                        value="multiQR"
                        onChange={(e) => {
                          setDisplayQRInfo(e.target.value);
                        }}
                        onBlur={formik.handleBlur}
                      />
                      <Label>
                        Generate a unique QR code for multiple locations
                      </Label>
                      <hr/>

                      {displayQRInfo === 'multiQR' && (
                        <>
                          <div className="container">
                            <Row>
                              <Col m="6">
                                <div>
                                  <span className="input-group-text">
                                    <FontAwesomeIcon
                                      icon={faMagnifyingGlass}
                                    />
                                  </span>
                                </div>

                                <ListGroup className="gmap-locations-list">
                                  <ListGroupItem>Location 1</ListGroupItem>
                                  <ListGroupItem>Location 2</ListGroupItem>
                                  <ListGroupItem>Location 3</ListGroupItem>
                                  <ListGroupItem>Location 4</ListGroupItem>
                                  <ListGroupItem>Location 5</ListGroupItem>
                                  <ListGroupItem>Location 6</ListGroupItem>
                                  <ListGroupItem>Location 7</ListGroupItem>
                                  <ListGroupItem>Location 8</ListGroupItem>
                                  <ListGroupItem>Location 9</ListGroupItem>
                                  <ListGroupItem>Location 10</ListGroupItem>
                                  <ListGroupItem>Location 11</ListGroupItem>
                                  <ListGroupItem>Location 12</ListGroupItem>
                                </ListGroup>
                              </Col>

                              <Col lg="8" md="6">
                                <img
                                  src={GMAPImage}
                                  alt="gmap placeholder"
                                  className="image-multi-qr"
                                />
                              </Col>

                              <br/>
                            </Row>
                            <Row>
                              <Col lg="12" m="8">
                                <br/>
                                <p style={{textAlign: 'center'}}>
                                  <strong>OR</strong>
                                </p>

                                <p style={{textAlign: 'left'}}>
                                  Search for and select the locations to which
                                  you want to assign QR codes. A unique QR
                                  code will be used by customers at each
                                  location to activate the offer, enabling us
                                  to record customer activity at each site.
                                </p>

                                <div>
                                  <textarea
                                    id="textSearch"
                                    className="offer-search"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                  ></textarea>
                                </div>

                                <div>
                                  <Button
                                    id="fileUpload"
                                    onClick={handleClick}
                                  >
                                    Drag and Drop or Upload a File
                                  </Button>
                                  <input
                                    type="file"
                                    ref={hiddenFileInput}
                                    onChange={handleChange}
                                    style={{display: 'none'}}
                                  />
                                </div>

                                <div>
                                  <Button className="btn-download-qr">
                                    Generate QR Codes
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </>
                      )}
                    </Col> */}
                  </Row>
                </div>
              </CardBody>
            </TabPane>
            <TabPane tabId="emails" role="tabpanel">
              {isLoadingEmailLink && <div> loading... </div>}
              <Button
                disabled={!formik.values['domainName']}
                className="gen-links"
                id="emailbutton"
                onClick={() => {
                  createEmailTrigger({
                    campaignID: props.campaignId,
                  });
                }}
              >
                generate Email Trigger
                {isLoadingEmailLink && (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </Button>
              <CardBody>
                {/* TODO: need to add add/remove email items */}
                {(formik.values['emails'] ?? []).map((item, index) => {
                  return (
                    <Accordion>
                      <AccordionHeader>
                        <Row>
                          <Col sm={4} style={{textAlign: 'left'}}>
                            Email trigger{' '}
                            {item.emailName && `( ${item.emailName} )`}
                          </Col>
                          <Col sm={4}>
                            <Input
                              type="text"
                              name="emailAddress"
                              disabled
                              value={`${currentUrlReader()}/${item.emailID}`}
                            />
                          </Col>
                          <Col sm={4}>
                            <Button
                              className="btn btn-danger button"
                              style={{'margin-top': '0px'}}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                deleteEmail({
                                  ...item,
                                  campaignID: props.campaignId,
                                });
                              }}
                            >
                              {isLoadingEmailLink && (
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              )}
                              {!isLoadingEmailLink && 'X'}
                            </Button>
                          </Col>
                        </Row>
                      </AccordionHeader>
                      <AccordionBody>
                        <Email
                          campaignId={props.campaignId}
                          formik={formik}
                          index={index}
                        />
                      </AccordionBody>
                    </Accordion>
                  );
                })}
              </CardBody>
            </TabPane>
            <TabPane tabId="texts" role="tabpanel">
              {activeTab === 'texts' && (
                <>
                  <Button
                    disabled={!formik.values['domainName']}
                    className="gen-links"
                    onClick={(e) => {
                      e.stopPropagation();

                      createTextTrigger({
                        campaignID: props.campaignId,
                        name: `Text Trigger ${
                          (formik.values['texts'] ?? []).length + 1
                        }`,
                      });
                    }}
                  >
                    generate Text Trigger{'  '}
                    {isLoadingTextLink && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </Button>
                  <CardBody>
                    {formik?.values['texts'] &&
                      (formik?.values['texts']).map((item, index) => {
                        return (
                          <Accordion>
                            <AccordionHeader
                              style={{'border-color': 'black'}}
                            >
                              <Row>
                                <Col sm={4} style={{textAlign: 'left'}}>
                                  Text Trigger{' '}
                                  {item.TextName && `( ${item.TextName} )`}
                                </Col>
                                <Col sm={4}>
                                  <Input
                                    type="text"
                                    name="textAddress"
                                    disabled
                                    defaultValue={`${currentUrlReader()}/${item.textID}`}
                                  />
                                </Col>
                                <Col>
                                  <Button
                                    disabled
                                    className="btn btn-warning button"
                                    style={{'margin-top': '0px'}}
                                    size="sm"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      const currTexts =
                                        formik.values['texts'] ?? [];
                                      currTexts.push(item);
                                      formik.setFieldValue(
                                          'texts',
                                          currTexts,
                                      );
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faCopy}/>
                                  </Button>
                                  <Button
                                    className="btn btn-danger button"
                                    style={{'margin-top': '0px'}}
                                    size="sm"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      deleteText({
                                        ...item,
                                        campaignID: props.campaignId,
                                      });
                                    }}
                                  >
                                    {isLoadingRemoveText && (
                                      <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                    )}
                                    {!isLoadingRemoveText && 'X'}
                                  </Button>
                                </Col>
                              </Row>
                            </AccordionHeader>
                            <AccordionBody>
                              <Texts
                                campaignId={props.campaignId}
                                formik={formik}
                                index={index}
                              />
                            </AccordionBody>
                          </Accordion>
                        );
                      })}
                  </CardBody>
                </>
              )}
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </div>
  );
};

export default Trigger;
