import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Select from 'react-select';
import GoogleFontLoader from '../../../../../../utils/GoogleFontLoader';

const getUrl = () => {
  const GOOGLE_API_KEY = 'AIzaSyDsCcWmj47QtG6WOp-DE2yXFMqtzi4j5Y4';
  return `https://www.googleapis.com/webfonts/v1/webfonts?key=${GOOGLE_API_KEY}&sort=POPULARITY`;
};

const FontSelect = ({formik, name, label}) => {
  if (!formik || !name) return null;
  const formikValue = formik?.values?.[name]?.value;
  const [fonts, setFonts] = useState([]);
  const options = fonts.map(item => ({value: item.family, label: item.family})) || [];
  const selected = options.find(item => item?.value === formikValue) || null;

  const onChange = ({value}) => {
    const currentFontItem = fonts.find(item => item.family === value);
    formik.setFieldValue(name, {value: value, payload: currentFontItem});
  };

  useEffect(async () => {
    try {
      const {data} = await axios.get(getUrl());
      setFonts(data?.items || []);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const labelStyle = {
    fontFamily: selected?.value || 'inherit',
  };

  return (
    <div className="textColorInput">
      <label style={labelStyle} htmlFor={name}>{label}</label>
      <Select
        name={name}
        placeholder={label}
        onBlur={formik.handleBlur}
        value={selected}
        onChange={onChange}
        defaultValue={selected}
        options={options}
        menuPortalTarget={document.body}
        styles={{menuPortal: base => ({...base, zIndex: 1})}}
      />
      {
        selected?.value && (
          <GoogleFontLoader
            fonts={[
              {
                font: selected.value,
                weights: ['regular'],
              },
            ]}
            subsets={['latin', 'latin-ext']}
          />
        )
      }
    </div>
  );
};

export default FontSelect;
