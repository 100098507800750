import React from 'react';
import {joinClasses} from '../../../utils';
import Switch from 'react-bootstrap-switch';

const SwitchInput = ({formik, name, label, labelEnd, specialValues, ...restProps}) => {
  if (!formik || !name) return null;
  const error = formik.touched?.[name] && formik.errors?.[name];

  const onChange = (e, v) => {
    formik.setFieldValue(name, specialValues ? specialValues[String(v)] : v);
  };

  const isOn = formik.values[name] === specialValues?.['true'];

  return (
    <div className={joinClasses('switchInput')}>
      {label && !labelEnd && <label htmlFor={name}>{label}</label>}
      <Switch
        {...restProps}
        value={specialValues ? isOn : !!formik.values[name]}
        onColor="success"
        offColor="primary"
        onChange={onChange}
        offText={<i className="nc-icon nc-simple-remove" />}
        onText={<i className="nc-icon nc-check-2" />}
      />
      {label && labelEnd && <label htmlFor={name}>{label}</label>}
      {error && (
        <div style={{color: 'red', fontSize: '12px'}}>{error}</div>
      )}
    </div>
  );
};

export default SwitchInput;
