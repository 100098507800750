const NotFound = () => {
  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <div style={{textAlign: 'center'}}>
        <h1>404</h1>
        <h2>Page Not Found</h2>
        <h5>Or you don't have the required permission</h5>
      </div>
    </div>
  );
};

export default NotFound;
