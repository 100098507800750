import {React, useState, useContext, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  FormText,
  Button,
  Spinner,
} from 'reactstrap';
import FileService from 'shared/services/FileService';
import {useQueryClient, useMutation} from '@tanstack/react-query';
import ClientService from 'shared/services/ClientService';
import {ChosenLocation} from 'contexts/RouterContext/ChosenLocation';
import AuthService from '../../../shared/services/AuthService2';

const authService = new AuthService();

const CreateClient = () => {
  const [chosenLocation] = useContext(ChosenLocation);
  const orgID = chosenLocation.organization || authService.getCurrentOrgList()[0];
  const history = useHistory();

  const [clientName, setClientName] = useState('');
  const [logo, setLogo] = useState(null);

  const queryClient = useQueryClient();
  const clientService = new ClientService();
  const fileService = new FileService();

  useEffect(() => {
    if (!orgID) history.push('/');
  }, [orgID]);

  const {mutate: createClient, isLoading: loadingClient} = useMutation(
      clientService.createClient,
      {
        onSuccess: (data) => {
          console.log(`success!`);
        },
        onError: () => {
          alert('there was an error');
        },
        onSettled: () => {
          queryClient.invalidateQueries('clients');
          history.push('/admin/clients');
        },
      },
  );

  const {mutate: createFile, isLoading: loadingFile} = useMutation(
      async (formData) => {
        return await fileService.createFile(formData);
      },
      {
        onSuccess: (returnLogo) => {
          const clientData = {
            Logo: returnLogo.replace(/["]+/g, ''),
            Name: clientName,
            orgID: orgID,
            dateCreated: new Date(),
          };
          createClient(clientData);
          console.log(`success! ${returnLogo}`);
        },
        onError: () => {
          alert(
              `Please upload a logo and input an organization name.
               Or wait a minute and try again...`,
          );
        },
      },
  );

  const handleCreateClient = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append('file', logo);
    formData.append('fileName', logo.Name);
    formData.append('clientname', clientName);
    createFile(formData);
  };

  return (
    <>
      <div
        className="h-100 w-100 container-fluid d-flex flex-column
        justify-content-center align-items-center "
        style={{fontFamily: 'sans-serif'}}
      >
        <Row className=" h-100 w-100">
          <h4 className="font-weight-normal">Client Details</h4>
        </Row>
        <Row className="d-flex w-100">
          <Col sm="12">
            <Form
              onSubmit={(e) => handleCreateClient(e)}
              className="bg-white rounded-lg shadow-sm h-100 d-flex flex-column p-3"
            >
              <FormGroup className="mt-5 ">
                <Label for="clientname" className="font-weight-bold">
                  Client Name
                </Label>
                <Input
                  type="text"
                  name="clientname"
                  value={clientName}
                  onChange={(e) => setClientName(e.target.value)}
                  placeholder="Enter client name"
                  className="w-100"
                ></Input>
              </FormGroup>
              <FormGroup className="mt-2 ">
                <Label for="logoUpload" className="font-weight-bold">
                  Upload Client Logo
                </Label>
                <Input
                  type="file"
                  name="logoUpload"
                  id="logoUpload"
                  className="bg-white w-100"
                  onChange={(e) => setLogo(e.target.files[0])}
                />
                <FormText color="muted">
                  Upload your client's logo. Let the user know any image
                  restrictions here; size, format or otherwise.
                </FormText>
              </FormGroup>
              {loadingClient || loadingFile ? (
                <div className="d-flex justify-content-center">
                  <Spinner
                    color="primary"
                    style={{
                      height: '3rem',
                      width: '3rem',
                    }}
                  >
                    Loading...
                  </Spinner>
                </div>
              ) : (
                <Button
                  disabled={loadingClient}
                  type="submit"
                  className="mt-5 rounded-pill"
                  style={{backgroundColor: '#1898DA'}}
                >
                  Create Client
                </Button>
              )}
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CreateClient;
