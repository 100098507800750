import * as yup from 'yup';
const PHONE_REGEX = /(?:\d{1}\s)?\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})/;
export const getRolesOptions = (arr) => arr.filter(item => item.active).map(item => ({label: item.role, value: item.id}));

export const userSchema = (isEdit) => yup.object().shape({
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
  email: yup.string().required('Required'),
  phone: yup.string().matches(PHONE_REGEX, 'Phone number is not valid').nullable(),
});
