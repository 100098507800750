import React, {useContext} from 'react';
import {DateTime as luxDate} from 'luxon';
import {
  Col,
  Card,
  CardBody,
  Row,
  CardFooter,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import {Link} from 'react-router-dom';
import {format, parseISO} from 'date-fns';
import {ChosenLocation} from '../contexts/RouterContext/ChosenLocation.js';


export const CampaignDisplay = (props) => {
  const [chosenLocation, setChosenLocation] = useContext(ChosenLocation);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const {campaign} = props;
  const clickHandler = () => {
    setChosenLocation({
      ...chosenLocation,
      campaign: campaign.id,
    });
  };
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const formatUSD = (amount) => {
    return amount.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  };
  return (
    <Col
      lg="4"
      md="6"
      sm="6"
      key={campaign.id.toString()}
      className="d-flex justify-content-center align-items-center"
    >
      <Card className="card-stats c-display-card-height">
        <CardBody className="p-0 c-font-fullhd">
          <div
            className={`${
              campaign.status === 'Active' ?
                'active-card-header' :
                'inactive-card-header'
            } container-fluid mb-2`}
          ></div>
          <div className="mx-4 mt-0 header-wrapper">
            <div >
              <h5
                className={`font-weight-bold mb-0 ${
                  !campaign.campaignName && 'text-danger'
                }`}
              >
                {campaign.campaignName ?
                  campaign.campaignName :
                  'NAME NOT PROVIDED'}
              </h5>
            </div>

            <div className="dropdown-wrapper" onClick={toggle}>
              <Dropdown className="m-2 pr-2 pl-2" isOpen={dropdownOpen} direction={'down'}>
                <DropdownToggle className="nohover shadow-none m-0 p-0" style={{backgroundColor: 'transparent', color: 'black'}}><i className="fa fa-ellipsis-v fa-2x"></i></DropdownToggle>
                <DropdownMenu right>
                  <Link
                    onClick={clickHandler}
                    to="/admin/clients/campaigns/edit"
                    className="c-button-link"
                  >
                    <DropdownItem>

                Edit
                    </DropdownItem>
                  </Link>

                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          <div className="mx-4">
            <Row className=" mt-0 c-pill-height">
              <div
                className="c-light-gray rounded-pill d-flex justify-content-center ml-2"
              >
                <p className="text-center c-variable-font font-italic m-auto" >
                  Created:{' '}
                  {campaign.dateCreated &&
                    format(
                        new Date(parseISO(campaign.dateCreated)),
                        'MM-dd-yyyy',
                    )}
                </p>
              </div>
            </Row>
            <Row className="" style={{marginBottom: '4rem'}}>
              <Col>
                {/* <p className="m-0 p-0">Program:</p>
                <p
                  className={`font-weight-bold m-0 p-0 mb-2 campaign-card-info-text ${
                    !campaign.programName && 'text-danger'
                  }`}
                >
                  {campaign.programName ?
                    campaign.programName :
                    campaign.programName === '' ?
                    'NOT PROVIDED' :
                    'NOT PROVIDED'}
                </p> */}
                <p className="m-0 p-0">Amount Disbursed:</p>
                <p
                  className={`font-weight-bold m-0 p-0 campaign-card-info-text ${
                    !campaign.campaignBudget && 'text-danger'
                  }`}
                >
                  {campaign.campaignBudget ?
                    `${formatUSD(campaign.campaignBudget)}` :
                    'NOT PROVIDED'}
                </p>
              </Col>
              {/* total parts / primary contact */}
              <Col>
                <p className="m-0 p-0">Total Participants:</p>
                <p
                  className={`font-weight-bold m-0 p-0 mb-2 campaign-card-info-text ${
                    !campaign.totalParticipants ? 'text-danger' : 'text-info'
                  }`}
                >
                  {campaign.totalParticipants ?
                    campaign.totalParticipants :
                    '0'}
                </p>
                {/* <p className="m-0 p-0">Primary Contact:</p>
                <p
                  className={`font-weight-bold m-0 p-0 campaign-card-info-text ${
                    !campaign.primaryContactID ? 'text-danger' : 'text-info'
                  }`}
                >
                  {campaign.primaryContactID ?
                    campaign.primaryContactID :
                    'NOT PROVIDED'}
                </p> */}
              </Col>
            </Row>
          </div>
        </CardBody>
        <CardFooter className="p-0 c-footer-height">
          <hr className="m-0" />
          <div className="w-100 h-100 d-flex">
            <div className="w-50 h-100 d-flex c-button-hover">
              <Link
                onClick={clickHandler}
                to="./campaigns/details"
                className="w-100 h-100 border-right d-flex justify-content-center align-items-center c-button-link"
              >
                View Details
              </Link>
            </div>
            <div className="w-50 h-100 d-flex flex-column justify-content-center align-items-center">
              <p className="m-0 p-0 text-success font-weight-bold text-center">
                {campaign.status ? campaign.status.toUpperCase() : 'NONE'}
              </p>
              <p
                className={`m-0 p-0 c-variable-font w-100 font-italic text-center ${
                  !campaign.campaignDateEnd && 'text-danger'
                }`}
              >
                <span className="font-weight-bold"> Ends:</span>{' '}
                <span className="font-weight-bold">
                  {campaign.campaignDateEnd!='' ?
                  `${luxDate.fromISO(campaign.campaignDateEnd).toFormat('MM-dd-yyyy')} at ${luxDate.fromISO(campaign.campaignDateEnd).toFormat('hh:ss a')}` :
                  'NOT PROVIDED'}</span>
              </p>
            </div>
          </div>
        </CardFooter>
      </Card>
    </Col>
  );
};
