import React from 'react';

export const TimeLimit = ({formik}) => {
  return (
    <div>
      <label>Time Limit to Purchase Once Activated</label>
      <div className="form-group form-inline">
        <label className="label-inline mr-3">Hours</label>
        <select
          className="form-control d-inline"
          name="timeLimitHour"
          defaultValue={formik.values.timeLimitHour}
          onChange={formik.handleChange}
        >
          <option value="" selected></option>
          {[...Array(25).keys()].map((val) => {
            return (
              <option value={val} selected={val === (formik.values.timeLimitHour ?? 0)}>
                {val}
              </option>
            );
          })}
        </select>
        <label className="label-inline mr-3 ml-5">Minutes</label>
        <select
          className="form-control d-inline"
          defaultValue={formik.values.timeLimitMin}
          onChange={formik.handleChange}
          name="timeLimitMin"
        >
          <option value="" ></option>
          <option value="0" selected>0</option>
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
          <option value="25">25</option>
          <option value="30">30</option>
          <option value="35">35</option>
          <option value="40">40</option>
          <option value="45">45</option>
          <option value="50">50</option>
          <option value="55">55</option>
        </select>
      </div>
    </div>
  );
};
