import React from 'react';
import {Form} from './index';
import {PDFInput} from './fields';

const OfficialLinks = ({formik, onSubmit}) => {
  return (
    <div>
      <Form formik={formik} onSubmit={onSubmit}>
        <PDFInput
          label="Official Rules"
          placeholder="Enter Link"
          name="footerRules"
          formik={formik}
        />
        <PDFInput
          label="Privacy Rules"
          placeholder="Enter Link"
          name="footerPrivacy"
          formik={formik}
        />
        <PDFInput
          label="Terms of Use"
          placeholder="Enter Link"
          name="footerTerms"
          formik={formik}
        />
      </Form>
    </div>
  );
};

export default OfficialLinks;
