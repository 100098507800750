import {STORAGE_KEYS} from './config';
import {axiosInstance, authHeader} from '../../utils';
import {ROLES} from '../../routes/config';

const API_URL = '/Identity/';

class AuthService2 {
  createUser(user) {
    return axiosInstance.post(API_URL + 'register', user);
  }

  login(email, password) {
    return axiosInstance.post(API_URL + 'login', {
      email: email,
      password: password,
    });
  }

  logout() {
    const attemptDelete = axiosInstance.delete(API_URL + `logout`);
    localStorage.clear();
    return attemptDelete;
  }

  saveUser(user) {
    localStorage.setItem(STORAGE_KEYS.user, JSON.stringify(user));
  }

  saveTokens(token, refreshToken) {
    localStorage.setItem(STORAGE_KEYS.token, token);
    localStorage.setItem(STORAGE_KEYS.refreshToken, refreshToken);
    axiosInstance.defaults.headers.Authorization = authHeader();
  }

  getToken() {
    return localStorage.getItem(STORAGE_KEYS.token);
  }

  getCurrentUser() {
    return localStorage.getItem(STORAGE_KEYS.user) && JSON.parse(localStorage.getItem(STORAGE_KEYS.user)) || null;
  }
  getCurrentRoles() {
    return this.getCurrentUser()?.roles || [];
  }
  getCurrentOrgList() {
    return this.getCurrentRoles().filter(item => item.roleId.toLowerCase() === ROLES.org.toLowerCase()).map(item => item.organizationId);
  }

  isLoggedIn() {
    return localStorage.getItem(STORAGE_KEYS.token) && localStorage.getItem(STORAGE_KEYS.user);
  }

  userHasRole(role) {
    const roles = JSON.parse(localStorage.getItem(STORAGE_KEYS.user))?.roles || [];
    return roles?.includes(role);
  }

  isSuperAdmin() {
    const userRoles = JSON.parse(localStorage.getItem(STORAGE_KEYS.user))?.roles.map(item => item.roleId) || [];
    return userRoles.includes(ROLES.superAdmin.toLowerCase());
  }

  canUserDo(validRoles = []) {
    if (this.isSuperAdmin()) return true;
    let canDo = false;
    const userRoles = JSON.parse(localStorage.getItem(STORAGE_KEYS.user))?.roles.map(item => item.roleId.toLowerCase()) || [];
    validRoles?.forEach(item => {
      if (userRoles.includes(item.toLowerCase())) canDo = true;
    });
    return canDo;
  }

  resetPassword(email) {
    return axiosInstance.patch(API_URL + 'Reset-Password-Email' + '?email=' + email, {
      email,
    });
  }

  updatePassword(data) {
    return axiosInstance.patch(`${API_URL}Update-Password`, {
      email: data.email,
      password: data.password,
      passwordConfirm: data.passwordconfirm,
      linkId: data.linkid,
    });
  }

  authenticateUpdate(data) {
    return axiosInstance.post(`${API_URL}Authenticate-Update`, {
      email: data.email,
      linkId: data.linkid,
    });
  }
}

export default AuthService2;
