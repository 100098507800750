import {Spinner} from 'reactstrap';

export const CashIconRenderer = (props) => {
  return (
    <div className="text-align-center">
      {props.value === 'Y' ? (
        <i
          className="zmdi zmdi-check zmdi-hc-lg"
          style={{fontWeight: 'bold', color: 'greenyellow'}}
        ></i>
      ) : (
        <i
          className="zmdi zmdi-block zmdi-hc-lg"
          style={{fontWeight: 'bold', color: '#bbbbbb'}}
        ></i>
      )}
    </div>
  );
};

export const CustomLoadingOverlay = () => {
  return (
    <div className="ag-overlay-loading-center">
      <i>
        <Spinner></Spinner>
      </i>
    </div>
  );
};
