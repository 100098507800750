import React, {useState, useEffect, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import AuthService from 'shared/services/AuthService';
import FilterBar from './components/shared/FilterBar';
import {ChosenLocation} from 'contexts/RouterContext/ChosenLocation';
// reactstrap components
import {Row, Spinner} from 'reactstrap';
import {CampaignDisplay} from './CampaignDisplay';
import CampaignService from 'shared/services/CampaignService';
import {useQuery} from '@tanstack/react-query';
const Campaigns = (props) => {
  const [filteredCampaign, setFilteredCampaign] = useState([]);
  const [chosenLocation] = useContext(ChosenLocation);
  const campaignService = new CampaignService();

  // TODO: when roles are introducted we will need to implement a the get all campaigns.
  // useQuery(["campaigns"], () => campaignService.getCampaigns());
  const {
    isLoading: loading,
    error: errors,
    data: campaignData,
  } = useQuery(['campaigns', chosenLocation.client], () => campaignService.getCampaignsByClientId(chosenLocation.client));
  const history = useHistory();
  useEffect(() => {
    // Update the org
    if (campaignData?.data?.length > 0) {
      setFilteredCampaign(campaignData?.data);
    }
  }, [campaignData]);

  // check login here
  const isLoggedIn = AuthService.isLoggedIn();
  if (!isLoggedIn) {
    history.push('/auth/login');
  }

  const setQuickFilter = (e) => {
    const value = e.target.value;
    if (value === 'All') return setFilteredCampaign(campaignData?.data);
    const filteredData = campaignData?.data?.filter((item) => {
      return item.status === value;
    });
    setFilteredCampaign(filteredData);
  };

  if (loading) {
    return <Spinner color="primary" className="page-loader" />;
  }
  if (errors) {
    console.error(errors);
    return <h1>Oops, Something happened!</h1>;
  }
  return (
    <>
      <div className="c-centered-row p-0 pr-4">
        <FilterBar
          navigateUrl={`/admin/clients/campaigns/create`}
          setQuickFilter={setQuickFilter}
          currentPage={'Campaigns'}
          dropdownOption={['All', 'Draft', 'Scheduled', 'Active', 'Archived']}
        />
      </div>

      <div className="m-auto p-0">
        <Row className="c-centered-row mt-0 pt-0">
          {filteredCampaign?.map((campaign, i) => {
            return <CampaignDisplay key={i} campaign={campaign} />;
          })}
        </Row>
      </div>
    </>
  );
};

export default Campaigns;
