import React, {useEffect, useRef} from 'react';
import {Card, Row} from 'reactstrap';
import {Spinner} from 'react-bootstrap';
import useResize from '../../../shared/hooks/useResize';
import {drawGraph, getColumnsConfig} from './utils';
import {chartStyle, GRADIENT_END, GRADIENT_START, MIN_HEIGHT} from './config';
import Header from './Header';
import {CAMPAIGN_TYPES} from '../../Campaign/config';

const CampaignFunnelGraph = ({campaignData, loading}) => {
  const isEnterToWin = campaignData?.type === CAMPAIGN_TYPES[1].value;
  const chart = useRef(null);
  const columns = getColumnsConfig(campaignData, isEnterToWin);
  const graphData = {
    colors: [GRADIENT_START, GRADIENT_END],
    values: columns.map(item => isNaN(item.value) ? 0 : item.value),
  };

  useResize(() => drawGraph(chart.current, graphData));
  useEffect(() => {
    if (!campaignData || loading) return;
    if (campaignData) {
      drawGraph(chart.current, graphData);
    }
  }, [campaignData]);

  return (
    <Card className="my-2 p-2">
      <Header columns={columns} minHeight={MIN_HEIGHT} loading={loading}/>
      <Row className="mx-0">
        {loading ? (
            <Spinner animation="border"/>
          ) : (
            <div
              style={chartStyle}
              className="funnel-graph mx-2"
              ref={chart}
            />
          )}
      </Row>
    </Card>
  );
};

export default CampaignFunnelGraph;
