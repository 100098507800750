import React, {useState} from 'react';
import {Collapse} from 'reactstrap';
import GroupItem from './GroupItem';
import {joinClasses} from '../../../../../utils';

const OptionsGroup = ({activeItem, setActiveItem, formList, groupLabel, defaultItem}) => {
  const [isOpen, setIsOpen] = useState(false);

  const addActiveItem = key => () => setActiveItem(key);
  const toggle = () => {
    setIsOpen(prev => {
      setActiveItem(!prev === false ? -1 : defaultItem ?? -1);
      return !prev;
    });
  };

  return (
    <div>
      <div className="optionsGroup">
        <div
          className={joinClasses('optionsGroupMainButton', isOpen && 'active')}
          onClick={toggle}>{groupLabel}
        </div>
        <Collapse isOpen={isOpen}>
          <div className="optionsGroupBox">
            {formList?.map(({title, isFilledOut, disabled}, i) => {
              return (
                <GroupItem
                  key={title}
                  isFilledOut={isFilledOut}
                  title={title}
                  isActive={activeItem === i}
                  onClick={addActiveItem(i)}
                  disabled={disabled}
                />
              );
            })}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default OptionsGroup;
