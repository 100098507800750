import React from 'react';
import {useLocation} from 'react-router-dom';
import {authService2} from 'views/Users/api';
import {useFormik} from 'formik';
import {useMutation} from '@tanstack/react-query';
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  Form,
  Input,
  Container,
  Col,
  Row,
} from 'reactstrap';
import {convertQueryToObject} from '../../utils';
import {validate} from './utils';


const ResetPassword = (props) => {
  const {search} = useLocation();
  const searchParams = convertQueryToObject(search);
  const linkid = searchParams['linkid'];
  const email = searchParams['email'];
  const [authCheck, setAuthCheck] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const {mutate: handleResetPasswordMutation, isSuccess: isResetSuccess} = useMutation((email, password, linkid) => {
    return authService2.updatePassword(email, password, linkid);
  },
  {
    onError: (error) => {
      console.log('err', error.response.data);
      setErrorMessage(error.response.data);
    },
  },
  );
  const {mutate: authenticateUpdate} = useMutation((email, linkid) => {
    return authService2.authenticateUpdate(email, linkid);
  },
  {
    onSuccess: (data) => {
      setAuthCheck(true);
    },
  },
  );

  const formik = useFormik({
    initialValues: {
      email: email || '',
      password: '',
      passwordConfirm: '',
    },
    validate,
    onSubmit: (values) => {
      if (authCheck) {
        handleResetPassword();
      }
    },
  });

  React.useEffect(() => {
    authenticateUpdate({email, linkid});
  }, [linkid, email]);

  const handleResetPassword = (values) => {
    if (formik.values.email?.trim() && formik.values.password?.trim()) {
      const data = {...formik.values, linkid};
      handleResetPasswordMutation(data);
    }
  };

  const clearLocalStorageCredentials = () => {
    localStorage.setItem('user', '');
    localStorage.setItem('username', '');
  };
  return (
    <div className="login-page">
      <Container>
        <Row>
          <Col className="m-auto" lg="5" md="6">
            <Form onSubmit={formik.handleSubmit} action="" className="form" name="form" method="">
              <Card>
                {
                  isResetSuccess ? (
                    <div className="login-card-body">
                      <CardHeader>
                        <h5 className="title text-center">Success!</h5>
                      </CardHeader>
                      <p className="text-center">Success</p>
                      <CardFooter className='d-flex pr-0 justify-content-end'>
                        <Button
                          onClick={clearLocalStorageCredentials}
                          className="btn-round login-submit-button"
                          href="./login"
                        >
                     Login
                        </Button>
                      </CardFooter>
                    </div>
                  ) : (
                    <div className="login-card-body">
                      <CardHeader>
                        <h5 className="title text-center">Create New Password</h5>
                      </CardHeader>
                      <Input
                        className={`has-label, login-height`}
                        id={'email'}
                        name={'email'}
                        placeholder="Enter Email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        type="email"
                      />
                      <Input
                        className={`has-label, login-height`}
                        id={'password'}
                        name={'password'}
                        placeholder="Password"
                        type="password"
                        autoComplete="off"
                        onChange={(event) => {
                          formik.handleChange(event); setErrorMessage('');
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                      />
                      <Input
                        className={`has-label, login-height`}
                        id={'passwordConfirm'}
                        name={'passwordConfirm'}
                        placeholder="Confirm Password"
                        type="password"
                        autoComplete="off"
                        onChange={(event) => {
                          formik.handleChange(event); setErrorMessage('');
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.passwordConfirm}
                      />
                      {formik.touched.passwordConfirm && formik.errors.passwordConfirm ? (<div style={{paddingLeft: '20px', color: 'red'}}>{formik.errors.passwordConfirm}</div>) : null}
                      {errorMessage ? (<div style={{paddingLeft: '20px', color: 'red'}}>{errorMessage}</div>) : null}
                      <CardFooter className='d-flex pr-0 justify-content-end'>
                        <Button
                          className="btn-round login-submit-button"
                          type="submit"
                          disabled={!authCheck}
                        >
                          Submit
                        </Button>
                      </CardFooter>
                    </div>
                  )
                }

              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
      <div
        className="full-page-background"
      />
    </div>
  );
};

export default ResetPassword;
