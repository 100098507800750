import React, {useState, useEffect} from 'react';
import {Col, Row} from 'reactstrap';
import {formatFilteredOption} from '../../utils';
import ActionsComplete from './ActionsComplete';
import ActionsCompleteETW from './ActionsCompleteETW';
import ReceivedCash from './ReceivedCash';
import LeadGenSource from './LeadGenSource';
import StateGroup from './StateFilter';

const TableFilter = ({setFilterParams, isEnterToWin}) => {
  const [selectedActionCompleted, setSelectedActionCompleted] = useState([]);
  const [selectedRecieveCashOption, setSelectedRecieveCashOption] = useState([]);
  const [leadGenSource, setLeadGenSource] = useState([]);
  const [stateSearchText, setStateSearchText] = useState();

  useEffect(() => {
    setFilterParams({
      actionCompleted: formatFilteredOption(selectedActionCompleted),
      receiveCashOption: formatFilteredOption(selectedRecieveCashOption),
      leadGenSource: formatFilteredOption(leadGenSource),
      state: stateSearchText,
    });
  }, [
    selectedActionCompleted,
    selectedRecieveCashOption,
    leadGenSource,
    stateSearchText,
  ]);

  const onCheckboxBtnClick = (chosenValue, value, setValue) => {
    const index = value.indexOf(chosenValue);
    if (index < 0) value.push(chosenValue);
    else value.splice(index, 1);
    setValue([...value]);
  };

  return (
    <Row className="d-flex justify-content-around gray-background p-3 mt-3 mr-3 ml-3">
      <Col className="d-flex flex-column justify-content-start">
        {
          isEnterToWin ? <ActionsCompleteETW
            onClick={(v) => onCheckboxBtnClick(v, selectedActionCompleted, setSelectedActionCompleted)}
            selected={selectedActionCompleted}
          /> :
            <ActionsComplete
              onClick={(v) => onCheckboxBtnClick(v, selectedActionCompleted, setSelectedActionCompleted)}
              selected={selectedActionCompleted}
            />
        }
        <LeadGenSource
          onClick={(v) => onCheckboxBtnClick(v, leadGenSource, setLeadGenSource)}
          selected={leadGenSource}
        />
      </Col>
      <Col className="d-flex flex-column justify-content-start">
        {
          !isEnterToWin && (
            <ReceivedCash
              onClick={(v) => onCheckboxBtnClick(v, selectedRecieveCashOption, setSelectedRecieveCashOption)}
              selected={selectedRecieveCashOption}
            />
          )
        }
        <StateGroup onClick={setStateSearchText} selected={stateSearchText}/>
      </Col>
    </Row>
  );
};

export default TableFilter;
