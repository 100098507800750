import {NavLink} from 'react-router-dom';
import AuthService2 from '../../shared/services/AuthService2';

const clientService = new AuthService2();

const Submenu = ({handleResetContext, data}) => {
  return (
    <ul className="nav-submenu">
      {
        data?.map((item) => {
          const isAllowed = clientService.canUserDo(item.validRoles);
          const isShow = item.path ? location.pathname.includes(item.path) : true;
          return (
            <li key={item.path} style={{display: (!isShow || !isAllowed) && 'none'}}>
              <NavLink
                onClick={item.context ? () => handleResetContext(item.context) : undefined}
                className="nav-link-subitem"
                exact
                to={item.to}
              >
                {item.text}
              </NavLink>
            </li>
          );
        })
      }
    </ul>
  );
};

export default Submenu;
