import React from 'react';

const FormSection = ({children, label, split}) => {
  return (
    <section>
      {label && <div style={{fontSize: '12px', color: '#808080', marginBottom: '4px'}}>{label}</div>}
      <div style={{border: '1px solid #e4e2e2', borderRadius: '6px', padding: '20px'}}>
        {
          split ? children.map((item, i) => (
            <>
              {item}
              {children.length > (i + 1) && (
                <div
                  style={{
                    borderBottom: '1px solid #e4e2e2',
                    margin: '20px -20px',
                  }}
                />
              )}
            </>
          )) : children
        }
      </div>
    </section>
  );
};

export default FormSection;
