import React, {useEffect, useRef, useState} from 'react';
import {PhoneFlip} from 'react-bootstrap-icons';
import {joinClasses} from '../../../utils';

import './style.scss';

const FlipChange = ({children, unmountHidden = true}) => {
  const frontRef = useRef();
  const cashHeight = useRef(0);
  const [otherSide, setOtherSide] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [initHeight, setInitHeight] = useState(cashHeight.current);

  useEffect(() => {
    // do not delete setTimeout
    setTimeout(() => {
      const height = frontRef.current?.clientHeight || cashHeight.current;
      cashHeight.current = height;
      setInitHeight(height);
    }, 0);
  }, [frontRef.current?.clientHeight]);

  const changeSide = () => {
    setIsDeleted(false);
    setOtherSide(prev => !prev);
    setTimeout(() => {
      setIsDeleted(true);
    }, 500);
  };

  return (
    <div className="flip-container-wrap">
      <div
        className={joinClasses('flip-container', otherSide && 'otherSide')}
        style={{height: initHeight}}
      >
        <div className="flipper">
          <div className="front">
            <div ref={frontRef}>
              {unmountHidden && isDeleted && otherSide ? null : children[0] }
            </div>
          </div>
          <div className="back">
            {unmountHidden && isDeleted && !otherSide ? null : children[1] }
          </div>
        </div>
      </div>
      <div className="flip-button" onClick={changeSide}>
        <PhoneFlip/>
      </div>
    </div>
  );
};

export default FlipChange;
