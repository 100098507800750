import {faDisplay, faMoneyBill1Wave, faReceipt, faShield} from '@fortawesome/free-solid-svg-icons';
import {faClipboard} from '@fortawesome/free-regular-svg-icons';

export const getColumns = (campaign) => [
  {
    id: 0,
    mainText: 'Opened Landing Page',
    icon: faDisplay,
    value: campaign?.landingCount,
    valueAmount: campaign?.landingCount,
  },
  {
    id: 1,
    mainText: 'Submitted Initial Data',
    icon: faClipboard,
    value: campaign?.userInfoUploadedCount,
    yesAmount: campaign?.userInfoUploadedCount,
    noAmount: campaign?.landingCount - campaign?.userInfoUploadedCount,
  },
  {
    id: 2,
    mainText: 'Submitted PoP',
    icon: faReceipt,
    value: campaign?.receiptSubmittedCount,
    yesAmount: campaign?.receiptSubmittedCount,
    noAmount: campaign?.userInfoUploadedCount - campaign?.receiptSubmittedCount,
  },
  {
    id: 3,
    mainText: 'Submitted Disbursement',
    icon: faShield,
    value: campaign?.disbursementCount,
    yesAmount: campaign?.disbursementCount,
    noAmount: campaign?.receiptSubmittedCount - campaign?.disbursementCount,
  },
  {
    id: 4,
    mainText: 'Received Disbursement',
    icon: faMoneyBill1Wave,
    value: campaign?.disbReceivedCount,
    yesAmount: campaign?.disbReceivedCount,
    noAmount: campaign?.disbursementCount - campaign?.disbReceivedCount,
  },
];

export const getColumnsETW = (campaign) => [
  {
    id: 0,
    mainText: 'Opened Landing Page',
    icon: faDisplay,
    value: campaign?.landingCount,
    valueAmount: campaign?.landingCount,
  },
  {
    id: 1,
    mainText: 'Passes Age gate',
    icon: faClipboard,
    value: campaign?.ageGateCount,
    yesAmount: campaign?.ageGateCount,
    noAmount: campaign?.landingCount - campaign?.ageGateCount,
  },
  {
    id: 2,
    mainText: 'Submitted Initial Entry',
    icon: faReceipt,
    value: campaign?.etwInitialEntries,
    yesAmount: campaign?.etwInitialEntries,
    noAmount: (campaign?.ageGateCount || campaign?.landingCount) - campaign?.etwInitialEntries,
  },
  {
    id: 3,
    mainText: 'Submitted Second Entry',
    icon: faShield,
    value: campaign?.etwSecondaryEntries,
    yesAmount: campaign?.etwSecondaryEntries,
    noAmount: campaign?.etwInitialEntries - campaign?.etwSecondaryEntries,
  },
];
