import {ROLES} from './config';

const mainNavigation = ({orgName='Alpha 6'}) => [
  {
    text: 'Organizations',
    to: '/admin/organizations',
    context: 'Organizations',
    iconClasses: 'zmdi zmdi-globe-alt',
  },
  {
    text: 'Clients',
    to: '/admin/clients',
    context: 'Client',
    iconClasses: 'zmdi zmdi-accounts-alt',
    validRoles: [ROLES.org],
    children: [
      {
        text: 'Campaigns',
        to: '/admin/clients/campaigns',
        context: 'Campaigns',
        path: '/admin/clients/campaigns',
        validRoles: [ROLES.org],
      },
      {
        text: 'Create Campaign',
        to: '/admin/clients/campaigns/create',
        context: 'Campaigns',
        path: '/admin/clients/campaigns',
        validRoles: [ROLES.org],
      },
      {
        text: 'Participant List',
        to: '/admin/clients/campaigns/participantlist',
        context: 'Campaigns',
        path: '/admin/clients/campaigns',
        validRoles: [ROLES.org],
      },
    ],
  },
  {
    text: `${orgName} Users`,
    to: '/admin/users',
    iconClasses: 'zmdi zmdi-accounts-alt',
    validRoles: [ROLES.org],
  },
];

export default mainNavigation;
