import {axiosInstance} from '../../utils';

const clientUrl = '/client';

class ClientService {
  async getAllClients() {
    return axiosInstance.get(`${clientUrl}/clients/`);
  }

  getClientById(id) {
    return axiosInstance.get(`${clientUrl}/client-info/${id}`);
  }

  createClient(clientPayload) {
    return axiosInstance.post(`${clientUrl}/create-client/`, clientPayload);
  }

  updateClient(clientPayload, id) {
    return axiosInstance.put(`${clientUrl}/update-client/${id}`, clientPayload);
  }
}
export default ClientService;
