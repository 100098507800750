import React from 'react';
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  UncontrolledDropdown,
} from 'reactstrap';
import {List} from 'react-bootstrap-icons';
import {useHistory} from 'react-router-dom';
import Switch from 'react-bootstrap-switch';
import {userService} from './api';

const ProfileNav = ({data, statusValue, setStatusValue}) => {
  const {id} = data;
  const history = useHistory();

  const onEdit = () => {
    history.push(`/admin/users/edit/${id}`);
  };

  const changeStatus = async () => {
    try {
      const newStatus = statusValue === 'Active' ? 'InActive' : 'Active';
      const result = await userService.updateUser(id, {...data, status: newStatus});
      setStatusValue(result.data?.status);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Navbar expand="md">
        <Collapse isOpen={false} navbar>
          <Nav className="mr-auto" navbar>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <List color="#000"/>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>
                  <Switch
                    value={statusValue === 'Active'}
                    onColor="success"
                    offColor="primary"
                    onText="Yes"
                    offText="No"
                    onChange={changeStatus}
                  />
                </DropdownItem>
                <DropdownItem divider/>
                <DropdownItem onClick={onEdit}>
                  Edit
                </DropdownItem>
                <DropdownItem divider/>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default ProfileNav;
