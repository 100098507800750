import React from 'react';
import {Row, Col} from 'reactstrap';
import InfoCard from '../components/shared/InfoCard';
import {
  calcPillColorByBurnPercentage,
  calculateBurnPercentage, campaignActiveRange, redemptionTimeLimit,
} from './utils';
import {calculateCampaignEndDateTime, handleOfferValueDisplay} from '../../shared/utils';
import './style.scss';

const Header = ({campaignData}) => {
  return (
    <Row className="mx-0">
      <Col sm="12" md="2" className="px-0 my-2">
        <InfoCard
          navLocation={'./participantlist'}
          cardMainText={'Total Reach'}
          pillColor={'success'}
          valueAmount={campaignData?.landingCount ?? 0}
          linkText={'View Participants'}
        />
      </Col>
      <Col sm="12" md="2" className="px-0 my-2">
        <InfoCard
          cardMainText={'Budget Burn'}
          pillColor={calcPillColorByBurnPercentage(
              calculateBurnPercentage(campaignData),
          )}
          valueAmount={campaignData?.disbReceivedCount ?? 0}
          pillText={calculateBurnPercentage() + ' %'}
          linkText={'Redemptions'}
        />
      </Col>
      <Col sm="12" md="3" className="px-0 my-2">
        <InfoCard
          cardMainText={'Campaign Active'}
          valueAmount={campaignActiveRange(campaignData)}
          pillText={calculateCampaignEndDateTime(
              campaignData?.campaignDateStart,
              campaignData?.campaignDateEnd,
          )}
          pillColor={'info'}
        />
      </Col>
      <Col sm="12" md="2" className="px-0 my-2">
        <InfoCard
          cardMainText={'Offer:'}
          valueAmount={handleOfferValueDisplay(campaignData?.cashBackAmt, campaignData?.cashBackType, campaignData?.percentage)}
        />
      </Col>
      <Col sm="12" md="3" className="px-0 my-2">
        <InfoCard
          cardMainText={'Redemption Time Limit:'}
          valueAmount={redemptionTimeLimit(campaignData)}
        />
      </Col>
    </Row>
  );
};

export default Header;
