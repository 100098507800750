import {Button} from 'reactstrap';
import React from 'react';
import {joinClasses} from '../../utils';

const SubmitBox = ({disabled, onSubmit, hidden, text='Submit'}) => {
  return (
    <div className={joinClasses('submitBox')} style={{display: hidden ? 'none' : 'block'}}>
      <Button color="primary" className="submitBtn" disabled={disabled} onClick={onSubmit}>{text}</Button>
    </div>
  );
};
export default SubmitBox;
