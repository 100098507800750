import {
  LogoForm,
  TextForm,
  HeroForm,
  ProofOfPurchase,
  ButtonForm,
  PersonalInfo,
  BackgroundForm,
  CashDisbursement,
  Confirmation,
  Rules,
  Copyright,
  OfficialLinks,
  InitialSubmission,
  SecondSubmission,
} from './forms';

export const offerLookForms = () => [
  {title: 'Logo', formTitle: 'Upload Logo:', component: LogoForm},
  {title: 'Text', formTitle: 'Text Settings:', component: TextForm},
  {title: 'Button', formTitle: 'Button Settings:', component: ButtonForm},
  {title: 'Hero Image', formTitle: 'Hero Image:', component: HeroForm, isFilledOut: true},
  {title: 'Background', formTitle: 'Background:', component: BackgroundForm},
];

export const offerExpForms = () => [
  {title: 'Personal Information', formTitle: 'Personal Information:', component: PersonalInfo},
  {title: 'Proof of Purchase', formTitle: 'Proof of Purchase:', component: ProofOfPurchase},
  {title: 'Cash Disbursement', formTitle: 'Disbursement:', component: CashDisbursement},
  {title: 'Confirmation', formTitle: 'Confirmation:', component: Confirmation},
];

export const footerForms = () => [
  {title: 'Rules', formTitle: 'Rules:', component: Rules},
  {title: 'Official Links', formTitle: 'Official Links:', component: OfficialLinks},
  {title: 'Copyright', formTitle: 'Copyright:', component: Copyright},
];

export const initialSubmissionForms = ({disabled}) => [
  {title: 'Initial Submission', formTitle: 'Initial Submission:', component: InitialSubmission},
  {title: 'Second Submission', formTitle: 'Second Submission:', component: SecondSubmission, disabled},
  {title: 'Confirmation', formTitle: 'Confirmation:', component: Confirmation},
];

export const URL_REG_EXP = /^((https?|ftp):\/\/)(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
