import {axiosInstance} from '../../utils';

const users = [
  {
    id: '0',
    firstName: 'Stepan',
    lastName: 'Givno',
    image: '',
    phone: '+1(571)241-3967',
    email: 'stepan@gmail.com',
    status: 'active',
    title: 'Title',
    roles: [
      {role: 'org', organizationId: 1},
      {role: 'org', organizationId: 2},
      {role: 'platform', organizationId: null},
    ],
    createdAt: '2023-01-01T00:00:00',
  },
  {
    id: '1',
    firstName: 'Vasyl',
    lastName: 'Perekotypole',
    image: '',
    phone: '+1(571)241-0202',
    email: 'pole@gmail.com',
    status: '',
  },
  {
    id: '2',
    firstName: 'Anna',
    lastName: 'Borsch',
    image: '',
    phone: '+1(571)241-3967',
    email: 'borsch@gmail.com',
    status: 'active',
  },
  {
    id: '3',
    firstName: 'Anton',
    lastName: 'Gandon',
    image: '',
    phone: '+1(571)241-0202',
    email: 'pole@gmail.com',
    status: 'active',
  },
];
export const rolesList = [
  {
    'id': '94835497-9b4c-4f76-8809-5fd7ce787c8c',
    'role': 'org',
    'active': true,
  },
  {
    'id': '594df995-1c2b-4203-b3d2-6c6bc86fa8f1',
    'role': 'platform',
    'active': true,
  },
];

const getUsers = () => new Promise((resolve, reject) => {
  setTimeout(() => resolve({data: users}), 1000);
});

const getUserItem = (i) => new Promise((resolve, reject) => {
  setTimeout(() => resolve({data: users[i]}), 1000);
});
const createUserItem = () => new Promise((resolve, reject) => {
  setTimeout(() => resolve({data: users[i]}), 1000);
});
const updateUserItem = () => new Promise((resolve, reject) => {
  setTimeout(() => resolve({data: users[i]}), 1000);
});
const getRolesMock = () => new Promise((resolve, reject) => {
  setTimeout(() => resolve({data: rolesList}), 1000);
});

const API_URL = '/ClientProfiles';

class UserService {
  constructor(useMockData) {
    this.useMockData = useMockData;
  }

  getList() {
    if (this.useMockData) return getUsers();
    return axiosInstance.get(`${API_URL}/Get-All-Users/`);
  };

  getUser(id) {
    if (this.useMockData) return getUserItem(id);
    return axiosInstance.get(`${API_URL}/users/${id}`);
  }

  geRolesList() {
    if (this.useMockData) return getRolesMock();
    return axiosInstance.get(`${API_URL}/roles`);
  }

  createUser(data) {
    if (this.useMockData) return createUserItem();
    return axiosInstance.post(`${API_URL}/register`, {
      ...data,
    });
  }

  updateUser(id, data) {
    if (this.useMockData) return updateUserItem();
    return axiosInstance.patch(`${API_URL}/update-client-user-info`, {
      id,
      ...data,
    });
  }

  resetPassword(email) {
    return axiosInstance.patch(API_URL + '/Reset-Password-Email', {
      email,
    });
  }
}

export default UserService;
