import {Route} from 'react-router-dom';
import {routes} from './routes';
import AuthService2 from '../shared/services/AuthService2';

const clientService = new AuthService2();

export const getRoutes = (layout, applyRoles = true) => {
  const filtered = applyRoles ? routes.filter(item => clientService.canUserDo(item.validRoles)) : routes;
  return filtered.map((item, i) => {
    if (item.layout === layout) {
      return (
        <Route
          path={item.layout + item.path}
          component={item.component}
          exact={item.exact}
          key={`${item.path}_${i}`}
        />
      );
    } else {
      return null;
    }
  });
};
