import React from 'react';
import {Form} from './index';
import {ColorInput, FontSelect} from './fields';

const TextForm = ({formik, onSubmit}) => {
  return (
    <div>
      <Form formik={formik} onSubmit={onSubmit}>
        <ColorInput label="Header Color" formik={formik} name="headerColor" />
        <ColorInput label="Description Color" formik={formik} name="descriptionColor" />
        <ColorInput label="Footer Color" formik={formik} name="footerColor" />
        <FontSelect label="Font Family" formik={formik} name="fontFamily" />
      </Form>
    </div>
  );
};

export default TextForm;
