import './csvreader.scss';
import {useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import Papa from 'papaparse';

function CsvParser({setParsedCsvData}) {
  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length) {
      Papa.parse(acceptedFiles[0], {
        complete: (results) => {
          setParsedCsvData(results.data);
        },
      });
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    onDrop,
    accept: 'text/csv',
  });

  return (
    <div className="my-4">
      <div
        {...getRootProps({
          className: 'dropzone btn-outline-muted',
        })}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>Drag & drop a file here, or click to select a file.</p>
        )}

      </div>
    </div>
  );
}

export default CsvParser;
