import React from 'react';
import {Button} from 'reactstrap';
import Container from '../components/campaign/StepContainer';
import Review from '../components/campaign/Review';

const Step6 = ({disabled, onSubmit, ...props}) => {
  return (
    <div>
      {props.open && (
        <Container>
          <Review
            setHorizontalTabs={props.setHorizontalTabs}
            open={props.horizontalTabs === 'review'}
            formik={props.formik}
            setSubItem={props.setSubItem} // for navigation to specific trigger tab
          />
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div></div>
            <Button
              style={{margin: 0}}
              color="primary"
              id="publishCompany"
              disabled={disabled}
              onClick={onSubmit}>Publish</Button>
          </div>
        </Container>
      )}
    </div>
  );
};

export default Step6;
