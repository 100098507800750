import React, {useState} from 'react';
import {useMutation} from '@tanstack/react-query';
import FileManagementService from '../../../../../../shared/services/FileService';
import {FileEarmarkPdfFill} from 'react-bootstrap-icons';
import {Input, Spinner} from 'reactstrap';

const FMS = new FileManagementService();
const PREFIX = 'input__pdf';

const PDFInput = ({formik, name, hint, label}) => {
  if (!formik || !name) return null;
  const [loading, _setLoading] = useState(false);
  const ID = `{${PREFIX}__${name}`;

  const setLoading = (value) => {
    _setLoading(value);
    formik.setSubmitting(value);
  };

  const {mutate: uploadLogo} = useMutation(FMS.createFile, {
    onSuccess: (url) => {
      setLoading(false);
      formik.setFieldValue(name, url);
    },
    onError: () => setLoading(false),
  });

  const onChange = (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    formData.append('fileName', e.target.files[0].name);
    setLoading(true);
    uploadLogo(formData);
  };
  return (
    <div className="pdfField animatedBox">
      {label && <span className="label">{label}</span>}
      <div className="pdfFieldBox">
        <Input
          name={name}
          placeholder="File path"
          {...formik.getFieldProps(name)}
        />
        <label className="pdfFieldLabel" htmlFor={ID}>
          <div>
            {loading ? <Spinner size="sm" color="primary" className="page-loader" /> : <FileEarmarkPdfFill size={25}/>}
          </div>
          <input
            style={{display: 'none'}}
            onChange={onChange}
            type="file"
            id={ID}
          />
        </label>
      </div>
      {hint && <div className="hint">{hint}</div>}
    </div>
  );
};

export default PDFInput;
