import React from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardText,
  Badge,
} from 'reactstrap';
import {Link} from 'react-router-dom';
/**
 *
 * @param {*} navLocation - where to route if we want to persist data and not cause re-render (history.push causes rerenders) - pass in the location IE "/admin/campaigns"
 * @param {*} linkText - What should the link say? IE "View Campaigns"
 * @param {*} valueAmount  - The main display value of the card IE "10"
 * @param {*} pillText - The text that will be displayed in the pill IE "Active"
 * @param {*} pillColor - The pill color
 * @param {*} cardMainText - The main text of the card IE "Total Reach"
 * @param {*} multiColData - An array of objects that will be displayed, if you need more than one key value pair on the SAME card
 */
const InfoCard = ({
  navLocation,
  linkText,
  valueAmount,
  pillText,
  cardMainText,
  pillColor,
  multiColData,
  colSize,
}) => {
  return (
    <>
      {multiColData ? (
          <Card className='basic-info-card '>
            <CardBody className="d-flex justify-content-between mx-0">
              <Row>
                {multiColData.map((data, index) => {
                  return (
                    <div className="multi-col-wrapper mx-2">
                      <p className="font-weight-bold">{data.label}</p>
                      <p className="font-weight-bold">{data.value}</p>
                    </div>
                  );
                })}
              </Row>
            </CardBody>
          </Card>
      ) : (
     <Card className='mx-2 individual-info-card'>
       <CardBody>
         <Row
         >
           <Col className='pr-0'>
             <p className='text-muted d-flex justify-content-between flex-wrap'>{cardMainText}
               {pillText && (
                 <Badge
                   color={pillColor ? pillColor : ''}
                   style={{backgroundColor: pillColor, textTransform: 'none', marginRight: '4px'}}
                   pill
                 >
                   {pillText}
                 </Badge>
               )}
             </p>
           </Col>
         </Row>
         <Row >
           <Col sm="12">
             <CardText className="font-weight-bold mb-0">{valueAmount}</CardText>
           </Col>
           {navLocation && linkText && <Col sm="12"><p ><Link style={{fontSize: '12px', width: 'fit-content'}} to={navLocation}>{linkText}</Link></p></Col>}
           {!navLocation && linkText && (
             <Col sm="12"> <p className="text-muted" style={{fontSize: '12px', width: 'fit-content'}}>{linkText}</p></Col>
           )}
         </Row>
       </CardBody>
     </Card>
      )}
    </>
  );
};

export default InfoCard;
