import FunnelGraph from 'funnel-graph-js';
import {getColumns, getColumnsETW} from './cellConfig';

const filterColumns = (columns, filter = []) => {
  if (filter.length < 1) return columns;
  return columns.filter((item, i) => !filter.includes(i));
};

export const getColumnsConfig = (data = {}, isEnterToWin = false) => {
  const filter = [];
  if (isEnterToWin) {
    if (String(data.ageVerif) === '0') filter.push(1);
    if (!data.winnerPhotoReq2)filter.push(3);
    return filterColumns(getColumnsETW(data), filter);
  }
  return filterColumns(getColumns(data), filter);
};


export const drawGraph = (chart, data) => {
  const length = chart.children?.length;
  for (let i = 0; i < length; i++) {
    chart?.children?.[0] && chart.removeChild(chart.children[0]);
  }
  const graph = new FunnelGraph({
    container: '.funnel-graph',
    gradientDirection: 'horizontal',
    data: data,
    direction: 'horizontal',
  });
  graph.draw();
};
