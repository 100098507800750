import moment from 'moment/moment';
import {DateTime as LuxDate} from 'luxon';
import Datetime from 'react-datetime';


const TimePicker = ({formik, name, label, onChange, ...restProps}) => {
  if (!formik || !name) return null;
  const error = formik.touched?.[name] && formik.errors?.[name];
  const valueStr = formik.getFieldProps(name).value;
  const value = valueStr ? moment(valueStr) : '';

  return (
    <div className="datePicker">
      {label && <label style={{display: 'block', width: '100%'}} htmlFor={name}>{label}</label> }
      <span className="datePickerIcon">
        <span className="zmdi zmdi-time"></span>
      </span>
      <Datetime
        inputProps={{disabled: restProps.disabled}}
        className={restProps.right ? 'right0' : 'left0'}
        value={value}
        onChange={value => {
          if (value.isValid) {
            const date = LuxDate.fromISO(value.toISOString()).toUTC();
            formik.setFieldValue(name, date.toISO());
          } else formik.setFieldValue(name, '');
          return onChange?.(value);
        }}
        dateFormat={false}
      />
      { error && (
        <div style={{color: 'red', fontSize: '12px'}}>{error}</div>
      )}
    </div>
  );
};

export default TimePicker;
