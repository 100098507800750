import Container from '../components/campaign/StepContainer';
import React from 'react';
import Overview from '../components/campaign/Overview';

const Step2 = (props) => {
  return (
    <div>
      {console.log('values', props.formik.values)}
      {props.open && (
        <>
          <h4 className="text-left m-0 mb-4">
						Let's begin setting up your offer
          </h4>
          <Container>
            <Overview
              open={props.open}
              formik={props.formik}
            />
          </Container>
        </>
      )}
    </div>
  );
};

export default Step2;
