import React, {useRef, memo} from 'react';
import {Editor} from '@tinymce/tinymce-react';
import FlipChange from '../../../../../../shared/components/FlipChange';

const TINY_MCE_API_KEY ='wc5vr320fj30gxf9ejbqgyetlbfb3dilkreqsu87ps5ln338';

const EditorHtml = ({formik, name, label, minHeight = 32}) => {
  if (!formik || !name) return <span>formik or name prop is undefined</span>;
  const editorRef = useRef(null);
  const data = formik?.values?.[name] || '';
  const onChange = e => formik.setFieldValue(name, e);

  return (
    <div className="EditorHtml">
      <div className="EditorHtmlBox">
        <div className="fieldBox">
          {label && <label htmlFor="">{label}</label>}
          <FlipChange>
            <div className="field" style={{minHeight}}>
              <Editor
                apiKey={TINY_MCE_API_KEY}
                inline={true}
                onInit={(evt, editor) => editorRef.current = editor}
                value={data}
                onEditorChange={onChange}
                init={{
                  menubar: true,
                  plugins: ['lists', 'charmap', 'preview', 'visualblocks', 'code', 'fullscreen'],
                  toolbar: [
                    'blocks undo redo code preview | removeformat',
                    'bold italic forecolor | alignleft aligncenter alignright alignjustify',
                    'bullist numlist outdent indent',
                  ],
                  // content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                }}
              />
            </div>
            <textarea
              className="sourceCode"
              style={{minHeight}}
              value={data}
              onChange={e => onChange(e.target.value)}
            />
          </FlipChange>
        </div>
      </div>
    </div>
  );
};

export default memo(EditorHtml);
