import {useEffect} from 'react';

const useResize = (cb) => {
  const resizeFn = () => cb(window.innerWidth);
  useEffect(() => {
    window.addEventListener('resize', resizeFn);
    window.addEventListener('gridReady', resizeFn);
    return () => {
      window.removeEventListener('resize', resizeFn);
      window.removeEventListener('gridReady', resizeFn);
    };
  }, []);
};

export default useResize;
