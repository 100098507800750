import React from 'react';
import {Input} from 'reactstrap';
import {joinClasses} from '../../../../../../utils';

const TextInput = ({formik, name, label, placeholder, classes = [], required}) => {
  const error = formik.touched?.[name] && formik.errors?.[name];
  return (
    <div className={joinClasses('inputText', ...classes)}>
      {label && <label htmlFor={name}>{label}</label>}
      <Input {...formik.getFieldProps(name)} placeholder={placeholder} invalid={error} />
      {error && <div style={{color: 'red'}}>{error}</div>}
    </div>
  );
};

export default TextInput;
