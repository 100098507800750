import {DateTime as luxDate} from 'luxon';
import sandglass from '../../assets/img/81838-sand-timer.gif';
import winner from '../../assets/img/111843-winner.gif';
import {winnerPeriodsCnf} from './OfferDetailsETW/config';

export const initFormData = {
  showFirstName: true,
  showLastName: true,
  showPhone: true,
  cashDisbursementMethod: '',
  campaignDateStart: luxDate.utc().toISO(),
  campaignDateEnd: luxDate.utc().toISO(),
  acceptedDateStart: luxDate.utc().toISO(),
  acceptedDateEnd: luxDate.utc().toISO(),
  winnerPeriodType: winnerPeriodsCnf.c,
  winnerSpecialPrize: false,
};

export const CAMPAIGN_TYPES = [
  {
    label: 'Special Offer',
    value: 'timedOffer',
    icon: sandglass,
    name: 'type',
  },
  {
    label: 'Enter to Win',
    value: 'enterWin',
    icon: winner,
    name: 'type',
  },
];
