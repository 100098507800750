import React from 'react';
import {Row, Col} from 'reactstrap';
import {DateTime as luxDate} from 'luxon';

const Overview = (props) => {
  const {formik} = props;
  const ageVerifCheck = () => {
    if (props.formik.values.ageVerif === 0) {
      return 'None';
    } else if (props.formik.values.ageVerif === 1) {
      return 'Acknowledge age';
    } else if (props.formik.values.ageVerif === 2) {
      return 'Enter birth date';
    } else return '';
  };

  return (
    <>
      <Row style={{marginBottom: '0px'}}>
        <Col>
          <h3
            className="text-primary"
            style={{
              textAlign: 'left',
              marginBottom: '0px',
            }}
          >
            Campaign Overview
          </h3>
        </Col>
        <Col className="text-right">
          <div
            className="btn btn-link btn-edit btn-sm"
            style={{textAlign: 'right', padding: '0px', margin: '0px'}}
            onClick={() => props.setHorizontalTabs('overview')}
          >
            <i className="zmdi zmdi-edit"></i> Edit
          </div>
        </Col>
      </Row>
      <div className="card mt-3" style={{padding: '20px'}}>
        {
          formik.values.type === 'timedOffer' && (
            <div className="row m-0">
              <div className="col-md-6 pl-0">
                <div className="form-row animated">
                  <label className="w-100 text-left">Campaign Type</label>
                  <p>{formik.values['offerType'] || ''}</p>
                </div>
              </div>
            </div>
          )
        }
        <div className="row m-0">
          <div className="col-md-6 pl-0">
            <div className="form-row animated">
              <label className="w-100 text-left">Campaign Name</label>
              <>{formik.values['campaignName'] || ''}</>
            </div>
          </div>
          <div className="col-md-6 pr-0">
            <div className="form-row animated">
              <label className="w-100 text-left">Campaign Age Gate</label>
              <p>{ageVerifCheck()}</p>
            </div>
          </div>
        </div>
        <div className="form-row animated">
          <label className="w-100 text-left">Campaign Summary</label>
          <p>{formik.values['description']}</p>
        </div>

        <div className="row m-0 form-row animated border-bottom-0">
          <div className="col-md-6 pl-0">
            <div className="text-left">
              <label className="w-100">Campaign Begins</label>
              <p>
                <i className="zmdi zmdi-calendar text-orange mr-1"></i>{' '}
                {luxDate.fromISO(formik.values?.campaignDateStart).toFormat('MM-dd-yyyy hh:mm a') || ''}
              </p>
            </div>
          </div>
          <div className="col-md-6 pr-0">
            <div className="text-left">
              <label className="w-100">Campaign Ends</label>
              <p>
                <i className="zmdi zmdi-calendar text-orange mr-1"></i>{' '}
                {luxDate.fromISO(formik.values?.campaignDateEnd).toFormat('MM-dd-yyyy hh:mm a') || ''}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
