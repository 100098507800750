import React from 'react';
import AuthService from '../services/AuthService2';

const authService = new AuthService();

const RenderWithRoles = ({validRoles = [], allowed, disabled}) => {
  const isAllowed = authService.canUserDo(validRoles);
  return (
    <>{isAllowed ? allowed : disabled}</>
  );
};

export default RenderWithRoles;
