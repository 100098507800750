import moment from 'moment';
import {DateTime as luxDate} from 'luxon';

const formatMessage = (diff = null) => (
	diff ? `${Math.floor(diff.asWeeks())} Weeks ${diff.days() % 7} Days` : 'Ended'
);

export const calculateCampaignEndDateTime = (campaignDateStart, campaignDateEnd) => {
  if (!campaignDateStart || !campaignDateEnd) return 'Missing dates';
  // validate dates
  const start = luxDate.fromISO(campaignDateStart);
  const end = luxDate.fromISO(campaignDateEnd);
  if (!end.isValid || !start.isValid) return 'Invalid dates';
  const diff = moment.duration(end.diff(start));
  if (diff._milliseconds < 0) return 'Invalid dates';
  // confirm campaign in progress
  const currentDate = luxDate.now().startOf('day');
  const startCurrentDiff = moment.duration(start.diff(currentDate));
  const endCurrentDiff = moment.duration(end.diff(currentDate));
  const isCampaignInProgress = (startCurrentDiff._data.days <= 0 && endCurrentDiff._data.days >= 0);
  // output resulting time span
  if (isCampaignInProgress) return formatMessage(endCurrentDiff);
  else if (startCurrentDiff._data.days > 0) return formatMessage(diff);
  return formatMessage();
};

export const handleOfferValueDisplay = (cashBackAmt, cashBackType, percentage) => {
  if (cashBackType === 'timedOffer') {
    return cashBackAmt.toLocaleString(
        'en-US',
        {
          style: 'currency',
          currency: 'USD',
        },
    );
  } else if (cashBackType === 'timeWindow') {
    return percentage + ' ' + '%';
  }
};
/**
 *
 * @param {*} number - The numberround to two decimal places
 * @param {*} percent - A boolean to determine if the number should be returned as a percentage
 */
export const safelyRoundTwoDecimals = (number, percent) => {
  // with research Math.round() is not safe to use for rounding numbers as many edge cases would have to be handled, this is a safer alternative
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/EPSILON
  if (!number) return 0;
  if (percent) {
    return Math.round((number + Number.EPSILON) * 100) / 100 + ' %';
  } else {
    return Math.round((number + Number.EPSILON) * 100) / 100;
  }
};
