import {Col, Container, Row} from 'reactstrap';
import DateTimePicker from 'shared/components/fields/DateTimePicker';
import {DatePicker, TimePicker} from '../../../shared/components/fields';
import React, {useEffect} from 'react';
import {DateTime as luxDate} from 'luxon';


export const WOTDateAndTime = ({formik}) => {
  useEffect(() => {
    if (!formik.values.campaignDateStart) return;
    const sd = luxDate.fromISO(formik.values.campaignDateStart);
    formik.setFieldValue('campaignDateTwo', sd.toUTC().toISO());
    formik.setFieldValue('campaignTimeStarter', sd.toUTC().toISO());
    if (!formik.values.campaignDateEnd) return;
    const ed = luxDate.fromISO(formik.values.campaignDateEnd);
    if (ed.day === sd.day) {
      formik.setFieldValue('campaignTimeEnder', sd?.set({hour: ed?.hour, minute: ed?.minute}).toISO());
    }
  }, [formik.isSubmitting]);

  const onDateChange = value => {
    if (!value.isValid) return;
    formik.setFieldValue('campaignDateStart', luxDate.fromISO(value.toISOString()).toUTC().toISO());
  };
  const onTimeStartChange = value => {
    if (!value.isValid) return;
    const sd = luxDate.fromISO(formik.getFieldProps('campaignDateTwo').value || luxDate.now().toISO());
    const st = luxDate.fromISO(luxDate.fromISO(value.toISOString()).toUTC().toISO());
    formik.setFieldValue('campaignDateStart', sd?.set({hour: st?.hour, minute: st?.minute}).toISO());
  };
  const onTimeEndChange = value => {
    if (!value.isValid) return;
    const sd = luxDate.fromISO(formik.getFieldProps('campaignDateStart').value || luxDate.now().toISO());
    const et = luxDate.fromISO(luxDate.fromISO(value.toISOString()).toUTC().toISO());
    formik.setFieldValue('campaignDateEnd', sd?.set({hour: et?.hour, minute: et?.minute}).toISO());
  };

  return (
    <>
      <Row>
        <Col>
          <DatePicker mb={6} label="Campaign Date" name="campaignDateTwo" formik={formik} onChange={onDateChange}/>
        </Col>
        <Col>
          <TimePicker mb={6} label="Begin Time" name="campaignTimeStarter" formik={formik}
            onChange={onTimeStartChange}/>
        </Col>
        <Col><TimePicker mb={6} label="End Time" name="campaignTimeEnder" formik={formik}
          onChange={onTimeEndChange}/></Col>
      </Row>
      <Container style={{visibility: 'hidden', height: '1px'}}>
        <Row className="dateAndTime">
          <Col>
            <DateTimePicker mb={6} name="campaignDateStart" formik={formik}/>
          </Col>
          <Col>
            <DateTimePicker mb={6} name="campaignDateEnd" formik={formik}/>
          </Col>
        </Row>
      </Container>
    </>
  );
};
