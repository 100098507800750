import * as yup from 'yup';
import {campaignTypeValidation} from '../components/campaign/CampaignType';
import {campaignOverviewValidation} from '../components/campaign/Overview';
import {campaignDetailsValidation} from './OfferDetails';
import {campaignDesignerValidation} from '../components/campaign/Designer';

// required to Draft
export const schemaCreateDraft = yup.object().shape({
  campaignName: yup.string().required('Required'),
  ...campaignTypeValidation,
  ...campaignDesignerValidation,
});

// required to Campaign
export const schemaCreateCampaign = yup.object().shape({
  ...campaignTypeValidation,
  ...campaignOverviewValidation,
  ...campaignDetailsValidation,
  ...campaignDesignerValidation,
});

export const compareFormValues = (prev = {}, current = {}) => JSON.stringify(prev) !== JSON.stringify(current);
// 'timeLimitHour', 'timeLimitMin'
export const findPushCampaignErrors = values => {
  const TYPE_KEY = 'cashBackType';
  const timedOfferFields = ['cashDisbursementMethod', 'proofOfPurchase', TYPE_KEY];
  const winnerTimesOfferFields = ['winnerTimes'];
  const errors = [];
  const required = {
    main: [
      'type',
      'campaignName',
      'ageVerif',
      'campaignDateStart',
      ...(values.type === 'timedOffer' ? timedOfferFields : []),
      ...(values.type === 'enterWin' ? winnerTimesOfferFields : []),
    ],
    timedOffer: ['cashBackAmt', 'campaignBudget'],
    timeWindow: ['averageAmount', 'percentage', 'campaignBudget'],
  };
  required.main.forEach(field => {
    const data = values[field];
    if (data !== 0 && !data) errors.push(field);
    if (field === 'winnerTimes' && values.type === 'enterWin' && data?.length < 1) {
      errors.push(field);
    }
  });

  // timedOffer
  required[values?.[TYPE_KEY]]?.forEach(field => {
    const data = values[field];
    if (data !== 0 && !data) errors.push(field);
  });
  console.log('errors', errors);
  return errors;
};
