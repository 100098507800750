import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import AuthService from 'shared/services/AuthService';
import FilterBar from './components/shared/FilterBar';

// reactstrap components
import {
  Row,
  Spinner,
} from 'reactstrap';
import {OrganizationDisplay} from './OrganizationDisplay';
import OrganizationService from 'shared/services/OrganizationService';
import {useQuery} from '@tanstack/react-query';
const Organization = (props) => {
  const [filteredOrg, setFilteredOrg] = useState([]);

  const orgService = new OrganizationService();
  const history = useHistory();
  const {isLoading: loading, error: errors, data: organization} = useQuery(['organizations'], () => orgService.getAllOrganizations());
  useEffect(() => {
    // Update the org
    if ( organization?.data?.length > 0) {
      setFilteredOrg(organization.data);
    }
  }, [organization]);

  // check login here
  const isLoggedIn = AuthService.isLoggedIn();
  if (!isLoggedIn) {
    history.push('/auth/login');
  }


  const setQuickFilter = (e) => {
    const value = e.target.value;
    let searchValue;
    if (value === 'Active') {
      searchValue = 1;
    } else if (value === 'Inactive') {
      searchValue = 0;
    } else {
      return setFilteredOrg(organization.data);
    }
    const filteredData = organization?.data?.filter((item) => {
      return item.isActive === searchValue;
    });
    setFilteredOrg(filteredData);
  };

  if (loading) {
    return <Spinner color="primary" className="page-loader" />;
  }
  if (errors) {
    console.error(errors);
    return <h1>Oops, Something happened!</h1>;
  }
  return (
    <>

      <div className='c-centered-row p-0 pr-4 filter-bar-wrapper'>
        <FilterBar navigateUrl={'/admin/organizations/create'} setQuickFilter={setQuickFilter} currentPage={'Organization'} dropdownOption={['All', 'Active', 'Inactive']}/>
      </div>

      <div className='m-auto p-0 '>
        <Row className='c-centered-row mt-0 pt-0'>
          {filteredOrg?.map((org, i) => {
            return <OrganizationDisplay
              key={i}
              organization={org} />;
          })}
        </Row>
      </div>

    </>
  );
};

export default Organization;
