import React from 'react';
import {Form} from './index';
import {ImgInput} from './fields';

const LogoForm = ({formik, onSubmit}) => {
  return (
    <div>
      <Form formik={formik} onSubmit={onSubmit}>
        <ImgInput
          hint="At least 100px / 100px and no larger than 400px / 400px"
          formik={formik}
          name="theLogo"
        />
      </Form>
    </div>
  );
};

export default LogoForm;
