import axiosOrigin from 'axios';
import {STORAGE_KEYS} from '../shared/services/config';
import {useEffect} from 'react';

export function authHeader() {
  const token = localStorage.getItem(STORAGE_KEYS.token);
  if (token) return `Bearer ${token}`;
  else return {};
}

export const joinClasses = (...classes) => {
  return classes.map(item => {
    const type = typeof item;
    if (type === 'string' || type === 'number') {
      return String(item).trim();
    }
    return '';
  }).join(' ');
};

export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return phoneNumberString;
};

export const axiosInstance = axiosOrigin.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: Infinity,
  headers: {Authorization: authHeader()},
});

export const usePerfectScrollbar = (platform) => useEffect(() => {
  if (navigator.platform.indexOf(platform) > -1) {
    document.documentElement.className += ' perfect-scrollbar-on';
    document.documentElement.classList.remove('perfect-scrollbar-off');
  }
  return () => {
    if (navigator.platform.indexOf(platform) > -1) {
      document.documentElement.className += ' perfect-scrollbar-off';
      document.documentElement.classList.remove('perfect-scrollbar-on');
    }
  };
}, []);

export const useScrollTop = dependencies => useEffect(() => {
  document.documentElement.scrollTop = 0;
  document.scrollingElement.scrollTop = 0;
}, dependencies);

export const convertQueryToObject = (url) => {
  const arr = url.slice(1).split(/&|=/);
  const params = {};

  for (let i = 0; i < arr.length; i += 2) {
    const key = arr[i];
    params[key] = arr[i + 1];
  }
  return params;
};

