import React, {useContext} from 'react';
import {
  Col,
  Card,
  CardBody,
  Row,
  CardFooter,
} from 'reactstrap';
import {Link, NavLink} from 'react-router-dom';
import {format, parseISO} from 'date-fns';
import {ChosenLocation} from '../contexts/RouterContext/ChosenLocation.js';

export const ClientDisplay = (props) => {
  const [chosenLocation, setChosenLocation] = useContext(ChosenLocation);
  const {client} = props;
  const clickHandler = () => {
    setChosenLocation({
      organization: chosenLocation.organization,
      client: client.id,
    });
  };
  return (
    <Col
      lg="4"
      md="6"
      sm="6"
      key={client.id.toString()}
      className="d-flex justify-content-center align-items-center"
    >
      <Card className="card-stats c-display-card-height">
        <CardBody className="p-0 c-font-fullhd">
          <div
            className={`${
                client.isActive ? 'active-card-header' : 'inactive-card-header'
            } container-fluid mb-2`}
          ></div>
          <Row className="mx-2 c-info-row-height">
            <Col
              md="6"
              xs="5"
              className="bg-white h-50 w-100 d-flex justify-content-center align-items-start"
            >
              {client.logo !== '' ? (
                  <img src={client.logo} alt={client.name} className="mh-100" />
                ) : (
                  <p>NO LOGO PROVIDED</p>
                )}
            </Col>
            <Col
              md="6"
              xs="7"
              className="d-flex flex-column justify-content-start align-items-center h-50 bg-white"
            >
              <p className="text-center inline font-weight-bold">
                {client.name}
              </p>
              <div className="c-light-gray rounded-pill d-flex justify-content-center container">
                <p className="text-center font-italic m-auto c-variable-font">
                    Created:{' '}
                  {format(
                      new Date(parseISO(client.dateCreated)),
                      'MM-dd-yyyy',
                  )}
                </p>
              </div>
            </Col>
            <div className="bg-light w-100 h-50 d-flex mt-2">
              <div className="bg-white w-50 h-100">
                <p className="text-center">Active Campaigns</p>
                <p className="text-center">{client.activeCampaign}</p>
              </div>
              <div className="bg-white w-50 h-100">
                <p className="text-center">Past Campaigns</p>
                <p className="text-center">{client.pastCampaign}</p>
              </div>
            </div>
          </Row>
        </CardBody>
        <CardFooter className="p-0 c-footer-height">
          <hr className="m-0" />
          <div className="w-100 h-100 d-flex">
            <div className="w-50 h-100 d-flex c-button-hover">
              <NavLink
                onClick={() => clickHandler()}
                to="/admin/clients/campaigns"
                className="w-100 h-100 border-right d-flex justify-content-center align-items-center c-button-link"
              >
                  Campaigns
              </NavLink>
            </div>
            <div className="w-50 h-100 d-flex c-button-hover">
              <Link
                to={'./clientprofile/' + client.id}
                className="w-100 h-100 d-flex justify-content-center align-items-center c-button-link"
              >
                  Account
              </Link>
            </div>
          </div>
        </CardFooter>
      </Card>
    </Col>
  );
};
