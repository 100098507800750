import Overview from './Overview';
import Details from './Details';
import Methods from './Methods';
import Triggers from './Triggers';

import './style.scss';

const Review = ({formik, setHorizontalTabs}) => {
  return (
    <>
      <div className="content">
        <Overview formik={formik} setHorizontalTabs={setHorizontalTabs}/>
        <Details formik={formik} setHorizontalTabs={setHorizontalTabs}/>
        {
          formik.values.type === 'timedOffer' && (
            <Methods formik={formik} setHorizontalTabs={setHorizontalTabs}/>
          )
        }
        <Triggers formik={formik} setHorizontalTabs={setHorizontalTabs}/>
      </div>
    </>
  );
};

export default Review;
