import React, {useContext, useEffect, useState} from 'react';
import Form from './Form';
import {useMutation} from '@tanstack/react-query';
import {Spinner} from 'reactstrap';
import CampaignService from '../../shared/services/CampaignService';
import {ChosenLocation} from '../../contexts/RouterContext/ChosenLocation';
import {schemaCreateCampaign} from './formValidation';

const {
  getCampaignByID,
  getEmailTriggers,
  getLinkTrigger,
  getTextTrigger,
  getQrTrigger,
  updateCampaign,
  publishCampaign,
} = new CampaignService();


const UpdateCampaign = () => {
  const [location] = useContext(ChosenLocation);
  const {client, campaign: campaignId} = location;
  const [initialValues, setInitialValues] = useState({});
  const [successMessage, setSuccessMessage] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const {mutate: getEmail, isLoading: loadingEmail} = useMutation(getEmailTriggers, {
    onSuccess: (data) => setInitialValues(prev => ({...prev, emails: data.data})),
  });

  const {mutate: getLink, isLoading: loadingLink} = useMutation(getLinkTrigger, {
    onSuccess: (data) => setInitialValues(prev => ({...prev, links: data.data})),
  });

  const {mutate: getTextT, isLoading: loadingText} = useMutation(getTextTrigger, {
    onSuccess: (data) => setInitialValues(prev => ({...prev, texts: data.data})),
  });

  const {mutate: getQr, isLoading: loadingQr} = useMutation(getQrTrigger, {
    onSuccess: (data) => setInitialValues(prev => ({...prev, texts: data.data})),
  });

  const {mutate: getCampaign, isLoading: isLoadingCampaign} = useMutation(
      getCampaignByID,
      {
        onSuccess: (data) => {
          setInitialValues(prev =>({...prev, ...data.data}));
          getEmail({campaignId});
          getLink({campaignId});
          getTextT({campaignId});
          getQr({campaignId});
        },
      },
  );

  const {mutateAsync: onUpdate} = useMutation(updateCampaign, {
    onSuccess: (data) => {
      setInitialValues(prev =>({...prev, ...data?.data}));
      setSuccessMessage('Campaign updated successfully!');
      return true;
    },
    onError: () => {
      setErrorMessage('Update Campaign Failed!');
    },
  });

  const {mutateAsync: onPublishSubmit} = useMutation(publishCampaign, {
    onSuccess: (data) => {
      setSuccessMessage('Campaign successfully published!');
      setInitialValues(prev =>({...prev, ...data?.data}));
    },
    onError: () => {
      setErrorMessage('Publish Campaign Failed!');
    },
  });

  useEffect(() => {
    if (campaignId && client) getCampaign({id: campaignId, clientId: client});
    return () => localStorage.removeItem('tabKey');
  }, []);

  const loaded = !loadingEmail && !loadingEmail && !loadingText && !loadingLink && !loadingQr;
  const isLoadedCamp = !isLoadingCampaign;

  const onSubmit = (v, fh, isPublishSubmit) => {
    setSuccessMessage(null);
    setErrorMessage(null);
    if (isPublishSubmit) return onPublishSubmit(v, fh);
    else return onUpdate(v, fh);
  };

  return (
    <>
      {
        loaded && isLoadedCamp ? (
          <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={schemaCreateCampaign}
            isEdit={true}
            initTabKey="overview"
            successMessage={successMessage}
            errorMessage={errorMessage}
          />
        ) : (
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          }}>
            <Spinner color="primary" style={{width: '50px', height: '50px'}}/>
          </div>
        )
      }
    </>
  );
};

export default UpdateCampaign;
