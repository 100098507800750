import React from 'react';
import 'react-color-palette/lib/css/styles.css';
import {Modal} from 'reactstrap';
import {joinClasses} from '../../../../../../utils';

import './style.scss';

const ModalAlert = ({isOpen, hideModal, children}) => {
  return (
    <div onClick={hideModal} className={joinClasses('colorPikerModal')}>
      <Modal className="colorPikerModalWrap" isOpen={isOpen} toggle={hideModal} >
        {children}
      </Modal>
    </div>
  );
};

export default ModalAlert;
