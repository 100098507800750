/* !

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import classnames from 'classnames';
import {NavLink} from 'react-router-dom';
import a6new from 'assets/img/a6new.png';
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
} from 'reactstrap';

const AuthNavbar = (props) => {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [color, setColor] = React.useState('navbar-transparent');
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened

  return (
    <Navbar
      className={classnames('navbar-absolute fixed-top', color)}
      expand="lg"
    >
      <Container>
        <a className="navbar-brand" href="#">
          <img src={a6new} />
        </a>

      </Container>
    </Navbar>
  );
};

export default AuthNavbar;
