import {winnerPeriodsCnf} from './config';
import {DateTime, Interval} from 'luxon';

function getDays(start, end) {
  const intervalConsumption = Interval.fromDateTimes(DateTime.fromISO(start), DateTime.fromISO(end));
  const interval = intervalConsumption.splitBy({days: 1});
  return interval.map(item => item.toISODate());
}

function getWeeks(start, end) {
  const intervalConsumption = Interval.fromDateTimes(DateTime.fromISO(start), DateTime.fromISO(end));
  const interval = intervalConsumption.splitBy({weeks: 1});
  return interval.map(item => item.toISODate());
}

function getMonth(start, end) {
  const intervalConsumption = Interval.fromDateTimes(DateTime.fromISO(start), DateTime.fromISO(end));
  const interval = intervalConsumption.splitBy({month: 1});
  return interval.map(item => item.toISODate());
}

function getEndOgCampaign(start, end) {
  return [`${DateTime.fromISO(end).toISODate()}/${DateTime.fromISO(end).toISODate()}`];
}

export function getTimingList({period, start, end}) {
  if (!period || !start || !end) return [];
  if (period === winnerPeriodsCnf.d) return getDays(start, end);
  if (period === winnerPeriodsCnf.w) return getWeeks(start, end);
  if (period === winnerPeriodsCnf.m) return getMonth(start, end);
  if (period === winnerPeriodsCnf.c) return [''];
  if (period === winnerPeriodsCnf.e) return getEndOgCampaign(start, end);
}

export function getNewValues(periods = []) {
  return periods.map(item => ({
    date: item?.split('/')?.[1] || null,
    amount: null,
  }));
}
