import * as React from 'react';
import {Range, getTrackBackground} from 'react-range';
import {useState} from 'react';
import {useEffect} from 'react';
import {BARS_COUNT} from './const';

let timerID;
const RangePeriod = (props) => {
  const STEP = 1;
  const MIN = props.min || 0;
  const maxInit = props.max || BARS_COUNT;
  const MAX = maxInit > MIN ? maxInit : maxInit + 1;
  const [values, setValues] = useState([MIN, MAX]);

  const updateValues = (values) => {
    if (values[1] - values[0] >= STEP) {
      clearTimeout(timerID);
      setValues([...values]);
      timerID = setTimeout(()=> {
        props.onChange(values[0], values[1]);
      }, 500);
    }
  };

  useEffect(() => props.onChange(MIN, MAX), []);

  return (
    <div style={{padding: '0 0px', fontSize: '10px'}}>
      <div style={{textAlign: 'center'}}>
        <span>Range user by time: </span>
        <span>{values[0]}</span>
        <span> - </span>
        <span>{values[1]}</span>
        <span> (minutes)</span>
      </div>
      <Range
        step={STEP}
        min={MIN}
        max={MAX}
        style={{
          height: '15px',
          display: 'flex',
          width: '100%',
        }}
        values={values}
        onChange={(values) => updateValues([...values])}
        renderTrack={({props, children}) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: '15px',
              display: 'flex',
              width: '100%',
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: '5px',
                width: '100%',
                borderRadius: '4px',
                background: getTrackBackground({
                  values,
                  colors: ['#ccc', '#548BF4', '#ccc'],
                  min: MIN,
                  max: MAX,
                }),
                alignSelf: 'center',
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({props, isDragged}) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '14px',
              width: '14px',
              borderRadius: '50%',
              backgroundColor: '#FFF',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0px 2px 6px #AAA',
            }}
          >
            <div
              style={{
                height: '8px',
                width: '2px',
                backgroundColor: isDragged ? '#548BF4' : '#CCC',
              }}
            />
          </div>
        )}
      />
    </div>
  );
};

export default RangePeriod;
