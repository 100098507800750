import moment from 'moment';

export const comparatorString = (valueA, valueB) => {
  const valueALower = valueA.toLowerCase().trim();
  const valueBLower = valueB.toLowerCase().trim();
  if (valueA === valueB) return 0;
  return valueALower.localeCompare(valueBLower, 'en', {numeric: true});
};

export function getTotalTime(params) {
  if (params.data.timeToComplete === 'Incomplete') {
    return params.data.timeToComplete;
  }
  // start time and end time
  const startTime = moment(params.data.landingTime);
  const endTime = moment(params.data.completedTime);

  // calculate total duration
  const duration = moment.duration(endTime.diff(startTime));

  // duration in hours
  const hours = parseInt(duration.asHours());
  // duration in minutes
  const minutes = parseInt(duration.asMinutes()) % 60;
  return hours + ' hrs ' + minutes + ' minutes.';
}

export const formatFilteredOption = (option) => {
  return option.toString().replace(/,/g, '|');
};

export function getPercent(rowData, participants) {
  const v = Math.floor((rowData?.length / participants?.length) * 100);

  return isNaN(v) ? 0 : v;
}
