import React from 'react';

import './style.scss';

const Form = ({formik, children}) => {
  if (!formik) return <div>Required props: formik!</div>;
  // console.log('formik?.values', formik?.values);
  return (
    <div>
      <div className="designerForm">
        <div className="animatedBox">{children}</div>
        {/* <div className="designerFormActionBox">*/}
        {/*  <Button disabled={formik.isSubmitting} className="designerFormActionButton">Cancel</Button>*/}
        {/*  <Button disabled={formik.isSubmitting} onClick={formik.handleSubmit} className="designerFormActionButton">*/}
        {/*    <span>Save</span>*/}
        {/*    {*/}
        {/*      formik.isSubmitting && (*/}
        {/*        <span className="designerFormActionButtonSpinner">*/}
        {/*          <Spinner*/}
        {/*            color="#ffffff"*/}
        {/*            className="page-loader"*/}
        {/*            style={{width: '8px', height: '8px', fontSize: '4px'}}*/}
        {/*          />*/}
        {/*        </span>*/}
        {/*      )*/}
        {/*    }*/}
        {/*  </Button>*/}
        {/* </div>*/}
      </div>
    </div>
  );
};

export default Form;


