import React, {useEffect, useContext} from 'react';
import {CardGroup, Row, Col, Card, Spinner} from 'reactstrap';
import {ChosenLocation} from 'contexts/RouterContext/ChosenLocation';
import {useQuery, useMutation} from '@tanstack/react-query';
import CampaignService from '../../shared/services/CampaignService';
import CampaignFunnelGraph from './CampaignFunnelGraph';
import AverageTimeChart from './AverageTimeChart';
import RedemptionZone from '../components/table/RedemptionZone';
import './style.scss';
import Header from './Header';
import HeaderEWT from './HeaderEWT';

const CampaignDetails = () => {
  const [chosenLocation] = useContext(ChosenLocation);
  const clientId = chosenLocation.client;
  const campaignId = chosenLocation.campaign;
  const campaignService = new CampaignService();
  const colWrapRef = React.useRef(null);

  const {mutate: getCampaign} = useMutation(campaignService.getCampaignByID);

  const {data: campaignData, isLoading} = useQuery(
      ['campaign_report', campaignId],
      () => campaignService.getCampaignReport(campaignId),
      {refetchOnWindowFocus: false},
  );

  const {data: redemptionZoneData} = useQuery(
      ['redemptionZone', campaignId],
      () => campaignService.getRedemptionZone(campaignId),
      {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {},
      },
  );

  useEffect(() => {
    if (campaignId) getCampaign({id: campaignId, clientId: clientId});
  }, [campaignId]);

  if (isLoading) return <Spinner color="primary" />;
  return (
    <div className="container-fluid">
      { campaignData?.data?.type === 'timedOffer' && <Header campaignData={campaignData?.data}/>}
      { campaignData?.data?.type === 'enterWin' && <HeaderEWT campaignData={campaignData?.data}/>}
      <CardGroup>
        <div className="mx-2" style={{width: '100%'}}>
          <CampaignFunnelGraph
            width={colWrapRef?.current?.clientWidth}
            campaignData={campaignData?.data || undefined}
            loading={isLoading}
          />
        </div>
      </CardGroup>
      <Row>
        <Col cxs="6">
          <Card className="m-2">
            <AverageTimeChart />
          </Card>
        </Col>
        <Col xs="6">
          <RedemptionZone data={redemptionZoneData?.data} />
		    </Col>
	    </Row>
    </div>
  );
};

export default CampaignDetails;
