import {Col, Row} from 'reactstrap';
import React from 'react';

const TitleRow = () => {
  return (
    <Row>
      <Col xs="5">
        <h6 className="designerWrapperTitle">Components</h6>
      </Col>
      <Col xs="7">
        <h6 className="designerWrapperTitle">Options</h6>
      </Col>
    </Row>
  );
};
export default TitleRow;
