import React from 'react';
import {Form} from './index';
import {EditorHtml} from './fields';
import {CampaignDetails} from './index';


const PersonalInfo = ({formik, onSubmit}) => {
  return (
    <div>
      <Form formik={formik} onSubmit={onSubmit}>
        <EditorHtml label="Heading" formik={formik} name="personalInfoHeading" />
        <EditorHtml label="Description" formik={formik} name="personalInfoDescription" minHeight="80px" />
        <hr/>
        <CampaignDetails formik={formik}/>
      </Form>
    </div>
  );
};

export default PersonalInfo;
