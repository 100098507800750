import React from 'react';
import {useMutation} from '@tanstack/react-query';
import CampaignService from '../../../../shared/services/CampaignService';
import {
  Button,
  Input,
  Row,
  Col,
} from 'reactstrap';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCopy} from '@fortawesome/free-regular-svg-icons';

const Link = (props) => {
  const {index, formik, item, campaignId} = props;
  const campaignService = new CampaignService();

  const {mutate: deleteLink, isLoading: isLoadingDeleteLink} = useMutation(
      campaignService.deleteLinkTrigger,
      {
        onSuccess: (data) => {
          const currLinks = formik.values['links'];
          const remLinks = currLinks.filter(
              (link) => link.linkID !== data.data.linkID,
          );
          formik.setFieldValue('links', remLinks);
        },
        onError: (error) => {
          console.log('error', error);
        },
      },
  );

  const {mutate: updateLink} = useMutation(
      campaignService.updateLinkTrigger,
      {
        onSuccess: (data) => {
          console.log(data);
        },
        onError: (error) => {
          console.log('error', error);
        },
      },
  );

  React.useEffect(() => {
    const link = formik.values[`links`][index];
    // updated values so udpate the database?
    updateLink({...link, campaignID: campaignId});
  }, [formik.values[`links`][index]]);

  return (
    <Row>
      <Col sm={4}>
        <Input
          type="text"
          name={`links[${index}].linkName`}
          value={item?.linkName}
          defaultValue={item?.linkName}
          onChange={formik.handleChange}
          className={'link-entry'}
          placeholder="Name of link..."
        ></Input>
      </Col>
      <Col sm={4}>
        <Input
          type="text"
          className={'link-entry'}
          name="linkAddress"
          disabled
          defaultValue={`${item?.linkAddress}/${item?.linkID}`}
        />
      </Col>
      <Col sm={4}>
        <Button
          disabled={isLoadingDeleteLink}
          className="btn btn-danger button"
          style={{'margin-top': '0px'}}
          onClick={() => {
            deleteLink({...item, campaignID: props.campaignId});
          }}
        >
          {isLoadingDeleteLink && (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          {!isLoadingDeleteLink && 'X'}
        </Button>
      </Col>
    </Row>
  );
};

export default Link;
