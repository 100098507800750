export const winnerPeriodsCnf = {
  d: 'DAILY',
  w: 'WEEKLY',
  m: 'MONTHLY',
  c: 'CUSTOM',
  e: 'END_CAMPAIGN',
};
export const winnerPeriods = [
  {label: 'End of campaign', value: winnerPeriodsCnf.e},
  {label: 'Daily', value: winnerPeriodsCnf.d},
  {label: 'Weekly', value: winnerPeriodsCnf.w},
  {label: 'Monthly', value: winnerPeriodsCnf.m},
  {label: 'Custom', value: winnerPeriodsCnf.c},
];

