import React from 'react';
import PerfectScrollbar from 'perfect-scrollbar';
import {Switch} from 'react-router-dom';
import AuthNavbar from 'components/Navbars/AuthNavbar.js';
import {getRoutes} from '../routes';

let ps;

function AuthLayout() {
  const fullPages = React.useRef();
  React.useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(fullPages.current);
    }
    return function cleanup() {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
      }
    };
  });
  return (
    <>
      <AuthNavbar />
      <div className="wrapper wrapper-full-page" ref={fullPages}>
        <div className="full-page section-image">
          <Switch>{getRoutes('/auth', false)}</Switch>
        </div>
      </div>
    </>
  );
}

export default AuthLayout;
