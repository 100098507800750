import React from 'react';
import {Row, Col, NavLink} from 'reactstrap';

const Triggers = (props) => {
  const {formik, setSubItem} = props;
  return (
    <>
      <Row style={{marginBottom: '0px'}}>
        <Col>
          <h3
            className="text-primary"
            style={{
              textAlign: 'left',
              marginBottom: '0px',
            }}
          >
            Triggers
          </h3>
        </Col>
        <Col className="text-right">
          <div
            href="create-timed-offer01.html"
            className="btn btn-link btn-edit btn-sm"
            style={{textAlign: 'right', padding: '0px', margin: '0px'}}
            onClick={() => props.setHorizontalTabs('trigger')}
          >
            <i className="zmdi zmdi-edit"></i> Edit
          </div>
        </Col>
      </Row>
      <div className="card mt-4" style={{padding: '20px'}}>
        <ul className="list-group">
          <li className="list-group-item">
            <p>
              A unique QR code for{' '}
              <strong>{(formik.values['qr'] ?? []).length}</strong> locations
            </p>
            <p className="small">
              <NavLink style={{cursor: 'pointer'}} onClick={() => {
                props.setHorizontalTabs('trigger'), setSubItem('qr');
              }}>View/Edit</NavLink></p>
          </li>
          <li className="list-group-item">
            <p>
              <strong>{(formik.values['emails'] ?? []).length}</strong> Emails
            </p>
            <p className="small">
              <NavLink style={{cursor: 'pointer'}} onClick={() => {
                props.setHorizontalTabs('trigger'), setSubItem('emails');
              }}>View/Edit</NavLink>
            </p>
          </li>
          <li className="list-group-item">
            <p>
              <strong>{(formik.values['texts'] ?? []).length}</strong> Text
              Messages
            </p>
            <p className="small">
              <NavLink style={{cursor: 'pointer'}} onClick={() => {
                props.setHorizontalTabs('trigger'), setSubItem('texts');
              }}>View/Edit</NavLink></p>
          </li>
          <li className="list-group-item">
            <p>
              <strong>{(formik.values['links'] ?? []).length}</strong> Link
            </p>
            <p className="small">
              <NavLink style={{cursor: 'pointer'}} onClick={() => {
                props.setHorizontalTabs('trigger'), setSubItem('links');
              }}>View/Edit</NavLink></p>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Triggers;
