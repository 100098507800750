import React, {useState} from 'react';
import {Input} from 'reactstrap';
import ModalAlert from '../ModalAlert';
import {ColorPicker, useColor} from 'react-color-palette';

const ColorInput = ({formik, name, label}) => {
  if (!formik || !name) return null;
  const [color, setColor] = useColor('hex', formik?.values?.[name] || '#121212');
  const [isOpen, setIsOpen] = useState(false);
  const showModal = () => setIsOpen(true);
  const hideModal = () => {
    formik.setFieldValue(name, color.hex);
    setTimeout(() => setIsOpen(false), 100);
  };

  return (
    <div className="textColorInput">
      <label htmlFor={name}>{label}</label>
      <div className="box">
        <span className="preview" onClick={showModal} style={{backgroundColor: formik?.values?.[name]} || 'transparent'}/>
        <ModalAlert isOpen={isOpen} hideModal={hideModal}>
          <ColorPicker width={300} height={200} color={color} onChange={setColor} hideRGB hideHSV dark />
        </ModalAlert>
        <Input
          name={name}
          placeholder="hex color"
          {...formik.getFieldProps(name)}
        />
      </div>
    </div>
  );
};

export default ColorInput;
