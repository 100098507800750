import React from 'react';
import {DateAndTime} from '../OfferDetails/DateAndTime';
import FormSection from '../../../shared/components/FormSection';
import {SwitchInput} from '../../../shared/components/fields';
import Winners from './Winners';

import './style.scss';


export const campaignDetailsValidationETW = {};

const OfferDetailsETW = (props) => {
  const formik = props.formik;

  return (
    <div className="offerDetailsETW">
      <FormSection label="Campaign Dates">
        <DateAndTime formik={formik} bottom/>
      </FormSection>
      <br/>
      <FormSection label="Winners">
        <Winners formik={formik}/>
      </FormSection>
      <br/>
      <FormSection label="Submissions" split>
        <div>
          <div>Single Entry</div>
          <div className="offerSubmissions">
            <h6>Require photo?</h6>
            <SwitchInput
              name="winnerPhotoReq1"
              formik={formik}
            />
          </div>
        </div>
        <div>
          <div>Secondary Entry</div>
          <div className="offerSubmissions">
            <h6>Allow for secondary entry?</h6>
            <SwitchInput
              name="winnerPhotoReq2"
              formik={formik}
            />
          </div>
        </div>
      </FormSection>
    </div>
  );
};

export default OfferDetailsETW;
