import React from 'react';
import UserCard from './UserCard';
import {Button, Col, Row, Spinner} from 'reactstrap';
import {useQuery} from '@tanstack/react-query';
import {Link} from 'react-router-dom';
import {userService} from './api';
import RenderWithRoles from '../../shared/components/RenderWithRoles';
import {ROLES} from '../../routes/config';

const UserList = () => {
  const {isLoading, error, data} = useQuery(['getUsers'], () => userService.getList(), {cacheTime: 0});
  const users = Array.isArray(data?.data) ? data?.data : [];

  return (
    <div className="sectionBox">
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <h5>Users</h5>
        <RenderWithRoles
          validRoles={[ROLES.platform]}
          allowed={(
            <Link to="/admin/users/create">
              <Button color="primary">Create user</Button>
            </Link>
          )}
        />
      </div>
      {error && <div>Something went wrong</div>}
      {
        isLoading ? (
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Spinner color="primary" className="page-loader"/>
          </div>
        ) : (
          <Row>
            {
              users?.map(item => (
                <Col xl="4" lg="6" md="6" sm="6" key={item.id}>
                  <UserCard data={item} isActive={item.status?.toLowerCase() === 'active'}/>
                </Col>
              ))
            }
          </Row>
        )
      }
    </div>
  );
};

export default UserList;
