import React from 'react';
import {Form} from './index';
import {ColorInput, ImgInput} from './fields';

const BackgroundForm = ({formik, onSubmit}) => {
  return (
    <div>
      <Form formik={formik} onSubmit={onSubmit}>
        <ColorInput label="Background Color" formik={formik} name="backgroundColor" />
        <ImgInput
          hint="At least 1000px / 1000px and no larger than 4000px / 4000px"
          formik={formik}
          name="backgroundImage"
          label="Background Image"
        />
      </Form>
    </div>
  );
};

export default BackgroundForm;
