import Container from '../components/campaign/StepContainer';
import React from 'react';
import Trigger from '../components/campaign/Trigger';

const Step5 = (props) => {
  return (
    <div>
      {props.open && (
        <Container>
          <Trigger
            campaignId={props.campaignId}
            formik={props.formik}
            subItem={props.subItem}
          />
          {!props.campaignId && (
            <div className="disableForm">
              <h3>You need to save draft first</h3>
            </div>
          )}
        </Container>
      )}
    </div>
  );
};

export default Step5;
