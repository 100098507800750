import React from 'react';
import {Spinner} from 'reactstrap';
import {useQuery} from '@tanstack/react-query';
import {useParams} from 'react-router-dom';
import ProfileHeader from './ProfileHeader';
import UserProfileBody from './UserProfileBody';
import {userService} from './api';


const UserProfile = () => {
  const {id} = useParams();
  const {isLoading, error, data} = useQuery(['getUser'], () => userService.getUser(id), {cacheTime: 0});
  const user = data?.data || {};

  return (
    <div className="sectionBox">
      {isLoading ? (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Spinner color="primary" className="page-loader"/>
        </div>
      ) : (
        <>
          {
            error ? (<div>Something went wrong</div>) : (<div className="userProfile">
              <ProfileHeader data={user}/>
              <br/>
              <UserProfileBody data={user}/>
            </div>)
          }
        </>
      )}
    </div>
  );
};

export default UserProfile;
