import {DateTime as luxDate} from 'luxon';
import Datetime from 'react-datetime';
import moment from 'moment/moment';

const DatePicker = ({formik, name, label, mb, onChange, ...restProps}) => {
  if (!formik || !name) return null;
  const error = formik.touched?.[name] && formik.errors?.[name];
  const valueStr = formik.getFieldProps(name).value;
  const value = valueStr ? moment(valueStr) : '';

  return (
    <div className="datePicker" style={{marginBottom: mb}}>
      {label && <label style={{display: 'block', width: '100%'}} htmlFor={name}>{label}</label> }
      <span className="datePickerIcon">
        <span className="zmdi zmdi-calendar"></span>
      </span>
      <Datetime
        inputProps={{disabled: restProps.disabled}}
        className={restProps.right ? 'right0' : 'left0'}
        value={value}
        onChange={value => {
          if (value.isValid) {
            const date = luxDate.fromISO(value.toISOString()).toUTC();
            formik.setFieldValue(name, date.toISO());
          } else formik.setFieldValue(name, '');
          return onChange?.(value);
        }}
        timeFormat={false}
      />
      { error && (
        <div style={{color: 'red', fontSize: '12px'}}>{error}</div>
      )}
    </div>
  );
};

export default DatePicker;
