import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';
import {Prompt, useHistory} from 'react-router-dom';
import {useState} from 'react';

const message = {
  title: 'Do you want to leave the Campaign Creator?',
  text: 'Changes you made will not be saved!',
};

const ignoreList = [
  '/admin/clients/campaigns/edit',
];

const Warning = ({isDifferent}) => {
  const [showWarning, setShowWarning] = useState(false);
  const [locationCash, setLocationCash] = useState(null);
  const history = useHistory();

  const okCancel = () => setShowWarning(false);
  const onOk = () => {
    setShowWarning(false);
    history.push(locationCash);
  };
  const onLeave = location => {
    if (ignoreList.includes(location.pathname)) return true;
    setShowWarning(true);
    setLocationCash(location.pathname);
    return false;
  };

  return (
    <div className="warning">
      <Prompt
        when={!showWarning && isDifferent}
        message={onLeave}
      />
      <Modal className="warningModal" isOpen={showWarning}>
        <ModalHeader>{message.title}</ModalHeader>
        <ModalBody>{message.text}</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={okCancel}>Cancel</Button>
          <Button color="primary" onClick={onOk}>Leave</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Warning;
