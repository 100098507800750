import React from 'react';
import {Card, CardBody} from 'reactstrap';

const Container = (props) => {
  return (
    <Card className="step-container" style={{padding: '15px'}}>
      <CardBody>
        {props.children}
      </CardBody>
    </Card>

  );
};

export default Container;
