import React from 'react';
import {Card, CardBody, CardTitle} from 'reactstrap';
import {NavLink} from 'react-router-dom';
import {joinClasses} from '../../utils';
import {ROLES} from '../../routes/config';
import RenderWithRoles from '../../shared/components/RenderWithRoles';
import {CutLongText} from '../../shared/components';

const UserCard = ({data, isActive}) => {
  const {id, firstName, lastName, phone, email, image} = data;

  return (
    <div className="userCardWrap">
      <Card className={joinClasses('userCard', isActive && '_active')}>
        <CardBody>
          <div className="cardHeader">
            <CardTitle tag="h6">{`${firstName} ${lastName}`}</CardTitle>
            <div className="cardLogo">
              {
                  image ?
                    <img className="cardLogoImg" src={image} alt="Logo"/> :
                    <div className="cardLogoImg">{firstName?.[0]}</div>
              }
            </div>
          </div>
          <div className="cardDesc">
            <ul>
              <li>
                <span className="cardDescTitle">Phone:</span>
                <span>{phone || '+1 ( ___ ) ___ ___'}</span>
              </li>
              <li>
                <CutLongText>
                  <span className="cardDescTitle">Email:</span>
                  <span>{email}</span>
                </CutLongText>
              </li>
            </ul>
          </div>
        </CardBody>
        <RenderWithRoles
          validRoles={[ROLES.platform]}
          allowed={(
            <footer className="cardFooter">
              <NavLink to={`/admin/users/profile/${id}`}>View</NavLink>
              <NavLink to={`/admin/users/edit/${id}`}>Edit</NavLink>
            </footer>
          )}
          disabled={(
            <footer className="cardFooter cardFooterNoEdit">
              <NavLink to={`/admin/users/profile/${id}`}>View</NavLink>
            </footer>
          )}
        />
      </Card>
    </div>
  );
};


export default UserCard;
