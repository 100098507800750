import React from 'react';
import CustomRadioInput from '../../../shared/components/fields/CustomRadioInput';
import {SunFill, CalendarCheck, HourglassSplit} from 'react-bootstrap-icons';
import {DateAndTime} from './DateAndTime';
import {TimeLimit} from './TimeLimit';
import {WOTDateAndTime} from './WOTDateAndTime';

const fieldName = 'offerTimingType';

export const OfferTiming = ({formik}) => {
  return (
    <div className="offerTiming">
      <label className='form-row animated font-weight-bold'>Offer Timing</label>
      <div>
        <CustomRadioInput
          formik={formik}
          name={fieldName}
          text="Standard Sunset"
          icon={<SunFill size={50} color="orange"/>}
          value="standardSunset"
        >
          <div>
            <DateAndTime formik={formik}/>
          </div>
        </CustomRadioInput>
        <CustomRadioInput
          formik={formik}
          name={fieldName}
          text="Timed Offer"
          icon={<HourglassSplit size={50} color="#fef59b"/>}
          value="timedOffer"
        >
          <div>
            <DateAndTime formik={formik}/>
            <br/>
            <TimeLimit formik={formik}/>
          </div>
        </CustomRadioInput>
        <CustomRadioInput
          formik={formik}
          name={fieldName}
          text="Window of Time"
          icon={<CalendarCheck size={50} color="#1798D9"/>}
          value="windowOfTime" >
          <div>
            <WOTDateAndTime formik={formik}/>
          </div>
        </CustomRadioInput>
      </div>
    </div>
  );
};
