import React from 'react';
import {joinClasses} from '../../../utils';
import {Input} from 'reactstrap';

const CheckboxInput = ({formik, name, label, labelEnd, specialValues, ...restProps}) => {
  if (!formik || !name) return null;
  const error = formik.touched?.[name] && formik.errors?.[name];
  const isOn = formik.values[name] === specialValues?.['true'];

  const onChange = () => {
    const v = !(specialValues ? isOn : formik.values[name]);
    formik.setFieldValue(name, specialValues ? specialValues[String(v)] : v);
  };

  return (
    <div className={joinClasses('checkboxInput')}>
      {label && !labelEnd && <label htmlFor={name}>{label}</label>}
      <Input
        {...restProps}
        name={name}
        onColor="success"
        offColor="primary"
        onChange={onChange}
        checked={specialValues ? isOn : !!formik.values[name]}
        type="checkbox"
      />
      {label && labelEnd && <label htmlFor={name}>{label}</label>}
      {error && (
        <div style={{color: 'red', fontSize: '12px'}}>{error}</div>
      )}
    </div>
  );
};

export default CheckboxInput;
