import Container from '../components/campaign/StepContainer';
import React from 'react';
import Designer from '../components/campaign/Designer';

const Step4 = (props) => {
  return (
    <div>
      {props.open && (
        <Container>
          <Designer formik={props.formik}/>
        </Container>
      )}
    </div>
  );
};

export default Step4;
