export const MIN_HEIGHT = '135px';
export const GRADIENT_START = '#F0512D';
export const GRADIENT_END = '#5F38FE';

export const chartStyle = {
  width: '100%',
  height: '350px',
  backgroundColor: 'F6F6F6',
};

