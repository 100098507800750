import React from 'react';
import {Col, Row} from 'reactstrap';
import {DateTime as luxDate} from 'luxon';
import {winnerPeriods} from '../../../Campaign/OfferDetailsETW/config';

const ETWdetails = (props) => {
  const {formik} = props;
  const name = 'winnerTimes';
  return (
    <div className="card mt-3" style={{padding: '20px'}}>
      <Row className="ETWdetails">
        <Col xs={12}>
          <Row className="form-row animated">
            <Col xs={2}>
              <div>Frequency </div>
              <span>{winnerPeriods.find(item => item.value === formik.values?.winnerPeriodType)?.label || ''}</span>
            </Col>
            <Col xs={5}>
              <Row>
                <Col xs={7}><p>Dates</p></Col>
                <Col xs={5}><p>Amount</p></Col>
              </Row>
              {
                formik.values?.[name]?.map((item, i) => {
                  return (
                    <Row key={item.roleId}>
                      <Col xs={7}>
                        <p>
                          <i className="zmdi zmdi-calendar text-orange mr-1"></i>{' '}
                          {luxDate.fromISO(formik.values?.[name][i].date).toFormat('MM-dd-yyyy') || ''}
                        </p>
                      </Col>
                      <Col xs={5} className="text-left">
                        <p>
                          ${formik.values?.[name][i].amount}
                        </p>
                      </Col>
                    </Row>
                  );
                })
              }
            </Col>
            <Col xs={5}>
              <div>
                <div>Prize notes: {formik.values?.winnerPrizeNote || ''}</div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={12}>
          <br/>
          <Row className="form-row animated">
            <Col xs={7}>
              <Row>
                <Col xs={6}>
                  <div>Single Entry, Image required?</div>
                  <div>{formik.values?.winnerPhotoReq1 ? 'Yes' : 'No'}</div>
                </Col>
                <Col xs={6}>
                  <div>Secondary Entry?</div>
                  <div>{formik.values?.winnerPhotoReq2 ? 'Yes' : 'No'}</div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ETWdetails;
