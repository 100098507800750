import React from 'react';
import Select from 'react-select';

const SelectInput = ({formik, name, label, options = [], ...rest}) => {
  if (!formik || !name) return null;
  const formikValue = formik.getFieldProps(name).value;
  const selected = options.find(item => item?.value === formikValue) || null;

  const onChange = ({value}) => {
    formik.setFieldValue(name, value);
    rest.onChange?.(value);
  };

  return (
    <div className="selectInput">
      {label && <label htmlFor={name}>{label}</label>}
      <Select
        name={name}
        placeholder={label}
        onBlur={formik.handleBlur}
        value={selected}
        onChange={onChange}
        options={[{label: 'Not selected', value: null}, ...options] || []}
        menuPortalTarget={document.body}
        styles={{menuPortal: base => ({...base, zIndex: 1})}}
      />
    </div>
  );
};

export default SelectInput;
