import Container from '../components/campaign/StepContainer';
import React from 'react';
import OfferDetails from './OfferDetails';
import {CAMPAIGN_TYPES} from './config';
import OfferDetailsETW from './OfferDetailsETW';

const Step3 = (props) => {
  const isEnterToWin = props.formik?.values?.type === CAMPAIGN_TYPES[1].value;
  return (
    <div>
      {props.open && (
        <Container>
          {
            !isEnterToWin ? <OfferDetails formik={props.formik}/> : <OfferDetailsETW formik={props.formik}/>
          }
        </Container>
      )}
    </div>
  );
};

export default Step3;
