import React, {useState} from 'react';
import {Popover, PopoverBody, PopoverHeader} from 'reactstrap';
import {Camera} from 'react-bootstrap-icons';
import {joinClasses} from '../../../../../utils';

const CameraIcon = ({disable}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  return (
    <div
      id="Popover1"
      onMouseEnter={() => setPopoverOpen(true)}
      onMouseLeave={() => setPopoverOpen(false)}
      className={joinClasses('cameraIcon', disable && 'disable' )}>
      <Camera/>
      <Popover isOpen={popoverOpen} target="Popover1">
        <PopoverHeader>Submissions</PopoverHeader>
        {!disable && <PopoverBody>Photo required</PopoverBody>}
        {disable && <PopoverBody>Photo not required</PopoverBody>}
      </Popover>
    </div>
  );
};

export default CameraIcon;
