import React from 'react';
import * as yup from 'yup';
import ButtonInput from '../../../shared/components/fields/ButtonInput';
import {CAMPAIGN_TYPES} from '../../Campaign/config';

export const campaignTypeValidation = {
  type: yup.string().required('Required'),
};

const CampaignType = ({formik, nextTab}) => {
  return (
    <div style={{display: 'flex'}}>
      {
        CAMPAIGN_TYPES.map((item, i) => (
          <div key={item.value} className="form-row animated" style={{marginRight: i === 0 && '20px'}}>
            <ButtonInput
              formik={formik}
              name={item.name}
              text={item.label}
              value={item.value}
              icon={<img src={item.icon} alt={item.label}/>}
              action={nextTab}
            />
          </div>
        ))
      }
    </div>
  );
};

export default CampaignType;
