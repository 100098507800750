import Container from '../components/campaign/StepContainer';
import CampaignType from '../components/campaign/CampaignType';
import React from 'react';

const Step1 = (props) => {
  return (
    <div>
      <h4 className="text-left m-0 mb-4">
				What type of campaign do you want to create?
      </h4>
      {props.open && (
        <Container>
          <CampaignType
            open={props.open}
            formik={props.formik}
            nextTab={props.nextTab}
          />
        </Container>
      )}
    </div>
  );
};

export default Step1;
