import {axiosInstance} from '../../utils';

const organizationUrl = '/org';
class OrganizationService {
  getAllOrganizations() {
    return axiosInstance.get(`${organizationUrl}/organizations`);
  }

  getPaypalCredentials(id) {
    return axiosInstance.get(`${organizationUrl}/paypal-credentials/${id}`);
  }


  async updatePaypalCredentials(data) {
    return await axiosInstance.post(
        `${organizationUrl}/save-paypal-credentials/`,
        data,
    );
  }

  async createOrganization(data) {
    return await axiosInstance.post(
        `${organizationUrl}/create-organization/`,
        data,
    );
  }

  updateOrganization(data, id) {
    const url = 'https://localhost:7274/update-organization/' + id;
    (async () => {
      // PUT request using axiosInstance with async/await
      const payload = {title: 'Axios PUT Request Example'};
      const response = await axiosInstance.put(url, payload);
      return response.data;
    })();
  }

  getOrganizationClientsById(id) {
    return axiosInstance.get(`${organizationUrl}/organizations/${id}/clients`);
  }
  getOrganization(id) {
    return axiosInstance.get(`${organizationUrl}/organization-info/${id}`);
  }

  savePaypalCredentials(data) {
    return axiosInstance.post(
        `${organizationUrl}/paypal-credentials/`,
        data,
    );
  }
}
export default OrganizationService;
