import React, {Fragment} from 'react';
import {Badge, ButtonGroup} from 'reactstrap';
import {getStyle} from './utils';

const data = ['Y', 'N'];

const ReceivedCash = ({onClick, selected = []}) => {
  const addOnClick = value => () => onClick(value);
  return (
    <div className="d-flex align-items-center mb-3">
      <div className="mr-3">Received Cash</div>
      <ButtonGroup>
        <div className="d-flex align-items-center">
          {
            data.map(item => (
              <Fragment key={item}>
                <Badge
                  onClick={addOnClick(item)}
                  active={selected.includes(item)}
                  style={getStyle(selected, item)}
                  className="text-capitalize text-dark d-flex justify-content-center align-items-center participation-badge-font mb-0"
                  color="light"
                >
                  {item}
                </Badge>
              </Fragment>
            ))
          }
        </div>
      </ButtonGroup>
    </div>
  );
};

export default ReceivedCash;
