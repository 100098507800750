import React from 'react';
import SwitchField from './fields/SwitchField';
import stateList from '../../../shared/USStates';
import Select from 'react-select';

const SHOW_STATES_KEY = 'showStates';
const STATES_KEY = 'stateSelection';
const SEPARATOR = '|';
const stateValues = stateList.map(item => item.value);

const CampaignDetails = ({formik}) => {
  if (!formik) return null;
  const {values} = formik;
  const initValues = values.stateSelection?.split(SEPARATOR) || [];
  const selected = stateList.filter(state => initValues.includes(state.value));

  const onChange = (values) => {
    if (values.find(item => item.value === 'all')) {
      const arr = [...stateValues];
      arr.shift();
      return formik.setFieldValue(STATES_KEY, arr.join(SEPARATOR));
    }
    const value = values.map(item => item.value).join(SEPARATOR);
    return formik.setFieldValue(STATES_KEY, value);
  };

  return (
    <>
      <h6>Campaign details:</h6>
      <SwitchField label="First Name" formik={formik} name="showFirstName"/>
      <SwitchField label="Last Name" formik={formik} name="showLastName"/>
      <SwitchField label="Email" formik={formik} name="showEmail"/>
      <SwitchField label="Phone" formik={formik} name="showPhone"/>
      <SwitchField label="States" formik={formik} name={SHOW_STATES_KEY}/>
      {values?.[SHOW_STATES_KEY] && (
        <div className="styled-select">
          <Select
            {...formik.getFieldProps(name)}
            placeholder="Select state"
            closeMenuOnSelect={false}
            name={STATES_KEY}
            options={stateList}
            onChange={onChange}
            isMulti
            value={selected}
            menuPortalTarget={document.body}
            styles={{menuPortal: base => ({...base, zIndex: 1, fontSize: '12px'})}}
          />
        </div>
      )}
    </>
  );
};

export default CampaignDetails;
