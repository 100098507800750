import React, {Fragment} from 'react';
import {Col, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import TableDataCard from '../../components/shared/TableDataCard';


const Header = ({columns, minHeight, loading}) => {
  return (
    <>
      <Row className="mx-0">
        {
          columns.map(item => {
            return (
              <Fragment key={item.id}>
                <Col
                  style={{minHeight}}
                  className="d-flex justify-content-between"
                >
                  <TableDataCard
                    icon={<FontAwesomeIcon size="2x" icon={item.icon}/>}
                    mainText={item.mainText}
                    valueAmount={isNaN(item.valueAmount) ? 0 : item.valueAmount}
                    yesAmount={isNaN(item.yesAmount) ? 0 : item.yesAmount}
                    noAmount={isNaN(item.noAmount) ? 0 : item.noAmount}
                    loading={loading}
                  />
                </Col>
                <div>
                  <div className="vertical-line"/>
                </div>
              </Fragment>
            );
          })
        }
      </Row>
    </>
  );
};

export default Header;
