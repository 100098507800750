import Clients from '../../views/Clients';
import CreateClient from '../../views/components/client/CreateClient';
import {ROLES} from '../config';

const routes = [
  {
    path: '/clients',
    name: 'Clients',
    component: Clients,
    layout: '/admin',
    exact: true,
    validRoles: [ROLES.org],
  },
  {
    path: '/clients/create',
    name: 'Create Client',
    component: CreateClient,
    layout: '/admin',
    exact: true,
    validRoles: [ROLES.org],
  },
];

export default routes;
