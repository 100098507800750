import React from 'react';
import {Link} from 'react-router-dom';
import {
  Table,
  Card,
  CardSubtitle,
  CardBody,
  Spinner,
} from 'reactstrap';
import './style.scss';
const RedemptionZone = ({data}) => {
  return (
    <Card style={{minHeight: '275px'}} className="m-2">
      <CardBody>
        <CardSubtitle className="mb-0">
          <h6 className="text-capitalize text-muted font-weight-light">
            Redemption Zone
          </h6>
        </CardSubtitle>
        {data ? (
          <Table className="redemption-zone-wrapper" size="sm">
            <thead>
              <tr>
                <th className="text-capitalize">Trigger Name</th>
                <th className="text-capitalize text-right">Trigger Type</th>
                <th className="text-capitalize text-right">
                  Opened Landing Page
                </th>
                <th className="text-capitalize text-right">
                  Submitted Contact Info
                </th>
                <th className="text-capitalize text-right">Submitted PoP</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => {
                return (
                  <tr key={index}>
                    <th style={{minWidth: '100px'}} scope="row">
                      <Link>{item.segmentname || 'Not Named'}</Link>
                    </th>
                    <td>
                      <p className="adjust-table-text m-0">
                        {item.theType}
                      </p>
                    </td>
                    <td>
                      <p className="adjust-table-text m-0">
                        {item.landingcount}
                      </p>
                    </td>
                    <td>
                      <p className="adjust-table-text m-0">
                        {item.subreceiptcount}
                      </p>
                    </td>
                    <td>
                      <p className="adjust-table-text m-0">
                        {item.useruploadcount}
                      </p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <Spinner />
        )}
      </CardBody>
    </Card>
  );
};

export default RedemptionZone;
